/**
* This file is generated by running "yarn generate-gql".
* Manual changes will be lost - proceed with caution!
*
* If this file contains merge conflicts, use "yarn generate-gql" to resolve them automatically.
*/
/* eslint-disable */

/** All built-in and custom scalars, mapped to their actual values */

/**
 * A generic representation of anomalous conditions, especially those where
 *  other object would normally appear but cannot.
 *  interface is meant to be used on concrete types with additional
 * sibly optional) fields with any additional information required to react
 * he anomaly appropriately.
 *
 * lly you will need only only one such type per anomalous location
 *  pattern of types like the following:
 *
 * type SomeObject {
 *   thing(thingId: Int!): MaybeThing!
 * }
 * union MaybeThing = Thing | NotThing
 * type NotThing implements Anomaly {
 *   anomalyReason: AnomalyReason!
 *   anomalyUserMessage: String
 *   requestedThingId: Int
 * }
 *
 * or mutation response payloads, something like this:
 *
 * type Mutation {
 *   doSomething(toThing: ID!): SomePayload
 * }
 *
 * type SomePayload implements Anomaly {
 *   anomalyReason: AnomalyReason
 *   anomalyUserMessage: String
 *   thing: Thing #
 * }
 */

/**
 * A global list of symbolic names for anomalous conditions that may be communicated
 * through responses as data.
 * When adding new entries, sort alphabetically and use descriptive names that are
 * unlikely to collide in other contexts.
 */

/**
 * A mirror of the Workspace2Automations type that is the result of
 * an updateAutomations mutation.
 * Selections are performed on *all* automations, not just those affected
 * by the mutation, but their state will reflect changes made by the mutation.
 */

/** Shared data point between daily and detailed burndown */

/**
 * A representation of a color.
 * ere are three ways a thing can be colored:
 *
 * In older concepts: hexadecimalColor.
 * This is a raw rgb hex color value which should be used as-is.
 * In newer concepts: colorKey. This is a color name in a palette.
 * Client code should use a palette mapping to get a css color value.
 * In specific scenarios: semanticColor. These are like colorKey,
 * but are not intended to be in user data, only in code,
 * and the value represents a meaning, not an abstract color.
 * They use a palette also, but a different one from colorKey.
 *
 * actly one of the above fields will be set on any color object.
 * e id will be some deterministic derivative of its contents.
 */

/**
 * A named color index in the UI color palette.
 *
 * Note: these are not specific color values and
 * must be mapped to specific values in code.
 */

/**
 * A sample of the cycle time lead time average for a report
 * query. The report calculates a rolling average for the cycle and lead
 * time.
 *
 * Cycle time is defined from when a story is moved into a started
 * workflow state until it's moved into a completed workflow.
 *
 * Lead time is defined from when a story is created until it's moved to
 * a completed workflow state.
 */

/**
 * A generalization of a UI icon. It wraps both named icons and url-based icons
 * and can represent either one or a mix of both.
 */

/**
 * Static icon identifiers for use by Displayable types.
 *
 * These should match the names used by [Shapes Icons].
 * Code should be defensive against unrecognized Icon names so that they do not
 * get runtime errors when new icons are added.
 *
 * [Shapes Icons]: https://shapes-storybook.shortcut.engineering/?path=/story/foundations-icons-icon--all
 */

/**
 * A presentation-oriented representation of an object in the UI as a icon and name pair.
 * Currently used for SelectItemEdge entries.
 */

/** A rich text document. */

/** A rich text document. */

/** A rich text document. */

/** Imported functionality from docs-api */

/** Imported functionality from docs-api */

/** Imported functionality from docs-api */

/**
 * A key for the Frontend to group EpicStates for Epic EpicState selection results.
 *
 * Order these enum values are declared in is significant:
 *
 * * Higher groups sort before lower groups.
 * * Lower groups coalesce into higher groups if an item could belong to multiple
 * groups, i.e. "highest group wins".
 */

/**
 * A key for the Frontend to group Permissions for Epic follower selection results.
 *
 * der these enum values are declared in is significant:
 *
 * Higher groups sort before lower groups.
 * Lower groups coalesce into higher groups if an item could belong to multiple
 * groups, i.e. "highest group wins".
 */

/**
 * A key for the Frontend to group Permissions for Epic owner selection results.
 *
 * der these enum values are declared in is significant:
 *
 * Higher groups sort before lower groups.
 * Lower groups coalesce into higher groups if an item could belong to multiple
 * groups, i.e. "highest group wins".
 */

/**
 * A key for the Frontend to group Teams for Epic team selection results.
 *
 * der these enum values are declared in is significant:
 *
 * Higher groups sort before lower groups.
 * Lower groups coalesce into higher groups if an item could belong to multiple
 * groups, i.e. "highest group wins".
 */

/**
 * A key for the Frontend to group Teams for Epic Teams or Associated Teams results.
 *
 * der these enum values are declared in is significant:
 *
 * Higher groups sort before lower groups.
 * Lower groups coalesce into higher groups if an item could belong to multiple
 * groups, i.e. "highest group wins".
 */

/**
 * This doesn't implement Node because the ID is not a valid global
 * ID, but it follows the shape of Node in order to facilitate reuse of
 * consumer code.
 */

/** The displayed type and value labels of a Filter. This is the least-common-denominator of what you can display for a Filter. */

/** A pill that only has the value options 'true' or 'false' and a fixed (hidden) operator. Used for most 'Quick Filters'. */

/** A pill that only has the value options 'true' or 'false' and a fixed (hidden) operator. Used for most 'Quick Filters'. */

/** A FilterPill which also lets you select operators. */

/** A pill that exposes Filter operator and value options. Used for most Filter Types. */

/** A pill that exposes Filter operator and value options. Used for most Filter Types. */

/** A pill that has a freeform text value. Used for the 'Search' filter. */

/** Members must implement FilterPill */

/** A FilterPill which also lets you select value options */

/** A FilterPill which also lets you select value options */

/**
 * These are magic literal ID values which *cannot* be looked up by node query
 * and will never be an id field of a Node.
 *
 * They are not legal ID values for a field unless explicitly allowed by an
 * @IDRange(sentinels:[...]) directive.
 */

/**
 * These are legal values for the type attribute of the @IDRange directive.
 *
 * All the values of this enum are the same as the name of a type in the schema
 * that implements Node and can be retrieved via the node(id: ID) query.
 */

/**
 * ObjectiveCreate_KeyResultCreateInput is another input that is used to create KeyResults.
 * When making changes here or there, we should consider if we should update this input as well.
 */

/** The dual of the MultiAxisPaginationOptions input type. */

/** The dual of the MultiAxisPageInfo output type. */

/** The dual of the MultiAxisPageInfo output type */

/** The dual of the NodeAxisPageInfo output type */

/**
 * A Space reference which could not be provided. Common reasons are:
 * notFound, spaceNotVisible, spaceContainerMismatchedTeamScope.
 *
 * In the case of spaceContainerMismatchedTeamScope, the space is available
 * but the application should reference it from the appropriate container.
 */

/**
 * KeyResultCreateInput is another input that is used to create KeyResults.
 * If there are changes made to here or there, we should consider if we should update this input as well.
 */

/**
 * Pagination metadata about the `edges` field on a Connection type.
 *
 * Connection paging is based on limit and offset.
 *
 * The limit is the max number of edges you will accept in the response.
 * The offset is the index of the first edge (not page) into the entire result
 * that you want to start seeing edges. Offset is 0-indexed.
 *
 * To get the next page of results, inspect hasNextPage.
 * If true, requery with a PaginationOptions value like
 * `{limit: PageInfo.limit, offset: PageInfo.offset + PageInfo.limit}`.
 *
 * To get the previous page, do a similar check of hasPreviousPage
 * and subtract the limit.
 *
 * Do not reuse the limit value supplied to the PaginationOptions of your
 * query because the server may have altered it.
 * Use the PageInfo.limit value to ensure page boundaries are aligned.
 */

/**
 * Options to control the number of returned edges of a Connection type.
 * See also the PageInfo type for how this is expressed in the returned objects.
 */

/**
 * Mutually-exclusive states a Permission may possess that describe the suitability
 * of the Permission for various purposes.
 *
 * A Permission has exactly one PermissionState.
 */

/**
 * Permission query input `where` options.
 * Any added option will reduce the result set to those that match the
 * option and parameter.
 * Multiple options reduce the set to Permissions where all options are satisfied.
 * Omitted options are ignored, i.e. do not reduce the result set in any way.
 * Option names correspond to field names on the Permission type.
 */

/** Units of progress for aggregations of stories (e.g. epics). */

/** Represents an insertion position in a list relative to anchor id. */

/** A time range that the Report querying function will run its search in. */

/** Interface representing what a layer data point should be minimally composed of. */

/** Interface representing what a report should be minimally composed of. */

/** Metrics that were calculated by sampling a point in time. */

/**
 * What mutative operation to apply to an automation that has an inherent
 * "scope".
 *
 * The type of automation created and the scope of that kind of automation
 * are all implied by semantics of the field that contains this operation.
 */

/**
 * Imported functionality from docs-api
 * We return cursor information that is required for paging search results.
 */

/** The edge interface all SelectItemEdgeUnions must implement. */

/** All items in this union MUST implement SelectItemEdgeInterface */

/**
 * The root of a tree of SelectItemEdges.
 * This is a Connection-like type designed for filling lists of selectable options.
 */

/**
 * Represents a cardinality-one field with an optional user-selectable value
 * as a Displayable for display, a scalar value for mutation parameters,
 * and a list of possible values for that field as a Connection.
 */

/**
 * Represents a cardinality-one field with an optional user-selectable value
 * as a Displayable for display, a scalar value for mutation parameters,
 * and a list of possible values for that field as a Connection.
 */

/**
 * A meaningful color for UI elements.
 *
 * Note: these are not specific color values and
 * must be mapped to specific values in frontend code.
 */

/** A named, persisted query over Stories. */

/**
 * The independently-saveable query and view configuration of a Space.
 *
 * Objects of this type represent what is changeable about a Space which requires
 * a separate "save" action to make the new query and view settings for the Space.
 * Because this object contains all the parameters needed to represent a query
 * result it also contains query result fields.
 */

/**
 * The independently-saveable query and view configuration of a Space.
 *
 * Objects of this type represent what is changeable about a Space which requires
 * a separate "save" action to make the new query and view settings for the Space.
 * Because this object contains all the parameters needed to represent a query
 * result it also contains query result fields.
 */

/**
 * The independently-saveable query and view configuration of a Space.
 *
 * Objects of this type represent what is changeable about a Space which requires
 * a separate "save" action to make the new query and view settings for the Space.
 * Because this object contains all the parameters needed to represent a query
 * result it also contains query result fields.
 */

/**
 * The independently-saveable query and view configuration of a Space.
 *
 * Objects of this type represent what is changeable about a Space which requires
 * a separate "save" action to make the new query and view settings for the Space.
 * Because this object contains all the parameters needed to represent a query
 * result it also contains query result fields.
 */

/**
 * The independently-saveable query and view configuration of a Space.
 *
 * Objects of this type represent what is changeable about a Space which requires
 * a separate "save" action to make the new query and view settings for the Space.
 * Because this object contains all the parameters needed to represent a query
 * result it also contains query result fields.
 */

/**
 * The independently-saveable query and view configuration of a Space.
 *
 * Objects of this type represent what is changeable about a Space which requires
 * a separate "save" action to make the new query and view settings for the Space.
 * Because this object contains all the parameters needed to represent a query
 * result it also contains query result fields.
 */

/** This exists only for backend type dispatch and may be replaced by SelectItemsSingleSelectImpl. */

/** This exists only for backend type dispatch and may be replaced by SelectItemsSingleSelectImpl. */

/**
 * Represents the state of a particular Permission's Space tabs and selected Space.
 * StoriesPage objects are only viewable by the owning Permission,
 * which must also be the viewing Permission.
 */

/**
 * Represents the state of a particular Permission's Space tabs and selected Space.
 * StoriesPage objects are only viewable by the owning Permission,
 * which must also be the viewing Permission.
 */

/**
 * Represents the state of a particular Permission's Space tabs and selected Space.
 * StoriesPage objects are only viewable by the owning Permission,
 * which must also be the viewing Permission.
 */

/**
 * Represents the state of a particular Permission's Space tabs and selected Space.
 * StoriesPage objects are only viewable by the owning Permission,
 * which must also be the viewing Permission.
 */

/**
 * Represents the state of a particular Permission's Space tabs and selected Space.
 * StoriesPage objects are only viewable by the owning Permission,
 * which must also be the viewing Permission.
 */

/**
 * This represents a Permission's private list of spaces for a StoriesPage.
 * A permission may have at most one tab list per team (including no team,
 * i.e. the All Work page).
 */

/**
 * This mutation allows for for mutation of a canonical custom field on a story without a need to supply a field id.
 * When a NONE is provided for valueId, the current existing story custom field entity is retracted.
 * The operation fails if any of the following is true.
 * - custom fields are not enabled in the workspace.
 * - the supplied field is disabled.
 * - the supplied value does not belong to the referenced field.
 */

/**
 * Mutate the asserted value of a Story's custom field.
 *
 * When a NONE is provided for valueId, the current existing story custom field entity is retracted.
 * The operation fails if any of the following is true.
 * - custom fields are not enabled in the workspace.
 * - the supplied field is disabled.
 * - the supplied value does not belong to the referenced field.
 */

/**
 * Upsert or Delete operations to perform on Workspace or Team-scoped Automations
 * addressed by their scope and not their id.
 */

/**
 * A container for expressing changes to a cardinality-many reference value in a mutation.
 * It has two mutually-exclusive modes: a "delta" mode and a "reset" mode.
 *
 * The "delta" mode expresses the desired *changes* to the value with the
 * `add` and `remove` fields. A value must be in one and only one of these fields.
 * Values that are not mentioned will not be affected.
 * If a value is in both `add` and `remove`, the mutation will be rejected.
 * This mode is good for incremental or live changes because it commutes well with
 * simultaneous updates and matches user intent.
 *
 * The "reset" mode expressed the desired *final value* at the end of the transaction.
 * Whatever values are present will be added or removed so that the full result
 * matches the value in the `reset` field.
 * If the `reset` field is present with either `add` and `remove` fields also,
 * the the mutation will be rejected.
 * This mode is good for "submit"-style forms, where a large amount of state
 * is saved simultaneously in a single commit.
 */

/** This has the same semantics as UpdateManyRefInput, but is typed for SelectOptionValues. */

/** The arguments of a CompleteEpic Automation's Upsert operation. */

/** The arguments of an EnsureNFutureIterations Automation's Upsert operation. */

/**
 * A parameterized operation performed on an EnsureNFutureIterations Automation
 * with a matching Team.
 */

/** The arguments of an MoveStoriesToNextIteration Automation's Upsert operation. */

/**
 * A parameterized operation performed on an MoveStoriesToNextIteration Automation
 * with a matching Team.
 */

/** The arguments of a StartEpic Automation's Upsert operation. */

/**
 * An id used to generate a url in the browser.
 * For a story with url http://app.shortcut.com/internal/story/1234, the urlId should be 1234.
 */

/**
 * A WIP Limit sets the maximum amount of work (Stories) that can exist in a workflow state.
 * These WIP Limits are soft limits and the system does not enforce these limits. They are
 * used mostly to display these limits in the Stories page.
 */

/** Input type for setting the WIP Limit associated with a team and a workflow state. */

/** The return payload for upsert-ing a WIP Limit */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/** A Workspace, and the selection entrypoint to Workspace-scoped queries. */

/**
 * We made a deliberate choice to implement types that resemble a connection but are not for the following reasons.
 * - We do not need pagination
 * - The frontend components expects a connection like shape
 * - Story type enumeration is hardcoded into the system and doesn't use an id attribute
 */

export const UpdateStoryFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UpdateStory"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }]
};
export const UpdateEpicFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UpdateEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const UpdateIterationFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UpdateIteration"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Iteration"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }]
};
export const CreateObjectiveResultFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CreateObjectiveResult"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }]
    }
  }]
};
export const CategoryItemFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CategoryItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Category"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectives"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const EnabledCustomFieldsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EnabledCustomFields"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workspace2"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "enabledCustomFields"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "canonicalName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "publicName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const ViewDocActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ViewDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const PinDocActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const CopyDocLinkActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyDocLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const RemoveDocActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RemoveDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }]
    }
  }]
};
export const ArchiveDocActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }]
    }
  }]
};
export const DeleteDocActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "docRelationships"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "object"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "InlineFragment",
                        "typeCondition": {
                          "kind": "NamedType",
                          "name": {
                            "kind": "Name",
                            "value": "Node"
                          }
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "creator"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const DocItemFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DocItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "creator"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ViewDocAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinDocAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyDocLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RemoveDocAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveDocAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteDocAction"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ViewDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyDocLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RemoveDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "docRelationships"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "object"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "InlineFragment",
                        "typeCondition": {
                          "kind": "NamedType",
                          "name": {
                            "kind": "Name",
                            "value": "Node"
                          }
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "creator"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const EpicDocsListFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicDocsList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "docsSharedToWorkspace2"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DocItem"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ViewDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyDocLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RemoveDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "docRelationships"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "object"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "InlineFragment",
                        "typeCondition": {
                          "kind": "NamedType",
                          "name": {
                            "kind": "Name",
                            "value": "Node"
                          }
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "creator"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DocItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "creator"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ViewDocAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinDocAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyDocLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RemoveDocAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveDocAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteDocAction"
        }
      }]
    }
  }]
};
export const EpicStateOptionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStateOption"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }]
};
export const EpicCreatedDateFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicCreatedDateFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const EpicLastUpdatedDateFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicLastUpdatedDateFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "effectiveUpdatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const PinEpicActionFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const GoToEpicActionFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const CopyEpicLinkActionFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyEpicLinkActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const UnarchiveEpicFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }]
};
export const ArchiveEpicFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }]
};
export const ArchiveEpicActionFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }]
};
export const DuplicateEpicActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateEpicAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const DeleteEpicFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const DeleteEpicActionFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteEpicFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const EpicMoreActionsFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicMoreActionsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "GoToEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyEpicLinkActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateEpicAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteEpicActionFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyEpicLinkActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateEpicAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteEpicFragment"
        }
      }]
    }
  }]
};
export const EpicRelatedDocsFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicRelatedDocsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const TeamDisplayIconFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const EpicsTeamFieldTeamFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTeamFieldTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const EpicTeamsOrAssociatedTeamsFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicTeamsOrAssociatedTeamsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teamsOrAssociatedTeams"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "3"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "includeTeams"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "includeTeams"
                }
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numStoriesOwned"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isEpicOwner"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsTeamFieldTeamFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "members"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "edges"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "node"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsTeamFieldTeamFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTeamFieldTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }]
};
export const EpicTotalPointsFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicTotalPointsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsTotal"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const EpicTotalStoriesFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicTotalStoriesFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesTotal"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const KeyResultProgressTooltipFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KeyResultProgressTooltip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "progress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentObservedValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentTargetValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveProgressTooltipEpicFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveProgressTooltipEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentStarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }]
    }
  }]
};
export const ObjectiveCreatedDateFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCreatedDateField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const CurrentHealthCommentDisplayFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CurrentHealthCommentDisplay"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Health"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "text"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "author"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveCurrentHealthCommentFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCurrentHealthCommentField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealth"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CurrentHealthCommentDisplay"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CurrentHealthCommentDisplay"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Health"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "text"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "author"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveEpicCountFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveEpicCountField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsTotal"
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveHealthUpdateSelectFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveHealthUpdateSelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numHealthComments"
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveHealthFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveHealthField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveHealthUpdateSelect"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveHealthUpdateSelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numHealthComments"
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveKeyResultCountFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultCountField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numKeyResults"
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveKeyResultProgressFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultProgressField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResultProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numKeyResults"
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveLastUpdatedDateFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveLastUpdatedDateField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const GoToObjectiveActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const PinObjectiveActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const CopyObjectiveLinkActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyObjectiveLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const ArchiveObjectiveActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }]
};
export const DuplicateObjectiveActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const DeleteObjectiveActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const ObjectiveMoreActionsFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveMoreActionsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "GoToObjectiveAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinObjectiveAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyObjectiveLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveObjectiveAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateObjectiveAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteObjectiveAction"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyObjectiveLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const ObjectiveNameFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveNameField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }]
};
export const ObjectiveProgressFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveProgressField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentStarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsTotal"
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectivePublicIdFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectivePublicIdField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }]
    }
  }]
};
export const ObjectiveRelatedDocumentsFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveRelatedDocumentsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveStartDateFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveStartDateField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "startDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "endDate"
        }
      }]
    }
  }]
};
export const ObjectiveStoryCountFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveStoryCountField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesTotal"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const ObjectiveStoryPointsFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveStoryPointsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsTotal"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const ObjectiveTargetDateFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTargetDateField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "startDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "endDate"
        }
      }]
    }
  }]
};
export const ObjectiveTypeFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTypeField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const DisplayableNameFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }]
};
export const CustomFieldColumnConfigFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CustomFieldColumnConfig"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomField"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "canonicalName"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DisplayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }]
};
export const EpicSelectFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicSelectFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }]
};
export const MenuFeaturesFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "MenuFeatures"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Features"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "projects"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "enabled"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iterations"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "enabled"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "docs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "enabled"
            }
          }]
        }
      }]
    }
  }]
};
export const UserCohortAdapterPermissionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UserCohortAdapterPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }]
};
export const DocSearchCardDocFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DocSearchCardDoc"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "accessControlScope"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "creator"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "UserCohortAdapterPermission"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UserCohortAdapterPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }]
};
export const EpicSearchCardEpicFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicSearchCardEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "UserCohortAdapterPermission"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UserCohortAdapterPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }]
};
export const IterationSearchCardIterationFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "IterationSearchCardIteration"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Iteration"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "iterationName"
        },
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "iterationState"
        },
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "startEpochDay"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "endEpochDay"
        }
      }]
    }
  }]
};
export const ObjectiveSearchCardObjectiveFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveSearchCardObjective"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "objectiveName"
        },
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "objectiveState"
        },
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }]
    }
  }]
};
export const StorySearchCardStoryFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StorySearchCardStory"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "UserCohortAdapterPermission"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UserCohortAdapterPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }]
};
export const DocCardDocFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DocCardDoc"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "accessControlScope"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "docCollections"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoryLabelLabelFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLabelLabel"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }]
};
export const PointsEpicBadgesAndLabelsEpicStatsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PointsEpicBadgesAndLabelsEpicStats"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicStats"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_points_done"
        },
        "name": {
          "kind": "Name",
          "value": "numPointsCompleted"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_points_started"
        },
        "name": {
          "kind": "Name",
          "value": "numPointsInProgress"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_points_unstarted"
        },
        "name": {
          "kind": "Name",
          "value": "numPointsUnstarted"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_points_backlog"
        },
        "name": {
          "kind": "Name",
          "value": "numPointsBacklog"
        }
      }]
    }
  }]
};
export const StoriesEpicBadgesAndLabelsEpicStatsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesEpicBadgesAndLabelsEpicStats"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicStats"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_stories_done"
        },
        "name": {
          "kind": "Name",
          "value": "numStoriesCompleted"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_stories_started"
        },
        "name": {
          "kind": "Name",
          "value": "numStoriesInProgress"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_stories_unstarted"
        },
        "name": {
          "kind": "Name",
          "value": "numStoriesUnstarted"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_stories_backlog"
        },
        "name": {
          "kind": "Name",
          "value": "numStoriesBacklog"
        }
      }]
    }
  }]
};
export const EpicIconEpicFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicIconEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "stateObject"
        },
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const EpicBadgesAndLabelsEpicFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicBadgesAndLabelsEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "stateObject"
        },
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }]
          }
        }],
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "showEpicLabels"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryLabelLabel"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stories"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsTotal"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "PointsEpicBadgesAndLabelsEpicStats"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "include"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "usingPoints"
                  }
                }
              }]
            }]
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesEpicBadgesAndLabelsEpicStats"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "usingPoints"
                  }
                }
              }]
            }]
          }]
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "percentCompleted"
        },
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicIconEpic"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLabelLabel"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PointsEpicBadgesAndLabelsEpicStats"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicStats"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_points_done"
        },
        "name": {
          "kind": "Name",
          "value": "numPointsCompleted"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_points_started"
        },
        "name": {
          "kind": "Name",
          "value": "numPointsInProgress"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_points_unstarted"
        },
        "name": {
          "kind": "Name",
          "value": "numPointsUnstarted"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_points_backlog"
        },
        "name": {
          "kind": "Name",
          "value": "numPointsBacklog"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesEpicBadgesAndLabelsEpicStats"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicStats"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_stories_done"
        },
        "name": {
          "kind": "Name",
          "value": "numStoriesCompleted"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_stories_started"
        },
        "name": {
          "kind": "Name",
          "value": "numStoriesInProgress"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_stories_unstarted"
        },
        "name": {
          "kind": "Name",
          "value": "numStoriesUnstarted"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_stories_backlog"
        },
        "name": {
          "kind": "Name",
          "value": "numStoriesBacklog"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicIconEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "stateObject"
        },
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const EpicCardEpicFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicCardEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicBadgesAndLabelsEpic"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "UserCohortAdapterPermission"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLabelLabel"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PointsEpicBadgesAndLabelsEpicStats"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicStats"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_points_done"
        },
        "name": {
          "kind": "Name",
          "value": "numPointsCompleted"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_points_started"
        },
        "name": {
          "kind": "Name",
          "value": "numPointsInProgress"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_points_unstarted"
        },
        "name": {
          "kind": "Name",
          "value": "numPointsUnstarted"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_points_backlog"
        },
        "name": {
          "kind": "Name",
          "value": "numPointsBacklog"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesEpicBadgesAndLabelsEpicStats"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicStats"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_stories_done"
        },
        "name": {
          "kind": "Name",
          "value": "numStoriesCompleted"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_stories_started"
        },
        "name": {
          "kind": "Name",
          "value": "numStoriesInProgress"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_stories_unstarted"
        },
        "name": {
          "kind": "Name",
          "value": "numStoriesUnstarted"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "num_stories_backlog"
        },
        "name": {
          "kind": "Name",
          "value": "numStoriesBacklog"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicIconEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "stateObject"
        },
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicBadgesAndLabelsEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "stateObject"
        },
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }]
          }
        }],
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "showEpicLabels"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryLabelLabel"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stories"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsTotal"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "PointsEpicBadgesAndLabelsEpicStats"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "include"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "usingPoints"
                  }
                }
              }]
            }]
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesEpicBadgesAndLabelsEpicStats"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "usingPoints"
                  }
                }
              }]
            }]
          }]
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "percentCompleted"
        },
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicIconEpic"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UserCohortAdapterPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }]
};
export const StoryAutoLinkFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryAutoLink"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }]
};
export const HealthStatusUpdateFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "HealthStatusUpdate"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Health"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "author"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "status"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "text"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reactions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "groupKey"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "emoji"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "author"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "displayName"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }]
    }
  }]
};
export const DockedDocFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DockedDoc"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }]
    }
  }]
};
export const DockedEpicFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DockedEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "epicName"
        },
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const DockedIterationFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DockedIteration"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Iteration"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "iterationName"
        },
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const DockedKeyResultFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DockedKeyResult"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "keyResultName"
        },
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objective"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }]
    }
  }]
};
export const DockedObjectiveFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DockedObjective"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "objectiveName"
        },
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const DockedStoryFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DockedStory"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }]
};
export const StoryCreatedToastFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCreatedToast"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }]
};
export const TeamHeaderFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamHeaderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }]
    }
  }]
};
export const WorkspaceIconWorkspace2FragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkspaceIconWorkspace2"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workspace2"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const WorkspaceHeaderWorkspace2FragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkspaceHeaderWorkspace2"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workspace2"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "WorkspaceIconWorkspace2"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkspaceIconWorkspace2"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workspace2"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const WorkspaceCardWorkspace2FragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkspaceCardWorkspace2"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workspace2"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "WorkspaceHeaderWorkspace2"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkspaceIconWorkspace2"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workspace2"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkspaceHeaderWorkspace2"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workspace2"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "WorkspaceIconWorkspace2"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const UseOptimisticFragmentUpdateTestFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UseOptimisticFragmentUpdateTestFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workspace2"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const IterationAutomationTeamFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "IterationAutomationTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }]
    }
  }]
};
export const StoryReorderFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryReorderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const BulkEditFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "BulkEditFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoryPublicIdFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryPublicIdFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoryNameFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryNameFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoryTypeFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryTypeFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }]
};
export const StoryOwnersFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryOwnersFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoryRequesterFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryRequesterField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "requester"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mentionName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const IterationFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "IterationFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }]
        }
      }]
    }
  }]
};
export const StoryDeadlineFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryDeadlineFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }]
    }
  }]
};
export const BacklogWorkflowStatesFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "BacklogWorkflowStatesField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }]
};
export const WorkflowFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflow"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoriesTableEpicFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epicState"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const ProductAreaFieldItemFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }]
    }
  }]
};
export const ProductAreaFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ProductAreaFieldItem"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }]
    }
  }]
};
export const TechnicalAreaFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TechnicalAreaFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }]
};
export const SkillSetFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SkillSetFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesTableTeamFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mentionName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "TeamDisplayIconFragment"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const SeverityFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SeverityFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesEstimateFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesEstimateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "estimate"
        }
      }]
    }
  }]
};
export const StoryPriorityFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryPriorityFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }]
};
export const StoryLabelsFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLabelsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoryLastUpdatedFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLastUpdatedField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoryCreatedOnFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCreatedOnField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const GoToStoryActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const PinStoryActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const CopyStoryLinkActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyStoryLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const UnarchiveStoryDataFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }]
};
export const ArchiveStoryDataFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }]
};
export const ArchiveStoryActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveStoryData"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveStoryData"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }]
};
export const StoryMoreActionsFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryMoreActionsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "GoToStoryAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinStoryAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyStoryLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveStoryAction"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyStoryLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveStoryData"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveStoryData"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoryGroupByEpicFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }]
    }
  }]
};
export const StoryGroupByOwnerFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByOwnerFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoryGroupByTeamFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoryGroupByWorkflowStateFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByWorkflowStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }]
        }
      }]
    }
  }]
};
export const StoryGroupByWorkflowFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByWorkflowFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflow"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoryGroupByTypeFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByTypeFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }]
};
export const StoryGroupByIterationFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByIterationFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }]
    }
  }]
};
export const StoryGroupBySkillSetFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupBySkillSetFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }]
};
export const StoryGroupByTechnicalAreaFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByTechnicalAreaFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }]
};
export const StoryGroupByPriorityFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByPriorityFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }]
};
export const StoryGroupBySeverityFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupBySeverityFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }]
};
export const StoryGroupByProductAreaFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByProductAreaFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }]
};
export const BacklogStoryFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "BacklogStory"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryReorderFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "BulkEditFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryPublicIdFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryNameFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryTypeFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryOwnersFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryRequesterField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryOwnersFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "IterationFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryDeadlineFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "BacklogWorkflowStatesField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "WorkflowFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesTableEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ProductAreaFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TechnicalAreaFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "SkillSetFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesTableTeamFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "SeverityFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesEstimateFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryPriorityFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryLabelsField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryLastUpdatedField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCreatedOnField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryMoreActionsField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByOwnerFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByTeamFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByWorkflowStateFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByWorkflowFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByTypeFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByIterationFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupBySkillSetFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByTechnicalAreaFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByPriorityFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupBySeverityFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByProductAreaFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyStoryLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveStoryData"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveStoryData"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryReorderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "BulkEditFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryPublicIdFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryNameFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryTypeFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryOwnersFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryRequesterField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "requester"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mentionName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "IterationFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryDeadlineFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "BacklogWorkflowStatesField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflow"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epicState"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ProductAreaFieldItem"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TechnicalAreaFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SkillSetFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mentionName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "TeamDisplayIconFragment"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SeverityFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesEstimateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "estimate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryPriorityFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLabelsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLastUpdatedField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCreatedOnField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryMoreActionsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "GoToStoryAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinStoryAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyStoryLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveStoryAction"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByOwnerFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByWorkflowStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByWorkflowFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflow"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByTypeFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByIterationFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupBySkillSetFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByTechnicalAreaFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByPriorityFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupBySeverityFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByProductAreaFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }]
};
export const BacklogStoriesFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "BacklogStories"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoryConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "node"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "BacklogStory"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "offset"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "limit"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryReorderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "BulkEditFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryPublicIdFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryNameFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryTypeFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryOwnersFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryRequesterField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "requester"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mentionName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "IterationFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryDeadlineFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "BacklogWorkflowStatesField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflow"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epicState"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ProductAreaFieldItem"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TechnicalAreaFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SkillSetFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mentionName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "TeamDisplayIconFragment"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SeverityFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesEstimateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "estimate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryPriorityFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLabelsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLastUpdatedField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCreatedOnField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyStoryLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveStoryData"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveStoryData"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryMoreActionsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "GoToStoryAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinStoryAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyStoryLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveStoryAction"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByOwnerFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByWorkflowStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByWorkflowFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflow"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByTypeFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByIterationFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupBySkillSetFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByTechnicalAreaFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByPriorityFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupBySeverityFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByProductAreaFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "BacklogStory"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryReorderFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "BulkEditFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryPublicIdFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryNameFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryTypeFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryOwnersFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryRequesterField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryOwnersFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "IterationFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryDeadlineFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "BacklogWorkflowStatesField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "WorkflowFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesTableEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ProductAreaFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TechnicalAreaFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "SkillSetFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesTableTeamFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "SeverityFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesEstimateFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryPriorityFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryLabelsField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryLastUpdatedField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCreatedOnField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryMoreActionsField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByOwnerFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByTeamFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByWorkflowStateFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByWorkflowFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByTypeFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByIterationFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupBySkillSetFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByTechnicalAreaFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByPriorityFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupBySeverityFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByProductAreaFragment"
        }
      }]
    }
  }]
};
export const EpicPinButtonFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicPinButton"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const EpicShareButtonFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicShareButton"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const EpicTitleDataFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicTitleDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const EpicStatsProgressFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStatsProgressFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicStats"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsBacklog"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesBacklog"
        }
      }]
    }
  }]
};
export const EpicProgressDataFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicProgressDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentStarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStatsProgressFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesUnestimated"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStatsProgressFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicStats"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsBacklog"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesBacklog"
        }
      }]
    }
  }]
};
export const UpdateEpicStateFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UpdateEpicState"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }]
};
export const EpicMutateEpicStateFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicMutateEpicStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "UpdateEpicState"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UpdateEpicState"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }]
};
export const EpicMoreActionsDataFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicMoreActionsDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateEpicAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicMutateEpicStateFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UpdateEpicState"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteEpicFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateEpicAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicMutateEpicStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "UpdateEpicState"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const EpicDetailsDataFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicDetailsDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastStoryUpdate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesTotal"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsTotal"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": []
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const EpicTeamsOrAssociatedTeamsAndOwnersDataFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicTeamsOrAssociatedTeamsAndOwnersDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "3"
                  }
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsTeamFieldTeamFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTeamFieldTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }]
};
export const EpicMiniKanbanDataFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicMiniKanbanDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesTotal"
            }
          }]
        }
      }]
    }
  }]
};
export const EpicFooterDataFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicFooterDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesInProgress"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesCompleted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesTotal"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastStoryUpdate"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }]
};
export const ColumnEpicDataFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnEpicDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsTotal"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesTotal"
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicTitleDataFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicProgressDataFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicMoreActionsDataFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicDetailsDataFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicTeamsOrAssociatedTeamsAndOwnersDataFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicMiniKanbanDataFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicFooterDataFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStatsProgressFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicStats"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsBacklog"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesBacklog"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteEpicFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateEpicAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UpdateEpicState"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicMutateEpicStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "UpdateEpicState"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTeamFieldTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicTitleDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicProgressDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentStarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStatsProgressFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesUnestimated"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicMoreActionsDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateEpicAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicMutateEpicStateFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicDetailsDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastStoryUpdate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesTotal"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsTotal"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": []
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicTeamsOrAssociatedTeamsAndOwnersDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "3"
                  }
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsTeamFieldTeamFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicMiniKanbanDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesTotal"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicFooterDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesInProgress"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesCompleted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesTotal"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastStoryUpdate"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }]
};
export const EpicColumnReorderFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicColumnReorderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "position"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }]
};
export const EpicStateNodeFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStateNode"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }]
};
export const EpicReorderFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicReorderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const BurndownTooltipBurndown_EntityReportFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "BurndownTooltipBurndown_EntityReport"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Burndown_EntityReport"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "burndown"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "completed"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "added"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "removed"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "started"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "unstarted"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "backlog"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reportNowValueEpoch"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "client"
          }
        }]
      }]
    }
  }]
};
export const BurndownChartBurndown_EntityReportFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "BurndownChartBurndown_EntityReport"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Burndown_EntityReport"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "burndown"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "active"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "idealBurndown"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "ideal"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "nonWorkingDays"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "nonWorkingDay"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "xAxis"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reportNowValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reportNowValueEpoch"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "client"
          }
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "BurndownTooltipBurndown_EntityReport"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "BurndownTooltipBurndown_EntityReport"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Burndown_EntityReport"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "burndown"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "completed"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "added"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "removed"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "started"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "unstarted"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "backlog"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reportNowValueEpoch"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "client"
          }
        }]
      }]
    }
  }]
};
export const CumulativeFlowTooltipCumulativeFlowDistribution_DataPoint_EntityReportFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CumulativeFlowTooltipCumulativeFlowDistribution_DataPoint_EntityReport"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CumulativeFlowDistribution_DataPoint_EntityReport"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "distribution"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "percentage"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "total"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "storyCount"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "estimateSum"
            }
          }]
        }
      }]
    }
  }]
};
export const CumulativeFlowChartCumulativeFlowDistribution_EntityReportFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CumulativeFlowChartCumulativeFlowDistribution_EntityReport"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CumulativeFlowDistribution_EntityReport"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "cumulativeFlow"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "distribution"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "aggregated"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "storyCount"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "estimateSum"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "workflowState"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "type"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "workflow"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CumulativeFlowTooltipCumulativeFlowDistribution_DataPoint_EntityReport"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "nonWorkingDays"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "nonWorkingDay"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "xAxis"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reportNowValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reportNowValueEpoch"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "client"
          }
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CumulativeFlowTooltipCumulativeFlowDistribution_DataPoint_EntityReport"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CumulativeFlowDistribution_DataPoint_EntityReport"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "distribution"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "percentage"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "total"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "storyCount"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "estimateSum"
            }
          }]
        }
      }]
    }
  }]
};
export const ReportSelectFilterSelectItemEdgeUnionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ReportSelectFilterSelectItemEdgeUnion"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemEdgeUnion"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelectItemEdgeInterface"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayable"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "Node"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayableName"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelectItemEdgeOption"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isSelected"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isDisabled"
            }
          }]
        }
      }]
    }
  }]
};
export const WorkflowSelectIteration_ReportConfig_WorkflowSelectFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowSelectIteration_ReportConfig_WorkflowSelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Iteration_ReportConfig_WorkflowSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Node"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ReportSelectFilterSelectItemEdgeUnion"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ReportSelectFilterSelectItemEdgeUnion"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemEdgeUnion"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelectItemEdgeInterface"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayable"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "Node"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayableName"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelectItemEdgeOption"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isSelected"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isDisabled"
            }
          }]
        }
      }]
    }
  }]
};
export const ReportSelectFilterReport_SelectorFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ReportSelectFilterReport_Selector"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Report_Selector"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Node"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ReportSelectFilterSelectItemEdgeUnion"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ReportSelectFilterSelectItemEdgeUnion"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemEdgeUnion"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelectItemEdgeInterface"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayable"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "Node"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayableName"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelectItemEdgeOption"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isSelected"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isDisabled"
            }
          }]
        }
      }]
    }
  }]
};
export const ChartTypeSelectReport_SelectorFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ChartTypeSelectReport_Selector"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Report_Selector"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ReportSelectFilterReport_Selector"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ReportSelectFilterSelectItemEdgeUnion"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemEdgeUnion"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelectItemEdgeInterface"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayable"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "Node"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayableName"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelectItemEdgeOption"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isSelected"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isDisabled"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ReportSelectFilterReport_Selector"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Report_Selector"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Node"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ReportSelectFilterSelectItemEdgeUnion"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const CycleTimeTooltipStoriesByInstant_EntityReportFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CycleTimeTooltipStoriesByInstant_EntityReport"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesByInstant_EntityReport"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "timeSpent"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "valueLabel"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "sampledAtEpoch"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "client"
          }
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "story"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "estimate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }]
    }
  }]
};
export const CycleTimeTooltipTrailingAverageByInstant_EntityReportFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CycleTimeTooltipTrailingAverageByInstant_EntityReport"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "TrailingAverageByInstant_EntityReport"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "sampledAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "sampledAtEpoch"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "client"
          }
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "trailingAverageTimeSpent"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "valueLabel"
            }
          }]
        }
      }]
    }
  }]
};
export const CycleTimeChartCycleTimeLeadTime_EntityReportFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CycleTimeChartCycleTimeLeadTime_EntityReport"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CycleTimeLeadTime_EntityReport"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "standardDeviation"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "standardDeviation"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stories"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "timeSpent"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CycleTimeTooltipStoriesByInstant_EntityReport"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "trailingAverage"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "trailingAverageTimeSpent"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CycleTimeTooltipTrailingAverageByInstant_EntityReport"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iterationAverage"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "iterationAverageTimeSpent"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "valueLabel"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "nonWorkingDays"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "nonWorkingDay"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "xAxis"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reportNowValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reportNowValueEpoch"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "client"
          }
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CycleTimeTooltipStoriesByInstant_EntityReport"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesByInstant_EntityReport"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "timeSpent"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "valueLabel"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "sampledAtEpoch"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "client"
          }
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "story"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "estimate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CycleTimeTooltipTrailingAverageByInstant_EntityReport"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "TrailingAverageByInstant_EntityReport"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "sampledAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "sampledAtEpoch"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "client"
          }
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "trailingAverageTimeSpent"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "valueLabel"
            }
          }]
        }
      }]
    }
  }]
};
export const StoryTypeLegendItem_SelectItemEdgeOptionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryTypeLegendItem_SelectItemEdgeOption"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemEdgeOption"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSelected"
        }
      }]
    }
  }]
};
export const CycleTimeLegend_SelectItemsConnectionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CycleTimeLegend_SelectItemsConnection"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeOption"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoryTypeLegendItem_SelectItemEdgeOption"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryTypeLegendItem_SelectItemEdgeOption"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemEdgeOption"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSelected"
        }
      }]
    }
  }]
};
export const CycleTimeChartSelectItemsConnectionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CycleTimeChartSelectItemsConnection"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CycleTimeLegend_SelectItemsConnection"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryTypeLegendItem_SelectItemEdgeOption"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemEdgeOption"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSelected"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CycleTimeLegend_SelectItemsConnection"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeOption"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoryTypeLegendItem_SelectItemEdgeOption"
            }
          }]
        }
      }]
    }
  }]
};
export const TimeUnitSelectReport_SelectorFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TimeUnitSelectReport_Selector"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Report_Selector"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ReportSelectFilterReport_Selector"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ReportSelectFilterSelectItemEdgeUnion"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemEdgeUnion"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelectItemEdgeInterface"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayable"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "Node"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayableName"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelectItemEdgeOption"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isSelected"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isDisabled"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ReportSelectFilterReport_Selector"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Report_Selector"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Node"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ReportSelectFilterSelectItemEdgeUnion"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const WorkflowSelectReport_SelectorFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowSelectReport_Selector"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Report_Selector"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ReportSelectFilterReport_Selector"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ReportSelectFilterSelectItemEdgeUnion"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemEdgeUnion"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelectItemEdgeInterface"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayable"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "Node"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayableName"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelectItemEdgeOption"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isSelected"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isDisabled"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ReportSelectFilterReport_Selector"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Report_Selector"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Node"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ReportSelectFilterSelectItemEdgeUnion"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const WorkflowStateRangeSelectReport_SelectorFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateRangeSelectReport_Selector"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Report_Selector"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ReportSelectFilterSelectItemEdgeUnion"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ReportSelectFilterSelectItemEdgeUnion"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemEdgeUnion"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelectItemEdgeInterface"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayable"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "Node"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayableName"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelectItemEdgeOption"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isSelected"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isDisabled"
            }
          }]
        }
      }]
    }
  }]
};
export const InsightsChartWorkflowChipDisplayableFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "InsightsChartWorkflowChipDisplayable"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }]
};
export const NameFieldComponentLabelFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "NameFieldComponentLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoriesProgressFieldComponentLabelFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesProgressFieldComponentLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesUnstarted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesInProgress"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesCompleted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsUnstarted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsInProgress"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsCompleted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesBacklog"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsBacklog"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const EpicsProgressFieldComponentLabelFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsProgressFieldComponentLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsUnstarted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsInProgress"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsCompleted"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const DeleteLabelDialogLabelFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteLabelDialogLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const LabelActionsMenuLabelFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LabelActionsMenuLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteLabelDialogLabelFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteLabelDialogLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const MoreActionsFieldComponentLabelFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "MoreActionsFieldComponentLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "LabelActionsMenuLabelFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteLabelDialogLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LabelActionsMenuLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteLabelDialogLabelFragment"
        }
      }]
    }
  }]
};
export const LabelsTableFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LabelsTableFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "NameFieldComponentLabelFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesProgressFieldComponentLabelFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicsProgressFieldComponentLabelFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "MoreActionsFieldComponentLabelFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteLabelDialogLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LabelActionsMenuLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteLabelDialogLabelFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "NameFieldComponentLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesProgressFieldComponentLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesUnstarted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesInProgress"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesCompleted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsUnstarted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsInProgress"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsCompleted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesBacklog"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsBacklog"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsProgressFieldComponentLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsUnstarted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsInProgress"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsCompleted"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "MoreActionsFieldComponentLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "LabelActionsMenuLabelFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const ObjectiveArchivedMessageBarFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveArchivedMessageBar"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const KeyResultDialogFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KeyResultDialog"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "canAddEpics"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objective"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epics"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "initialObservedValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentObservedValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentTargetValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveActionsSectionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveActionsSection"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResults"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateObjectiveAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveObjectiveAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteObjectiveAction"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const ObjectiveDescriptionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveDescription"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "description"
        }
      }]
    }
  }]
};
export const ObjectiveInsightsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveInsights"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsTotal"
            }
          }]
        }
      }]
    }
  }]
};
export const RemoveEpicFromKeyResultFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RemoveEpicFromKeyResult"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const RemoveEpicFromObjectiveFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RemoveEpicFromObjective"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const EpicHealthUpdateSelectFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicHealthUpdateSelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numHealthComments"
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveEpicFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "position"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentStarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastStoryUpdate"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "publicId"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "colorKey"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "GoToEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyEpicLinkActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RemoveEpicFromKeyResult"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RemoveEpicFromObjective"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateEpicAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicHealthUpdateSelect"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyEpicLinkActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RemoveEpicFromKeyResult"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RemoveEpicFromObjective"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateEpicAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicHealthUpdateSelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numHealthComments"
            }
          }]
        }
      }]
    }
  }]
};
export const KeyResultReorderFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KeyResultReorder"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const ObjectiveKeyResultEpicsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultEpics"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const PinObjectiveKeyResultFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinObjectiveKeyResult"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const ViewKeyResultActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ViewKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const CopyKeyResultLinkActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyKeyResultLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const DuplicateKeyResultActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const DeleteKeyResultActionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const ObjectiveKeyResultItemFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "progress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "canAddEpics"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epics"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveKeyResultEpics"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentObservedValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentTargetValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinObjectiveKeyResult"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ViewKeyResultAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyKeyResultLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateKeyResultAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteKeyResultAction"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultEpics"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinObjectiveKeyResult"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ViewKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyKeyResultLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const KeyResultsInObjectiveFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KeyResultsInObjective"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResults"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "KeyResultReorder"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ObjectiveKeyResultItem"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultEpics"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinObjectiveKeyResult"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ViewKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyKeyResultLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KeyResultReorder"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "progress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "canAddEpics"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epics"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveKeyResultEpics"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentObservedValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentTargetValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinObjectiveKeyResult"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ViewKeyResultAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyKeyResultLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateKeyResultAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteKeyResultAction"
        }
      }]
    }
  }]
};
export const EpicIdsInObjectiveFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicIdsInObjective"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "directEpics"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicInput"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "directEpicIDs"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicInput"
            }
          }
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const ObjectiveKeyResultsAndEpicsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultsAndEpics"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "canAddKeyResults"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "KeyResultsInObjective"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicIdsInObjective"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KeyResultReorder"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultEpics"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinObjectiveKeyResult"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ViewKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyKeyResultLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "progress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "canAddEpics"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epics"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveKeyResultEpics"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentObservedValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentTargetValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinObjectiveKeyResult"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ViewKeyResultAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyKeyResultLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateKeyResultAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteKeyResultAction"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KeyResultsInObjective"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResults"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "KeyResultReorder"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ObjectiveKeyResultItem"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicIdsInObjective"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "directEpics"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicInput"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "directEpicIDs"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicInput"
            }
          }
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const ObjectiveNameFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const ObjectiveCurrentHealthFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCurrentHealth"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numHealthComments"
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveEpicProgressFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveEpicProgress"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastStoryUpdate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsTotal"
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveTargetDateProgressFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTargetDateProgress"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "endDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "startDate"
        }
      }]
    }
  }]
};
export const ObjectiveKeyResultProgressFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultProgress"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResultProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastKeyResultUpdate"
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveProgressFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveProgress"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResultProgress"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveCurrentHealth"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveEpicProgress"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveTargetDateProgress"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveKeyResultProgress"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCurrentHealth"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numHealthComments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveEpicProgress"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastStoryUpdate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsTotal"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTargetDateProgress"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "endDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "startDate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultProgress"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResultProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastKeyResultUpdate"
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveDatePickerFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveDatePickerField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "startDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "endDate"
        }
      }]
    }
  }]
};
export const ObjectiveStateFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveStateField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }]
};
export const ObjectiveTeamFieldDataFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTeamFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const ObjectiveTeamsFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTeamsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveTeamFieldData"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTeamFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const ObjectiveOwnerFieldDataFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveOwnerFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveOwnersFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveOwnersField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveOwnerFieldData"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveOwnerFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveCategoryFieldDataFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCategoryFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Category"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const ObjectiveCategoriesFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCategoriesField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "categories"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveCategoryFieldData"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCategoryFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Category"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const ObjectiveDetailsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveDetails"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveDatePickerField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveStateField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveTeamsField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveOwnersField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveCategoriesField"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTeamFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveOwnerFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCategoryFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Category"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveDatePickerField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "startDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "endDate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveStateField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTeamsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveTeamFieldData"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveOwnersField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveOwnerFieldData"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCategoriesField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "categories"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveCategoryFieldData"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveDocsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveDocs"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveSidebarFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveSidebar"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveDetails"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveDocs"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveDatePickerField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "startDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "endDate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveStateField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTeamFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTeamsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveTeamFieldData"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveOwnerFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveOwnersField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveOwnerFieldData"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCategoryFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Category"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCategoriesField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "categories"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveCategoryFieldData"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveDetails"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveDatePickerField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveStateField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveTeamsField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveOwnersField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveCategoriesField"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveDocs"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }]
    }
  }]
};
export const ColumnObjectiveEpicItemFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnObjectiveEpicItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentStarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }]
    }
  }]
};
export const ColumnObjectiveKeyResultItemFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnObjectiveKeyResultItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "progress"
        }
      }]
    }
  }]
};
export const ColumnObjectiveFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnObjective"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentStarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResultProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "startDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "endDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numKeyResults"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsTotal"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "categories"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "publicId"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "publicId"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "publicId"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "colorKey"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResults"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ColumnObjectiveKeyResultItem"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epics"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "orderBy"
                  },
                  "value": {
                    "kind": "ListValue",
                    "values": [{
                      "kind": "ObjectValue",
                      "fields": [{
                        "kind": "ObjectField",
                        "name": {
                          "kind": "Name",
                          "value": "value"
                        },
                        "value": {
                          "kind": "EnumValue",
                          "value": "position"
                        }
                      }, {
                        "kind": "ObjectField",
                        "name": {
                          "kind": "Name",
                          "value": "direction"
                        },
                        "value": {
                          "kind": "EnumValue",
                          "value": "ascending"
                        }
                      }]
                    }]
                  }
                }]
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "directEpics"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicInput"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "directEpicIDs"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicInput"
            }
          }
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "GoToObjectiveAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyObjectiveLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinObjectiveAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveObjectiveAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateObjectiveAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteObjectiveAction"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnObjectiveKeyResultItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "progress"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyObjectiveLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const ObjectiveReorderFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveReorderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const ObjectiveGroupByTypeFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveGroupByType"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }]
};
export const ObjectiveGroupByStateFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveGroupByState"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }]
};
export const ObjectiveGroupByCategoryFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveGroupByCategory"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "categories"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveGroupByHealthFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveGroupByHealth"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }]
    }
  }]
};
export const EpicSelectOptionsFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicSelectOptionsFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "RoadmapContainerEpicSelectionOptionConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageSize"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "groupKey"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "node"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const RoadmapTeamHeaderFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapTeamHeaderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "TeamRoadmapContainer"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsDone"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsStarted"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const TeamRoadmapContainerFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamRoadmapContainerFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "TeamRoadmapContainer"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }]
    }
  }]
};
export const EpicPublicIdFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicPublicIdFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const EpicNameFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicNameFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const EpicStatesFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStatesFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }]
};
export const EpicGroupByEpicStateFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicGroupByEpicStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }]
};
export const EpicsTableEpicStateFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableEpicStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStatesFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicGroupByEpicStateFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStatesFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicGroupByEpicStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }]
};
export const EpicProgressFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicProgressFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStatsProgressFragment"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStatsProgressFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicStats"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsBacklog"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesBacklog"
        }
      }]
    }
  }]
};
export const EpicOwnersFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicOwnersFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const EpicHealthFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicHealthField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicHealthUpdateSelect"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicHealthUpdateSelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numHealthComments"
            }
          }]
        }
      }]
    }
  }]
};
export const EpicCurrentHealthCommentFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicCurrentHealthCommentField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealth"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CurrentHealthCommentDisplay"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CurrentHealthCommentDisplay"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Health"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "text"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "author"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const EpicsGroupByTeamFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsGroupByTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const EpicsTableTeamFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsTeamFieldTeamFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsGroupByTeamFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTeamFieldTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsGroupByTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }]
};
export const EpicObjectiveFieldObjectiveFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicObjectiveFieldObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }]
};
export const EpicGroupByObjectiveObjectiveFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicGroupByObjectiveObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }]
};
export const EpicsTableObjectiveFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectives"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicObjectiveFieldObjectiveFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicGroupByObjectiveObjectiveFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicObjectiveFieldObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicGroupByObjectiveObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }]
};
export const EpicTableRowObjectivesFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicTableRowObjectives"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectives"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "type"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const RoadmapEpicDocsFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicDocsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }]
    }
  }]
};
export const RoadmapEpicCommentsFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicCommentsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numComments"
            }
          }]
        }
      }]
    }
  }]
};
export const EpicStartDateFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStartDateFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "plannedStartDate"
        }
      }]
    }
  }]
};
export const EpicDeadlineFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicDeadlineFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "plannedStartDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }]
};
export const EpicLabelsFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicLabelsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const EpicKeyResultCountFieldFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicKeyResultCountField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numKeyResults"
            }
          }]
        }
      }]
    }
  }]
};
export const RoadmapMoveEpicEntryToBeginningEpicFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToBeginningEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const RoadmapMoveEpicEntryToBeginningActionFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToBeginningActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapMoveEpicEntryToBeginningEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToBeginningEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const RoadmapMoveEpicEntryToEndEpicFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToEndEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const RoadmapMoveEpicEntryToEndActionFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToEndActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapMoveEpicEntryToEndEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToEndEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const RoadmapRemoveEpicEntryEpicFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapRemoveEpicEntryEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const RoadmapRemoveEpicEntryActionFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapRemoveEpicEntryActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapRemoveEpicEntryEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapRemoveEpicEntryEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const RoadmapEpicEntryMoreActionsFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicEntryMoreActionsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "PinEpicActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "GoToEpicActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CopyEpicLinkActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ArchiveEpicActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DeleteEpicActionFragment"
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapMoveEpicEntryToBeginningActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapMoveEpicEntryToEndActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapRemoveEpicEntryActionFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToBeginningEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToEndEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapRemoveEpicEntryEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyEpicLinkActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteEpicFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToBeginningActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapMoveEpicEntryToBeginningEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToEndActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapMoveEpicEntryToEndEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapRemoveEpicEntryActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapRemoveEpicEntryEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const RoadmapEpicEntryFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicEntryFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicPublicIdFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicNameFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicsTableEpicStateFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicProgressFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicOwnersFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicHealthField"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicCurrentHealthCommentField"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicsTableTeamFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicsTableObjectiveFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicTableRowObjectives"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapEpicDocsFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapEpicCommentsFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStartDateFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicDeadlineFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicLabelsFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicKeyResultCountField"
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapEpicEntryMoreActionsFieldFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStatesFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicGroupByEpicStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStatsProgressFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicStats"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsBacklog"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesBacklog"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicHealthUpdateSelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numHealthComments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CurrentHealthCommentDisplay"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Health"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "text"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "author"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTeamFieldTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsGroupByTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicObjectiveFieldObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicGroupByObjectiveObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyEpicLinkActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteEpicFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToBeginningEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToBeginningActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapMoveEpicEntryToBeginningEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToEndEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToEndActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapMoveEpicEntryToEndEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapRemoveEpicEntryEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapRemoveEpicEntryActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapRemoveEpicEntryEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicPublicIdFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicNameFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableEpicStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStatesFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicGroupByEpicStateFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicProgressFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStatsProgressFragment"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicOwnersFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicHealthField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicHealthUpdateSelect"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicCurrentHealthCommentField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealth"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CurrentHealthCommentDisplay"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsTeamFieldTeamFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsGroupByTeamFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectives"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicObjectiveFieldObjectiveFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicGroupByObjectiveObjectiveFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicTableRowObjectives"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectives"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "type"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicDocsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicCommentsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numComments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStartDateFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "plannedStartDate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicDeadlineFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "plannedStartDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicLabelsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicKeyResultCountField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numKeyResults"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicEntryMoreActionsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "PinEpicActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "GoToEpicActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CopyEpicLinkActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ArchiveEpicActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DeleteEpicActionFragment"
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapMoveEpicEntryToBeginningActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapMoveEpicEntryToEndActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapRemoveEpicEntryActionFragment"
        }
      }]
    }
  }]
};
export const RoadmapPaginationFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapPaginationFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "PageInfo"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "offset"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "totalSize"
        }
      }]
    }
  }]
};
export const RoadmapTeamContainerFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapTeamContainerFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workspace2"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teamRoadmapContainers"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "teamInput"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "canAddEpicRoadmapEntries"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "RoadmapTeamHeaderFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "TeamRoadmapContainerFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "entries"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "edges"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "node"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "FragmentSpread",
                                "name": {
                                  "kind": "Name",
                                  "value": "RoadmapEpicEntryFragment"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "RoadmapPaginationFragment"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicPublicIdFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicNameFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStatesFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicGroupByEpicStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableEpicStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStatesFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicGroupByEpicStateFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStatsProgressFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicStats"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsBacklog"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesBacklog"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicProgressFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStatsProgressFragment"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicOwnersFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicHealthUpdateSelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numHealthComments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicHealthField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicHealthUpdateSelect"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CurrentHealthCommentDisplay"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Health"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "text"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "author"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicCurrentHealthCommentField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealth"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CurrentHealthCommentDisplay"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTeamFieldTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsGroupByTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsTeamFieldTeamFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsGroupByTeamFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicObjectiveFieldObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicGroupByObjectiveObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectives"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicObjectiveFieldObjectiveFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicGroupByObjectiveObjectiveFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicTableRowObjectives"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectives"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "type"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicDocsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicCommentsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numComments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStartDateFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "plannedStartDate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicDeadlineFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "plannedStartDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicLabelsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicKeyResultCountField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numKeyResults"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyEpicLinkActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteEpicFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToBeginningEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToBeginningActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapMoveEpicEntryToBeginningEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToEndEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToEndActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapMoveEpicEntryToEndEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapRemoveEpicEntryEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapRemoveEpicEntryActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapRemoveEpicEntryEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicEntryMoreActionsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "PinEpicActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "GoToEpicActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CopyEpicLinkActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ArchiveEpicActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DeleteEpicActionFragment"
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapMoveEpicEntryToBeginningActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapMoveEpicEntryToEndActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapRemoveEpicEntryActionFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapTeamHeaderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "TeamRoadmapContainer"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsDone"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsStarted"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamRoadmapContainerFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "TeamRoadmapContainer"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicEntryFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicPublicIdFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicNameFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicsTableEpicStateFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicProgressFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicOwnersFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicHealthField"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicCurrentHealthCommentField"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicsTableTeamFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicsTableObjectiveFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicTableRowObjectives"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapEpicDocsFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapEpicCommentsFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStartDateFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicDeadlineFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicLabelsFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicKeyResultCountField"
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapEpicEntryMoreActionsFieldFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapPaginationFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "PageInfo"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "offset"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "totalSize"
        }
      }]
    }
  }]
};
export const EditableSpaceFieldsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EditableSpaceFields"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "visibility"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const ColumnHeaderTooltipSpaceResultsEdgeAggregationFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderTooltipSpaceResultsEdgeAggregation"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdgeAggregation"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }]
};
export const ColumnHeaderTooltipSpaceResultsEdgeHeaderFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderTooltipSpaceResultsEdgeHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdgeHeader"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }]
};
export const ColumnHeaderSpaceResultsEdgeFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderSpaceResultsEdge"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headerAggregations"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ColumnHeaderTooltipSpaceResultsEdgeAggregation"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headers"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isWipLimitsEnabled"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "identifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ColumnHeaderTooltipSpaceResultsEdgeHeader"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderTooltipSpaceResultsEdgeAggregation"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdgeAggregation"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderTooltipSpaceResultsEdgeHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdgeHeader"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }]
};
export const WorkflowStateColumnHeaderAggregationsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumnHeaderAggregations"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ColumnHeaderSpaceResultsEdge"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderTooltipSpaceResultsEdgeAggregation"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdgeAggregation"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderTooltipSpaceResultsEdgeHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdgeHeader"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderSpaceResultsEdge"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headerAggregations"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ColumnHeaderTooltipSpaceResultsEdgeAggregation"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headers"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isWipLimitsEnabled"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "identifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ColumnHeaderTooltipSpaceResultsEdgeHeader"
            }
          }]
        }
      }]
    }
  }]
};
export const WorkflowStateColumnFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumn"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "WorkflowState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }]
    }
  }]
};
export const ColorFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }]
};
export const StoryCardFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCard"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "isArchived"
        },
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "storyColorInput"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyColorInput"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }]
};
export const StoryCardBlockedChipFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardBlockedChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "blockedByCount"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hasBlockedLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoryCardBlockerChipFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardBlockerChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "blockerOfCount"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hasBlockerLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoryCardAdvancedCustomFieldChipsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardAdvancedCustomFieldChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "advancedCustomFields"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "field"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "stringValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayableIcon"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "iconIdentifier"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "Color"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }]
};
export const StoryCardDeadlineChipFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDeadlineChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "completedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoryCardDocCountChipFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDocCountChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoryCardDoneChipFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDoneChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "completedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoryCardEpicNameFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardEpicName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoryCardEstimateChipFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardEstimateChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "estimate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoryCardExternalLinkChipsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardExternalLinkChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "externalLinks"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoryCardGroupFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardGroup"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoryCardIterationChipFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardIterationChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPreviousIterations"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoryCardJiraSyncChipFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardJiraSyncChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "syncedItem"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoryCardLabelChipsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardLabelChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const GroupAndOwnersOwnersPermissionFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GroupAndOwnersOwnersPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UserCohortAdapterPermission"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UserCohortAdapterPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }]
};
export const StoryCardOwnersFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardOwners"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "GroupAndOwnersOwnersPermission"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UserCohortAdapterPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GroupAndOwnersOwnersPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UserCohortAdapterPermission"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const CanonicalCustomFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CanonicalCustomFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "selectedValueId"
        },
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "iconIdentifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "Color"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }]
};
export const StoryCardPriorityChipFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardPriorityChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CanonicalCustomFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "selectedValueId"
        },
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "iconIdentifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "Color"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoryCardProductAreaChipFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardProductAreaChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CanonicalCustomFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "selectedValueId"
        },
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "iconIdentifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "Color"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoryCardProjectChipFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardProjectChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "project"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "abbreviation"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoryCardSkillSetChipFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardSkillSetChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CanonicalCustomFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "selectedValueId"
        },
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "iconIdentifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "Color"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoryCardSeverityChipFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardSeverityChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CanonicalCustomFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "selectedValueId"
        },
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "iconIdentifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "Color"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoryCardTaskChipFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTaskChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numTasksCompleted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numTasksTotal"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoryCardTechnicalAreaChipFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTechnicalAreaChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CanonicalCustomFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "selectedValueId"
        },
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "iconIdentifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "Color"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoryCardTitleFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTitle"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "isArchived"
        },
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoryCardTypeChipFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTypeChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoriesPageStoryCardFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageStoryCardFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCard"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardBlockedChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardBlockerChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardAdvancedCustomFieldChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDeadlineChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDocCountChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDoneChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardEpicName"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardEstimateChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardExternalLinkChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardGroup"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardIterationChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardJiraSyncChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardLabelChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardOwners"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardPriorityChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardProductAreaChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardProjectChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardSkillSetChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardSeverityChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTaskChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTechnicalAreaChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTitle"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTypeChip"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UserCohortAdapterPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GroupAndOwnersOwnersPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UserCohortAdapterPermission"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CanonicalCustomFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "selectedValueId"
        },
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "iconIdentifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "Color"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCard"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "isArchived"
        },
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "storyColorInput"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyColorInput"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardBlockedChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "blockedByCount"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hasBlockedLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardBlockerChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "blockerOfCount"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hasBlockerLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardAdvancedCustomFieldChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "advancedCustomFields"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "field"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "stringValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayableIcon"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "iconIdentifier"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "Color"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDeadlineChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "completedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDocCountChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDoneChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "completedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardEpicName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardEstimateChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "estimate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardExternalLinkChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "externalLinks"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardGroup"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardIterationChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPreviousIterations"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardJiraSyncChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "syncedItem"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardLabelChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardOwners"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "GroupAndOwnersOwnersPermission"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardPriorityChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardProductAreaChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardProjectChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "project"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "abbreviation"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardSkillSetChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardSeverityChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTaskChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numTasksCompleted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numTasksTotal"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTechnicalAreaChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTitle"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "isArchived"
        },
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTypeChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoriesPageKanbanFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageKanbanFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "queryKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "groupId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isStale"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "WorkflowStateColumnHeaderAggregations"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayable"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "DisplayableName"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "WorkflowStateColumn"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isStale"
                },
                "directives": [{
                  "kind": "Directive",
                  "name": {
                    "kind": "Name",
                    "value": "client"
                  }
                }]
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesPageStoryCardFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "offset"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "limit"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "offset"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "limit"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderTooltipSpaceResultsEdgeAggregation"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdgeAggregation"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderTooltipSpaceResultsEdgeHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdgeHeader"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderSpaceResultsEdge"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headerAggregations"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ColumnHeaderTooltipSpaceResultsEdgeAggregation"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headers"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isWipLimitsEnabled"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "identifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ColumnHeaderTooltipSpaceResultsEdgeHeader"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCard"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "isArchived"
        },
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "storyColorInput"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyColorInput"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardBlockedChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "blockedByCount"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hasBlockedLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardBlockerChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "blockerOfCount"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hasBlockerLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardAdvancedCustomFieldChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "advancedCustomFields"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "field"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "stringValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayableIcon"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "iconIdentifier"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "Color"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDeadlineChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "completedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDocCountChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDoneChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "completedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardEpicName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardEstimateChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "estimate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardExternalLinkChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "externalLinks"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardGroup"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardIterationChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPreviousIterations"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardJiraSyncChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "syncedItem"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardLabelChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UserCohortAdapterPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GroupAndOwnersOwnersPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UserCohortAdapterPermission"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardOwners"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "GroupAndOwnersOwnersPermission"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CanonicalCustomFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "selectedValueId"
        },
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "iconIdentifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "Color"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardPriorityChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardProductAreaChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardProjectChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "project"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "abbreviation"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardSkillSetChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardSeverityChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTaskChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numTasksCompleted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numTasksTotal"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTechnicalAreaChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTitle"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "isArchived"
        },
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTypeChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumnHeaderAggregations"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ColumnHeaderSpaceResultsEdge"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumn"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "WorkflowState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageStoryCardFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCard"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardBlockedChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardBlockerChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardAdvancedCustomFieldChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDeadlineChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDocCountChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDoneChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardEpicName"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardEstimateChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardExternalLinkChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardGroup"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardIterationChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardJiraSyncChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardLabelChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardOwners"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardPriorityChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardProductAreaChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardProjectChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardSkillSetChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardSeverityChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTaskChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTechnicalAreaChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTitle"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTypeChip"
        }
      }]
    }
  }]
};
export const WorkflowStateColumnViewSettingsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumnViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }]
};
export const KanbanViewSettingsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KanbanViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "WorkflowStateColumnViewSettings"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumnViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }]
};
export const DisplayIconFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }]
};
export const DisplayableIconFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }]
};
export const UrlIdFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UrlIdFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "UrlId"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }]
    }
  }]
};
export const ArchivableFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchivableFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Archivable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }]
};
export const StoriesPageGroupByHeaderFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageGroupByHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Node"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "WorkflowState"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableIcon"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "UrlIdFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ArchivableFragment"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headerAggregations"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableIcon"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UrlIdFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "UrlId"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchivableFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Archivable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }]
};
export const StoriesPageGroupedTableFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageGroupedTable"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageGroupByHeader"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UrlIdFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "UrlId"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchivableFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Archivable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageGroupByHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Node"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "WorkflowState"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableIcon"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "UrlIdFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ArchivableFragment"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headerAggregations"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableIcon"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }]
        }
      }]
    }
  }]
};
export const WorkflowStatesFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStatesFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }]
};
export const StoryCanonicalCustomFieldValuesFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCanonicalCustomFieldValues"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryPriorityFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ProductAreaFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "SeverityFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "SkillSetFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TechnicalAreaFieldFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryPriorityFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ProductAreaFieldItem"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SeverityFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SkillSetFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TechnicalAreaFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }]
};
export const StoryAdvanvedCustomFieldValueFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryAdvanvedCustomFieldValue"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DisplayableIcon"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DisplayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }]
};
export const StoryAdvancedCustomFieldsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryAdvancedCustomFields"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "advancedCustomFields"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "field"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryAdvanvedCustomFieldValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryAdvanvedCustomFieldValue"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DisplayableIcon"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DisplayableName"
        }
      }]
    }
  }]
};
export const StoriesTableBulkEditFieldFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableBulkEditFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }]
    }
  }]
};
export const StoriesPageTableViewLoadMoreFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTableViewLoadMore"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "groupId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageTableFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTable"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "queryKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "groupId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayable"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "Node"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageGroupedTable"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryNameFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryTypeFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesTableTeamFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryOwnersFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryRequesterField"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesEstimateFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryDeadlineFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "WorkflowFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "WorkflowStatesFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesTableEpicFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "IterationFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryLabelsField"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryLastUpdatedField"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryCreatedOnField"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryMoreActionsField"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryCanonicalCustomFieldValues"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryAdvancedCustomFields"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesTableBulkEditFieldFragment"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "offset"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "limit"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTableViewLoadMore"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "offset"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "limit"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UrlIdFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "UrlId"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchivableFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Archivable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageGroupByHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Node"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "WorkflowState"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableIcon"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "UrlIdFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ArchivableFragment"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headerAggregations"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableIcon"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyStoryLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveStoryData"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveStoryData"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryPriorityFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ProductAreaFieldItem"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SeverityFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SkillSetFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TechnicalAreaFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryAdvanvedCustomFieldValue"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DisplayableIcon"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DisplayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageGroupedTable"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageGroupByHeader"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryNameFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryTypeFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mentionName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "TeamDisplayIconFragment"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryOwnersFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryRequesterField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "requester"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mentionName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesEstimateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "estimate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryDeadlineFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflow"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStatesFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epicState"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "IterationFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLabelsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLastUpdatedField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCreatedOnField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryMoreActionsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "GoToStoryAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinStoryAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyStoryLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveStoryAction"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCanonicalCustomFieldValues"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryPriorityFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ProductAreaFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "SeverityFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "SkillSetFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TechnicalAreaFieldFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryAdvancedCustomFields"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "advancedCustomFields"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "field"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryAdvanvedCustomFieldValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableBulkEditFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTableViewLoadMore"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "groupId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageSpaceBodySpaceConfigFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageSpaceBodySpaceConfig"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "kanbanResults2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "kanbanResultsInput"
            }
          }
        }],
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isKanban"
              }
            }
          }]
        }, {
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "connection"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "key"
            },
            "value": {
              "kind": "StringValue",
              "value": "kanban",
              "block": false
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageKanbanFragment"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewSettings"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isWipLimitsEnabled"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "KanbanViewSettings"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "tableResults"
        },
        "name": {
          "kind": "Name",
          "value": "kanbanResults2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "kanbanResultsInput"
            }
          }
        }],
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isTable"
              }
            }
          }]
        }, {
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "connection"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "key"
            },
            "value": {
              "kind": "StringValue",
              "value": "table",
              "block": false
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTable"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderTooltipSpaceResultsEdgeAggregation"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdgeAggregation"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderTooltipSpaceResultsEdgeHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdgeHeader"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderSpaceResultsEdge"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headerAggregations"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ColumnHeaderTooltipSpaceResultsEdgeAggregation"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headers"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isWipLimitsEnabled"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "identifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ColumnHeaderTooltipSpaceResultsEdgeHeader"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumnHeaderAggregations"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ColumnHeaderSpaceResultsEdge"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumn"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "WorkflowState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCard"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "isArchived"
        },
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "storyColorInput"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyColorInput"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardBlockedChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "blockedByCount"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hasBlockedLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardBlockerChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "blockerOfCount"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hasBlockerLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardAdvancedCustomFieldChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "advancedCustomFields"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "field"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "stringValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayableIcon"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "iconIdentifier"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "Color"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDeadlineChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "completedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDocCountChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDoneChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "completedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardEpicName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardEstimateChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "estimate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardExternalLinkChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "externalLinks"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardGroup"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardIterationChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPreviousIterations"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardJiraSyncChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "syncedItem"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardLabelChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UserCohortAdapterPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GroupAndOwnersOwnersPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UserCohortAdapterPermission"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardOwners"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "GroupAndOwnersOwnersPermission"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CanonicalCustomFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "selectedValueId"
        },
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "iconIdentifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "Color"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardPriorityChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardProductAreaChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardProjectChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "project"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "abbreviation"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardSkillSetChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardSeverityChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTaskChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numTasksCompleted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numTasksTotal"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTechnicalAreaChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTitle"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "isArchived"
        },
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTypeChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageStoryCardFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCard"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardBlockedChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardBlockerChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardAdvancedCustomFieldChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDeadlineChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDocCountChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDoneChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardEpicName"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardEstimateChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardExternalLinkChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardGroup"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardIterationChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardJiraSyncChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardLabelChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardOwners"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardPriorityChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardProductAreaChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardProjectChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardSkillSetChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardSeverityChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTaskChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTechnicalAreaChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTitle"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTypeChip"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumnViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UrlIdFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "UrlId"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchivableFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Archivable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageGroupByHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Node"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "WorkflowState"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableIcon"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "UrlIdFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ArchivableFragment"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headerAggregations"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableIcon"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageGroupedTable"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageGroupByHeader"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryNameFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryTypeFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mentionName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "TeamDisplayIconFragment"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryOwnersFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryRequesterField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "requester"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mentionName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesEstimateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "estimate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryDeadlineFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflow"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStatesFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epicState"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "IterationFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLabelsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLastUpdatedField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCreatedOnField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyStoryLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveStoryData"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveStoryData"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryMoreActionsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "GoToStoryAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinStoryAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyStoryLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveStoryAction"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryPriorityFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ProductAreaFieldItem"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SeverityFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SkillSetFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TechnicalAreaFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCanonicalCustomFieldValues"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryPriorityFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ProductAreaFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "SeverityFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "SkillSetFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TechnicalAreaFieldFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryAdvanvedCustomFieldValue"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DisplayableIcon"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DisplayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryAdvancedCustomFields"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "advancedCustomFields"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "field"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryAdvanvedCustomFieldValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableBulkEditFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTableViewLoadMore"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "groupId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageKanbanFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "queryKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "groupId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isStale"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "WorkflowStateColumnHeaderAggregations"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayable"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "DisplayableName"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "WorkflowStateColumn"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isStale"
                },
                "directives": [{
                  "kind": "Directive",
                  "name": {
                    "kind": "Name",
                    "value": "client"
                  }
                }]
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesPageStoryCardFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "offset"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "limit"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "offset"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "limit"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KanbanViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "WorkflowStateColumnViewSettings"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTable"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "queryKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "groupId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayable"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "Node"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageGroupedTable"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryNameFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryTypeFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesTableTeamFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryOwnersFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryRequesterField"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesEstimateFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryDeadlineFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "WorkflowFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "WorkflowStatesFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesTableEpicFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "IterationFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryLabelsField"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryLastUpdatedField"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryCreatedOnField"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryMoreActionsField"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryCanonicalCustomFieldValues"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryAdvancedCustomFields"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesTableBulkEditFieldFragment"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "offset"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "limit"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTableViewLoadMore"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "offset"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "limit"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageSpaceBodyFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageSpaceBody"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "config"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageSpaceBodySpaceConfig"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderTooltipSpaceResultsEdgeAggregation"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdgeAggregation"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderTooltipSpaceResultsEdgeHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdgeHeader"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderSpaceResultsEdge"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headerAggregations"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ColumnHeaderTooltipSpaceResultsEdgeAggregation"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headers"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isWipLimitsEnabled"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "identifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ColumnHeaderTooltipSpaceResultsEdgeHeader"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumnHeaderAggregations"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ColumnHeaderSpaceResultsEdge"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumn"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "WorkflowState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCard"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "isArchived"
        },
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "storyColorInput"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyColorInput"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardBlockedChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "blockedByCount"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hasBlockedLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardBlockerChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "blockerOfCount"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hasBlockerLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardAdvancedCustomFieldChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "advancedCustomFields"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "field"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "stringValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayableIcon"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "iconIdentifier"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "Color"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDeadlineChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "completedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDocCountChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDoneChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "completedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardEpicName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardEstimateChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "estimate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardExternalLinkChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "externalLinks"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardGroup"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardIterationChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPreviousIterations"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardJiraSyncChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "syncedItem"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardLabelChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UserCohortAdapterPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GroupAndOwnersOwnersPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UserCohortAdapterPermission"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardOwners"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "GroupAndOwnersOwnersPermission"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CanonicalCustomFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "selectedValueId"
        },
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "iconIdentifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "Color"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardPriorityChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardProductAreaChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardProjectChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "project"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "abbreviation"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardSkillSetChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardSeverityChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTaskChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numTasksCompleted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numTasksTotal"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTechnicalAreaChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTitle"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "isArchived"
        },
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTypeChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageStoryCardFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCard"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardBlockedChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardBlockerChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardAdvancedCustomFieldChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDeadlineChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDocCountChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDoneChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardEpicName"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardEstimateChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardExternalLinkChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardGroup"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardIterationChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardJiraSyncChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardLabelChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardOwners"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardPriorityChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardProductAreaChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardProjectChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardSkillSetChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardSeverityChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTaskChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTechnicalAreaChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTitle"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTypeChip"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageKanbanFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "queryKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "groupId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isStale"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "WorkflowStateColumnHeaderAggregations"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayable"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "DisplayableName"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "WorkflowStateColumn"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isStale"
                },
                "directives": [{
                  "kind": "Directive",
                  "name": {
                    "kind": "Name",
                    "value": "client"
                  }
                }]
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesPageStoryCardFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "offset"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "limit"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "offset"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "limit"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumnViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KanbanViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "WorkflowStateColumnViewSettings"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UrlIdFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "UrlId"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchivableFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Archivable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageGroupByHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Node"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "WorkflowState"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableIcon"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "UrlIdFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ArchivableFragment"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headerAggregations"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableIcon"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageGroupedTable"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageGroupByHeader"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryNameFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryTypeFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mentionName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "TeamDisplayIconFragment"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryOwnersFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryRequesterField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "requester"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mentionName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesEstimateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "estimate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryDeadlineFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflow"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStatesFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epicState"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "IterationFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLabelsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLastUpdatedField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCreatedOnField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyStoryLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveStoryData"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveStoryData"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryMoreActionsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "GoToStoryAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinStoryAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyStoryLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveStoryAction"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryPriorityFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ProductAreaFieldItem"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SeverityFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SkillSetFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TechnicalAreaFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCanonicalCustomFieldValues"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryPriorityFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ProductAreaFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "SeverityFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "SkillSetFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TechnicalAreaFieldFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryAdvanvedCustomFieldValue"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DisplayableIcon"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DisplayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryAdvancedCustomFields"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "advancedCustomFields"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "field"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryAdvanvedCustomFieldValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableBulkEditFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTableViewLoadMore"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "groupId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTable"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "queryKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "groupId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayable"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "Node"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageGroupedTable"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryNameFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryTypeFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesTableTeamFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryOwnersFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryRequesterField"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesEstimateFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryDeadlineFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "WorkflowFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "WorkflowStatesFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesTableEpicFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "IterationFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryLabelsField"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryLastUpdatedField"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryCreatedOnField"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryMoreActionsField"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryCanonicalCustomFieldValues"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryAdvancedCustomFields"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesTableBulkEditFieldFragment"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "offset"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "limit"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTableViewLoadMore"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "offset"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "limit"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageSpaceBodySpaceConfig"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "kanbanResults2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "kanbanResultsInput"
            }
          }
        }],
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isKanban"
              }
            }
          }]
        }, {
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "connection"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "key"
            },
            "value": {
              "kind": "StringValue",
              "value": "kanban",
              "block": false
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageKanbanFragment"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewSettings"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isWipLimitsEnabled"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "KanbanViewSettings"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "tableResults"
        },
        "name": {
          "kind": "Name",
          "value": "kanbanResults2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "kanbanResultsInput"
            }
          }
        }],
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isTable"
              }
            }
          }]
        }, {
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "connection"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "key"
            },
            "value": {
              "kind": "StringValue",
              "value": "table",
              "block": false
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTable"
            }
          }]
        }
      }]
    }
  }]
};
export const QuickCreateStoryFormStoryQuickCreatePayloadFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "QuickCreateStoryFormStoryQuickCreatePayload"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoryQuickCreatePayload"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyVisibleInSpace"
        }
      }]
    }
  }]
};
export const SpaceCreatorFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceCreator"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }]
};
export const FilterPillOptionsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "maxValueCount"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "multiValueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "values"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabelIcons"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }]
};
export const FilterPillBooleanFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillBoolean"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillBoolean"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }]
    }
  }]
};
export const StoriesPageFilterPillOperatorOptionsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageFilterPillOperatorOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOperatorOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "BooleanValue",
              "value": true
            }
          }]
        }]
      }]
    }
  }]
};
export const FilterPillStringFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillString"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillString"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageFilterPillOperatorOptions"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageFilterPillOperatorOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOperatorOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "BooleanValue",
              "value": true
            }
          }]
        }]
      }]
    }
  }]
};
export const LegacyTranslationImprecisionsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LegacyTranslationImprecisions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "legacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const SpaceConfigViewSettingsViewTypeFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsViewType"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewType"
        }
      }]
    }
  }]
};
export const TableGroupBySelectFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TableGroupBySelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsSingleSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }]
};
export const DisplaySelectConfigViewSettingsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplaySelectConfigViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }]
};
export const SpaceConfigFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfig"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "query"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAllFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAnyFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflowSelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "valueDisplayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "InlineFragment",
                    "typeCondition": {
                      "kind": "NamedType",
                      "name": {
                        "kind": "Name",
                        "value": "Workflow"
                      }
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayableName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "LegacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewSettings"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfigViewSettingsViewType"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tableGroupBySelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "TableGroupBySelect"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplaySelectConfigViewSettings"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageFilterPillOperatorOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOperatorOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "BooleanValue",
              "value": true
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "maxValueCount"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "multiValueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "values"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabelIcons"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillBoolean"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillBoolean"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillString"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillString"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageFilterPillOperatorOptions"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LegacyTranslationImprecisions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "legacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsViewType"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewType"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TableGroupBySelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsSingleSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplaySelectConfigViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }]
};
export const SpaceHeaderFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "visibility"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaveable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSilentlyDeletable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "creator"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceCreator"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "config"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfig"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "savedConfig"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfig"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "maxValueCount"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "multiValueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "values"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabelIcons"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillBoolean"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillBoolean"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageFilterPillOperatorOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOperatorOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "BooleanValue",
              "value": true
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillString"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillString"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageFilterPillOperatorOptions"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LegacyTranslationImprecisions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "legacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsViewType"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewType"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TableGroupBySelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsSingleSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplaySelectConfigViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceCreator"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfig"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "query"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAllFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAnyFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflowSelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "valueDisplayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "InlineFragment",
                    "typeCondition": {
                      "kind": "NamedType",
                      "name": {
                        "kind": "Name",
                        "value": "Workflow"
                      }
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayableName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "LegacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewSettings"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfigViewSettingsViewType"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tableGroupBySelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "TableGroupBySelect"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplaySelectConfigViewSettings"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const SelectItemsConnectionFragmentFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SelectItemsConnectionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isFuzzyNameSupported"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeInterface"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableName"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableIcon"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeHeader"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "headerValue"
                },
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "showSelectAll"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeOption"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isSelected"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const SpaceContextFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceContext"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "creator"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceCreator"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "visibility"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSilentlyDeletable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaveable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "savedConfig"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfig"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "config"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfig"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "maxValueCount"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "multiValueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "values"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabelIcons"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillBoolean"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillBoolean"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageFilterPillOperatorOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOperatorOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "BooleanValue",
              "value": true
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillString"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillString"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageFilterPillOperatorOptions"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LegacyTranslationImprecisions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "legacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsViewType"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewType"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TableGroupBySelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsSingleSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplaySelectConfigViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceCreator"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfig"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "query"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAllFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAnyFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflowSelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "valueDisplayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "InlineFragment",
                    "typeCondition": {
                      "kind": "NamedType",
                      "name": {
                        "kind": "Name",
                        "value": "Workflow"
                      }
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayableName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "LegacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewSettings"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfigViewSettingsViewType"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tableGroupBySelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "TableGroupBySelect"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplaySelectConfigViewSettings"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageTabFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTab"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSilentlyDeletable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "visibility"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "savedConfig"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageTabListFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTab"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTab"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSilentlyDeletable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "visibility"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "savedConfig"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const UseSpaceDeleteFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UseSpaceDelete"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPage"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabList"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTabList"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTab"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSilentlyDeletable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "visibility"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "savedConfig"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTab"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageAddTabSpaceFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageAddTabSpace"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const StoriesPageTabManagerTabListFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabManagerTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageAddTabTabListFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageAddTabTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageAddTabSpace"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageAddTabSpace"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const StoriesPageTabManagerFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabManager"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPage"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "space"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "spacePublicId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageAddTabSpace"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabList"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTabManagerTabList"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageAddTabTabList"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageAddTabSpace"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabManagerTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageAddTabTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageAddTabSpace"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const StoryInGroupIdsFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryInGroupIds"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoryInGroup"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }]
    }
  }]
};
export const StoriesV3ColumnFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesV3Column"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "groupId"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "WorkflowStateColumnHeaderAggregations"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "WorkflowStateColumn"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderTooltipSpaceResultsEdgeAggregation"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdgeAggregation"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderTooltipSpaceResultsEdgeHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdgeHeader"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderSpaceResultsEdge"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headerAggregations"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ColumnHeaderTooltipSpaceResultsEdgeAggregation"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headers"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isWipLimitsEnabled"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "identifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ColumnHeaderTooltipSpaceResultsEdgeHeader"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumnHeaderAggregations"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ColumnHeaderSpaceResultsEdge"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumn"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "WorkflowState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }]
    }
  }]
};
export const StoriesV3TableFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesV3Table"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "groupId"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageGroupByHeader"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UrlIdFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "UrlId"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchivableFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Archivable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageGroupByHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Node"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "WorkflowState"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableIcon"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "UrlIdFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ArchivableFragment"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headerAggregations"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableIcon"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }]
        }
      }]
    }
  }]
};
export const KanbanResultEdgesFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KanbanResultEdges"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "queryKey"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "columnEdges"
        },
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isColumn"
              }
            }
          }]
        }, {
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "connection"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "key"
            },
            "value": {
              "kind": "StringValue",
              "value": "kanban",
              "block": false
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesV3Column"
            }
          }]
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "tableEdges"
        },
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isTable"
              }
            }
          }]
        }, {
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "connection"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "key"
            },
            "value": {
              "kind": "StringValue",
              "value": "table",
              "block": false
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesV3Table"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderTooltipSpaceResultsEdgeAggregation"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdgeAggregation"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderTooltipSpaceResultsEdgeHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdgeHeader"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderSpaceResultsEdge"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headerAggregations"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ColumnHeaderTooltipSpaceResultsEdgeAggregation"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headers"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isWipLimitsEnabled"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "identifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ColumnHeaderTooltipSpaceResultsEdgeHeader"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumnHeaderAggregations"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ColumnHeaderSpaceResultsEdge"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumn"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "WorkflowState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UrlIdFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "UrlId"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchivableFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Archivable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageGroupByHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Node"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "WorkflowState"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableIcon"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "UrlIdFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ArchivableFragment"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headerAggregations"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableIcon"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesV3Column"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "groupId"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "WorkflowStateColumnHeaderAggregations"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "WorkflowStateColumn"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesV3Table"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "groupId"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageGroupByHeader"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }]
};
export const SpaceConfigViewSettingsSpaceFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsSpace"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "config"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "viewSettings"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "SpaceConfigViewSettingsViewType"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "tableGroupBySelect"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "TableGroupBySelect"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "DisplaySelectConfigViewSettings"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsViewType"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewType"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TableGroupBySelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsSingleSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplaySelectConfigViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }]
};
export const TeamSelectDropdownTeamFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamSelectDropdownTeam"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }]
};
export const TeamWorkflowsTeamFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamWorkflowsTeam"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const WorkflowStateItemWorkflowStateFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateItemWorkflowState"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "WorkflowState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "teamIds"
          },
          "value": {
            "kind": "ListValue",
            "values": [{
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "teamId"
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }]
};
export const WorkflowItemWorkflowFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowItemWorkflow"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workflow"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowStates"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "WorkflowStateItemWorkflowState"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateItemWorkflowState"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "WorkflowState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "teamIds"
          },
          "value": {
            "kind": "ListValue",
            "values": [{
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "teamId"
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }]
        }
      }]
    }
  }]
};
export const WipLimitFormWorkflowFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WipLimitFormWorkflow"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workflow"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hasWIPLimits"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "teamIds"
          },
          "value": {
            "kind": "ListValue",
            "values": [{
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "teamId"
              }
            }]
          }
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowStates"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "WorkflowItemWorkflow"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateItemWorkflowState"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "WorkflowState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "teamIds"
          },
          "value": {
            "kind": "ListValue",
            "values": [{
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "teamId"
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowItemWorkflow"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workflow"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowStates"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "WorkflowStateItemWorkflowState"
            }
          }]
        }
      }]
    }
  }]
};
export const TeamWorkflowsWorkflowFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamWorkflowsWorkflow"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workflow"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "WipLimitFormWorkflow"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateItemWorkflowState"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "WorkflowState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "teamIds"
          },
          "value": {
            "kind": "ListValue",
            "values": [{
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "teamId"
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowItemWorkflow"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workflow"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowStates"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "WorkflowStateItemWorkflowState"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WipLimitFormWorkflow"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workflow"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hasWIPLimits"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "teamIds"
          },
          "value": {
            "kind": "ListValue",
            "values": [{
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "teamId"
              }
            }]
          }
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowStates"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "WorkflowItemWorkflow"
        }
      }]
    }
  }]
};
export const UpsertWipLimitsUpdateWorkflowFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UpsertWipLimitsUpdateWorkflow"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workflow"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hasWIPLimits"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "teamIds"
          },
          "value": {
            "kind": "ListValue",
            "values": [{
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "teamId"
              }
            }]
          }
        }]
      }]
    }
  }]
};
export const MoveToIterationContextMenuItemFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "MoveToIterationContextMenuItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentIteration"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "nextIteration"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const ToggleOwnersContextMenuItemFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ToggleOwnersContextMenuItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": []
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const CreateObjectiveDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CreateObjective"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspace2Slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ObjectiveCreateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectiveCreate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "workspace2Slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspace2Slug"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "objective"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "CreateObjectiveResult"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CreateObjectiveResult"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }]
    }
  }]
};
export const FetchEpicsForCreateObjectiveModalDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "FetchEpicsForCreateObjectiveModal"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Workspace2EpicSelectOptionsInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epicSelectOptions"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "groupKey"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetEpicForListingDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetEpicForListing"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Epic"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const TestOptionsSelectQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "TestOptionsSelectQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "ValueOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "FilterPillOptions"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "valueOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "isFuzzyNameSupported"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "InlineFragment",
                        "typeCondition": {
                          "kind": "NamedType",
                          "name": {
                            "kind": "Name",
                            "value": "SelectItemEdgeOption"
                          }
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "displayable"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "displayableName"
                                }
                              }]
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "value"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "isSelected"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryWorkspaceCategorySelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspaceCategorySelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspaceSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Workspace2CategorySelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspaceSlug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "options"
            },
            "name": {
              "kind": "Name",
              "value": "categorySelectOptions"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "groupKey"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const WorkspaceCategorySelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "WorkspaceCategorySelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspaceSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Workspace2CategorySelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspaceSlug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "options"
            },
            "name": {
              "kind": "Name",
              "value": "categorySelectOptions"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "groupKey"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const CategoryNodeByIdDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "CategoryNodeById"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Category"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const ListCategoriesDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "ListCategories"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "CategoryQueryInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "categories"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "connection"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "key"
                },
                "value": {
                  "kind": "StringValue",
                  "value": "categoriesDialog",
                  "block": false
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "CategoryItem"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CategoryItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Category"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectives"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const CreateCategoryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CreateCategory"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CategoryCreateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "categoryCreate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "workspace2Slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "category"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "CategoryItem"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CategoryItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Category"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectives"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateCategoryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateCategory"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CategoryUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "categoryUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "category"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "CategoryItem"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CategoryItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Category"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectives"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const DeleteCategoryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "DeleteCategory"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "categoryDelete"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "success"
            }
          }]
        }
      }]
    }
  }]
};
export const EnabledCustomFieldsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "EnabledCustomFields"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EnabledCustomFields"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EnabledCustomFields"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workspace2"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "enabledCustomFields"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "canonicalName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "publicName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const ArchiveDocUpdateDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "ArchiveDocUpdate"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "DocUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "docUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "doc"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const DeleteDocUpdateDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "DeleteDocUpdate"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "docDelete"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "success"
            }
          }]
        }
      }]
    }
  }]
};
export const RemoveDocActionDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "RemoveDocAction"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "DocUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "docUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "doc"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryWorkspaceEpicStateSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspaceEpicStateSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspaceSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Workspace2EpicStateSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspaceSlug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "options"
            },
            "name": {
              "kind": "Name",
              "value": "epicStateSelectOptions"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "groupKey"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicStateOption"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStateOption"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }]
};
export const EpicMutateEpicStateDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "EpicMutateEpicState"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "EpicMutateEpicStateFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UpdateEpicState"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicMutateEpicStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "UpdateEpicState"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const EpicHealthHistoryQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "EpicHealthHistoryQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "HealthHistoryQueryInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Epic"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "healthHistory"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "HealthStatusUpdate"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "createdAt"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "HealthStatusUpdate"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Health"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "author"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "status"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "text"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reactions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "groupKey"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "emoji"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "author"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "displayName"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }]
    }
  }]
};
export const CreateEpicHealthStatusDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CreateEpicHealthStatus"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicHealthInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicHealthCreate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "workspace2Slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "health"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const EpicKeyResultProgressFieldTooltipDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "EpicKeyResultProgressFieldTooltip"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "objectiveId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "objectiveId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Epic"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "keyResults"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "KeyResultProgressTooltip"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KeyResultProgressTooltip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "progress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentObservedValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentTargetValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const EpicNodeProgressDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "EpicNodeProgress"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "epicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "progressUnit"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Epic"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "stats"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicStatsProgressFragment"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStatsProgressFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicStats"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsBacklog"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesBacklog"
        }
      }]
    }
  }]
};
export const EpicTooltipQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "EpicTooltipQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Epic"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "percentDone"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "percentStarted"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "stats"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "numStoriesTotal"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "numPointsTotal"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "plannedStartDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "deadline"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "currentHealthStatus"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "owners"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "pagination"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "limit"
                          },
                          "value": {
                            "kind": "IntValue",
                            "value": "2"
                          }
                        }]
                      }
                    }]
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "email"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "mentionName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "displayName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "displayIcon"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "url"
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "team"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "url"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "colorKey"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "epicState"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "workspace2"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "canPointStories"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "EpicIconEpic"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicIconEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "stateObject"
        },
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const QueryWorkspaceEpicSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspaceEpicSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspaceSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Workspace2EpicSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspaceSlug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "options"
            },
            "name": {
              "kind": "Name",
              "value": "epicSelectOptions"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "groupKey"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "epicState"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "type"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const DuplicateEpicDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "DuplicateEpic"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "epicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicDuplicate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const MutateEpicDeadlineDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateEpicDeadline"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "EpicDeadlineFieldFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicDeadlineFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "plannedStartDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }]
};
export const QueryEpicEpicStateSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryEpicEpicStateSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "epicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "EpicEpicStateSelectOptionsInput"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Boolean"
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": false
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Epic"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "epicStateSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "EpicStateOption"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStateOption"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }]
};
export const MutateLabelsFieldDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateLabelsField"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicUpdateInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Boolean"
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": true
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "EpicLabelsFieldFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicLabelsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryEpicLabelSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryEpicLabelSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "epicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "EpicLabelSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Epic"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "labelSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "color"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const EpicObjectiveSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "EpicObjectiveSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "epicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "EpicObjectiveSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Epic"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "objectiveSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "state"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "type"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateEpicObjectivesDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateEpicObjectives"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "objectives"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "where"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "archived"
                          },
                          "value": {
                            "kind": "BooleanValue",
                            "value": false
                          }
                        }]
                      }
                    }, {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "orderBy"
                      },
                      "value": {
                        "kind": "ListValue",
                        "values": [{
                          "kind": "ObjectValue",
                          "fields": [{
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "value"
                            },
                            "value": {
                              "kind": "EnumValue",
                              "value": "name"
                            }
                          }, {
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "direction"
                            },
                            "value": {
                              "kind": "EnumValue",
                              "value": "ascending"
                            }
                          }]
                        }]
                      }
                    }]
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "state"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "type"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const MutateEpicOwnersFieldDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateEpicOwnersField"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "epicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicUpdateInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Boolean"
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": true
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "EpicOwnersFieldFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicOwnersFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryEpicOwnersSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryEpicOwnersSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "epicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "EpicOwnersSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Epic"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "ownersSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "state"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "displayName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "mentionName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "email"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "displayIcon"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "thumbnailUrl"
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const MutateStartDateFieldDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateStartDateField"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "EpicStartDateFieldFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStartDateFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "plannedStartDate"
        }
      }]
    }
  }]
};
export const QueryEpicTeamSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryEpicTeamSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "epicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "EpicTeamSelectOptionsInput"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": false
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Epic"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "teamSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "EpicsTeamFieldTeamFragment"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTeamFieldTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }]
};
export const MutateTeamFieldDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateTeamField"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "epicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicUpdateInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Boolean"
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": true
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "team"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsTeamFieldTeamFragment"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTeamFieldTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }]
};
export const UpdateEpicTeamsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateEpicTeams"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "epicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicUpdateInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Boolean"
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": true
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "teams"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "EpicsTeamFieldTeamFragment"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTeamFieldTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }]
};
export const WorkspaceHasEpicsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "WorkspaceHasEpics"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "hasEpics"
            }
          }]
        }
      }]
    }
  }]
};
export const QueryEpicsTableRowsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryEpicsTableRows"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicQueryInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": false
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "includeTeams"
        }
      },
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "canCreateKeyResults"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "canPointStories"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "progressUnit"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "features"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "docs"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "enabled"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epics"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "connection"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "key"
                },
                "value": {
                  "kind": "StringValue",
                  "value": "epicList",
                  "block": false
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicReorderFragment"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicPublicIdFieldFragment"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicNameFieldFragment"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicTotalStoriesFieldFragment"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicTotalPointsFieldFragment"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicRelatedDocsFieldFragment"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicOwnersFieldFragment"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicProgressFieldFragment"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicStartDateFieldFragment"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicDeadlineFieldFragment"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicHealthField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicLabelsFieldFragment"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicCreatedDateFieldFragment"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicLastUpdatedDateFieldFragment"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicTeamsOrAssociatedTeamsFieldFragment"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicMoreActionsFieldFragment"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicTableRowObjectives"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicsTableObjectiveFragment"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicsTableTeamFragment"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicsTableEpicStateFragment"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicKeyResultCountField"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "offset"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStatsProgressFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicStats"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsBacklog"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesBacklog"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicHealthUpdateSelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numHealthComments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTeamFieldTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyEpicLinkActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateEpicAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteEpicFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicObjectiveFieldObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicGroupByObjectiveObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsGroupByTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStatesFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicGroupByEpicStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicReorderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicPublicIdFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicNameFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicTotalStoriesFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesTotal"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicTotalPointsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsTotal"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicRelatedDocsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicOwnersFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicProgressFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStatsProgressFragment"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStartDateFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "plannedStartDate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicDeadlineFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "plannedStartDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicHealthField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicHealthUpdateSelect"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicLabelsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicCreatedDateFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicLastUpdatedDateFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "effectiveUpdatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicTeamsOrAssociatedTeamsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teamsOrAssociatedTeams"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "3"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "includeTeams"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "includeTeams"
                }
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numStoriesOwned"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isEpicOwner"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsTeamFieldTeamFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "members"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "edges"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "node"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsTeamFieldTeamFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicMoreActionsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "GoToEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyEpicLinkActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateEpicAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteEpicActionFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicTableRowObjectives"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectives"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "type"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectives"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicObjectiveFieldObjectiveFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicGroupByObjectiveObjectiveFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsTeamFieldTeamFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsGroupByTeamFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableEpicStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStatesFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicGroupByEpicStateFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicKeyResultCountField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numKeyResults"
            }
          }]
        }
      }]
    }
  }]
};
export const MutateEpicArchiveDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateEpicArchive"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "archived"
              },
              "value": {
                "kind": "BooleanValue",
                "value": true
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ArchiveEpicFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }]
};
export const MutateEpicDeleteDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateEpicDelete"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicDelete"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "success"
            }
          }]
        }
      }]
    }
  }]
};
export const MutateEpicUnarchiveDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateEpicUnarchive"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "archived"
              },
              "value": {
                "kind": "BooleanValue",
                "value": false
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "UnarchiveEpicFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }]
};
export const QueryWorkspaceCustomFieldSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspaceCustomFieldSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspaceSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Workspace2CustomFieldSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspaceSlug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "options"
            },
            "name": {
              "kind": "Name",
              "value": "otherCustomFieldsSelectOptions"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "group"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicName"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "canonicalName"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "stringValue"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "position"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "colorKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "field"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "canonicalName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "publicName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "iconSetIdentifier"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const CanCreateKeyResultsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "CanCreateKeyResults"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "canCreateKeyResults"
            }
          }]
        }
      }]
    }
  }]
};
export const StoryColorOptionsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "StoryColorOptions"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "storyColorOptions"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "SelectItemsConnectionFragment"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SelectItemsConnectionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isFuzzyNameSupported"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeInterface"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableName"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableIcon"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeHeader"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "headerValue"
                },
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "showSelectAll"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeOption"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isSelected"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const FetchEpicSelectOptionsFromKeyResultDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "FetchEpicSelectOptionsFromKeyResult"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "keyResultId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "KeyResultEpicSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "keyResultId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResult"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "epicSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "publicId"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "pageSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const KeyResultTooltipInfoDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "KeyResultTooltipInfo"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResult"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "KeyResultProgressTooltip"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KeyResultProgressTooltip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "progress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentObservedValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentTargetValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryWorkspaceLabelSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspaceLabelSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspaceSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Workspace2LabelSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspaceSlug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "options"
            },
            "name": {
              "kind": "Name",
              "value": "labelSelectOptions"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "groupKey"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "color"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveCategoriesSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCategoriesSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "objectiveId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "ObjectivesCategorySelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "objectiveId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Objective"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "categorySelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "ObjectiveCategoryFieldData"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCategoryFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Category"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const UpdateObjectiveCategoriesDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateObjectiveCategories"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ObjectiveUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectiveUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "objective"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ObjectiveCategoriesField"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCategoryFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Category"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCategoriesField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "categories"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveCategoryFieldData"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateObjectiveDatesDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateObjectiveDates"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ObjectiveUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectiveUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "objective"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ObjectiveDatePickerField"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveDatePickerField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "startDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "endDate"
        }
      }]
    }
  }]
};
export const GetObjectiveHealthHistoryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetObjectiveHealthHistory"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "HealthHistoryQueryInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Objective"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "healthHistory"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "HealthStatusUpdate"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "createdAt"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "HealthStatusUpdate"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Health"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "author"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "status"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "text"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reactions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "groupKey"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "emoji"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "author"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "displayName"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }]
    }
  }]
};
export const CreateObjectiveHealthStatusDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CreateObjectiveHealthStatus"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ObjectiveHealthInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectiveHealthCreate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "workspace2Slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "health"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveKeyResultProgressFieldTooltipDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultProgressFieldTooltip"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "objectiveId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "objectiveId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Objective"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "keyResults"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "KeyResultProgressTooltip"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KeyResultProgressTooltip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "progress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentObservedValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentTargetValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveProgressFieldTooltipDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "ObjectiveProgressFieldTooltip"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "objectiveId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "EpicQueryInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "objectiveId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Objective"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "epics"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "ObjectiveProgressTooltipEpic"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "pageSize"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveProgressTooltipEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentStarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }]
    }
  }]
};
export const QueryWorkspaceObjectiveSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspaceObjectiveSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspaceSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Workspace2ObjectiveSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspaceSlug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "options"
            },
            "name": {
              "kind": "Name",
              "value": "objectiveSelectOptions"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "groupKey"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "state"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "type"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveOwnersSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "ObjectiveOwnersSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "objectiveId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "ObjectiveOwnersSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "objectiveId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Objective"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "ownerSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "ObjectiveOwnerFieldData"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveOwnerFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateObjectiveOwnersDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateObjectiveOwners"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ObjectiveUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectiveUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "objective"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ObjectiveOwnersField"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveOwnerFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveOwnersField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveOwnerFieldData"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateObjectiveStateDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateObjectiveState"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ObjectiveUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectiveUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "objective"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "state"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveTeamsSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTeamsSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "objectiveId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "ObjectiveTeamSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "objectiveId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Objective"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "teamSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "ObjectiveTeamFieldData"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTeamFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const UpdateObjectiveTeamsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateObjectiveTeams"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ObjectiveUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectiveUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "objective"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ObjectiveTeamsField"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTeamFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTeamsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveTeamFieldData"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const ArchiveOrUnarchiveObjectiveDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "ArchiveOrUnarchiveObjective"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ObjectiveUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectiveUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "objective"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "archived"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const DeleteObjectiveActionDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "DeleteObjectiveAction"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectiveDelete"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "success"
            }
          }]
        }
      }]
    }
  }]
};
export const DuplicateObjectiveDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "DuplicateObjective"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectiveDuplicate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "objective"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const DuplicateKeyResultMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "DuplicateKeyResultMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResultDuplicate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "keyResult"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveRelatedDocsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "ObjectiveRelatedDocs"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "objectiveId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "objectiveId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Objective"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "docsSharedToWorkspace2"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "DocItem"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ViewDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyDocLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RemoveDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "docRelationships"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "object"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "InlineFragment",
                        "typeCondition": {
                          "kind": "NamedType",
                          "name": {
                            "kind": "Name",
                            "value": "Node"
                          }
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "creator"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DocItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "creator"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ViewDocAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinDocAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyDocLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RemoveDocAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveDocAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteDocAction"
        }
      }]
    }
  }]
};
export const CreateDocInObjectiveDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CreateDocInObjective"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "DocCreateInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "docCreate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "workspace2Slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "doc"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "urlId"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const WorkspacePermissionsSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "WorkspacePermissionsSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspaceSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Workspace2PermissionSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspaceSlug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "options"
            },
            "name": {
              "kind": "Name",
              "value": "permissionSelectOptions"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "groupKey"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "email"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "displayName"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "displayIcon"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "thumbnailUrl"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const OwnerNodeByIdDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "OwnerNodeById"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Permission"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "email"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayIcon"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "thumbnailUrl"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const OwnersForTooltipDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "OwnersForTooltip"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "ids"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "ID"
              }
            }
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "nodes"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "ids"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "ids"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Permission"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "mentionName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayIcon"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "thumbnailUrl"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "email"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "state"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryWorkspacePermissionSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspacePermissionSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspaceSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Workspace2PermissionSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspaceSlug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "options"
            },
            "name": {
              "kind": "Name",
              "value": "permissionSelectOptions"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "groupKey"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "state"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "mentionName"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "displayName"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "displayIcon"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "thumbnailUrl"
                            }
                          }]
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "email"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryWorkspacePrioritySelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspacePrioritySelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspaceSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Workspace2CustomFieldSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspaceSlug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "options"
            },
            "name": {
              "kind": "Name",
              "value": "prioritySelectOptions"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "stringValue"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "position"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryWorkspaceProjectSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspaceProjectSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspaceSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Workspace2ProjectSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspaceSlug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "options"
            },
            "name": {
              "kind": "Name",
              "value": "projectSelectOptions"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "group"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "color"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryStoryTooltipDataDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryStoryTooltipData"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "updatedAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "workflowState"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "workflow"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "DisplayableName"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "epic"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableName"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableIcon"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "team"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableIcon"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "owners"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "pagination"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "offset"
                          },
                          "value": {
                            "kind": "IntValue",
                            "value": "0"
                          }
                        }, {
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "limit"
                          },
                          "value": {
                            "kind": "IntValue",
                            "value": "5"
                          }
                        }]
                      }
                    }, {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "where"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": []
                      }
                    }, {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "orderBy"
                      },
                      "value": {
                        "kind": "ListValue",
                        "values": []
                      }
                    }]
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "UserCohortAdapterPermission"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UserCohortAdapterPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }]
};
export const ArchiveStoryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "ArchiveStory"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "archived"
              },
              "value": {
                "kind": "BooleanValue",
                "value": true
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ArchiveStoryData"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }]
};
export const UnarchiveStoryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UnarchiveStory"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "archived"
              },
              "value": {
                "kind": "BooleanValue",
                "value": false
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "UnarchiveStoryData"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }]
};
export const StoryAdvancedCustomFieldOptionsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "StoryAdvancedCustomFieldOptions"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryCustomFieldByIdSelectOptionsInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "customFieldSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "DisplayableIcon"
                            }
                          }, {
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "DisplayableName"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }]
};
export const StoryAdvancedCustomFieldMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "StoryAdvancedCustomFieldMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryCustomFieldUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyCustomFieldUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryAdvancedCustomFields"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryAdvanvedCustomFieldValue"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DisplayableIcon"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DisplayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryAdvancedCustomFields"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "advancedCustomFields"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "field"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryAdvanvedCustomFieldValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const MutateStoryDeadlineDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateStoryDeadline"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryDeadlineFieldFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryDeadlineFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }]
    }
  }]
};
export const QueryStoryEpicSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryStoryEpicSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "StoryEpicSelectOptionsInput"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Boolean"
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": false
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "epicSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "EpicSelectFragment"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicSelectFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }]
};
export const MutateStoryEpicDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateStoryEpic"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "epic"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryStoryEstimateSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryStoryEstimateSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "estimateOptions"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "value"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const MutateStoryEstimateDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateStoryEstimate"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimate"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryStoryIterationSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryStoryIterationSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "StoryIterationSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "iterationSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "state"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const MutateStoryIterationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateStoryIteration"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "iteration"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateStoryLabelsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateStoryLabels"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryUpdateInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Boolean"
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": true
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryLabelsField"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLabelsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetStoryLabelOptionsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetStoryLabelOptions"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "StoryLabelSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "labelSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "color"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryStoryOwnerSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryStoryOwnerSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "StoryOwnersSelectOptionsInput"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Boolean"
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": false
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "ownersSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "state"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "displayName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "mentionName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "email"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "displayIcon"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "thumbnailUrl"
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const MutateStoryOwnersFieldDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateStoryOwnersField"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryUpdateInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Boolean"
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": true
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryOwnersFieldFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryOwnersFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryStoryPrioritySelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryStoryPrioritySelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "StoryCustomFieldSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "prioritySelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "stringValue"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "position"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const MutateStoryPriorityFieldDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateStoryPriorityField"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryCanonicalCustomFieldUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyCanonicalCustomFieldUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "priority"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "stringValue"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "position"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryStoryProductAreaSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryStoryProductAreaSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "StoryCustomFieldSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "productAreaSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "ProductAreaFieldItem"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }]
    }
  }]
};
export const MutateStoryProductAreaDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateStoryProductArea"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryCanonicalCustomFieldUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyCanonicalCustomFieldUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "productArea"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoryRequesterSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "StoryRequesterSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "StoryRequesterSelectOptionsInput"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Boolean"
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": false
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "requesterSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "state"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "displayName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "mentionName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "email"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "displayIcon"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "thumbnailUrl"
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateStoryRequesterFieldDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateStoryRequesterField"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryUpdateInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Boolean"
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": true
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryRequesterField"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryRequesterField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "requester"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mentionName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryStorySeveritySelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryStorySeveritySelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "StoryCustomFieldSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "severitySelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "stringValue"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "position"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const MutateSeverityFieldDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateSeverityField"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "StoryCanonicalCustomFieldUpdateInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyCanonicalCustomFieldUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "severity"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryStorySkillSetSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryStorySkillSetSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "StoryCustomFieldSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "skillSetSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "stringValue"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "colorKey"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StorySkillSetFieldUpdateDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "StorySkillSetFieldUpdate"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "StoryCanonicalCustomFieldUpdateInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyCanonicalCustomFieldUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "skillSet"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "stringValue"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "colorKey"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryStoryTypeSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryStoryTypeSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "storyTypeSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "ObjectValue",
                    "fields": []
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const MutateStoryTypeDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateStoryType"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryStoryTeamSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryStoryTeamSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "StoryTeamSelectOptionsInput"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Boolean"
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": false
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "teamSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "publicId"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "colorKey"
                            }
                          }, {
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "TeamDisplayIconFragment"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const MutateStoryTeamFieldDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateStoryTeamField"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryUpdateInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Boolean"
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": false
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoriesTableTeamFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mentionName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "TeamDisplayIconFragment"
            }
          }]
        }
      }]
    }
  }]
};
export const QueryStoryTechnicalAreaSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryStoryTechnicalAreaSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "StoryCustomFieldSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "technicalAreaSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "stringValue"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "colorKey"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const MutateStoryTechnicalAreaFieldDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateStoryTechnicalAreaField"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryCanonicalCustomFieldUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyCanonicalCustomFieldUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "technicalArea"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "stringValue"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "colorKey"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryStoryWorkflowStateSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryStoryWorkflowStateSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "StoryWorkflowStateSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "options"
                },
                "name": {
                  "kind": "Name",
                  "value": "workflowStateSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "type"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const MutateStoryWorkflowStateDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateStoryWorkflowState"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "workflowState"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "type"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const EnabledCustomFieldsQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "EnabledCustomFieldsQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "enabledCustomFields"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "CustomFieldColumnConfig"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CustomFieldColumnConfig"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomField"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "canonicalName"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DisplayableName"
        }
      }]
    }
  }]
};
export const QueryTeamNodeForTooltipDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryTeamNodeForTooltip"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Team"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "mentionName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "colorKey"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayIcon"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "thumbnailUrl"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "members"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "pagination"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "limit"
                          },
                          "value": {
                            "kind": "IntValue",
                            "value": "6"
                          }
                        }]
                      }
                    }]
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "email"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "displayName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "mentionName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "displayIcon"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "thumbnailUrl"
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryWorkspaceTeamSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspaceTeamSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspaceSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Workspace2TeamSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspaceSlug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "options"
            },
            "name": {
              "kind": "Name",
              "value": "teamSelectOptions"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "groupKey"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "mentionName"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "colorKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "displayIcon"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "thumbnailUrl"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const WorkspaceTeamsSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "WorkspaceTeamsSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspaceSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Workspace2TeamSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspaceSlug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "options"
            },
            "name": {
              "kind": "Name",
              "value": "teamSelectOptions"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "groupKey"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "mentionName"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "colorKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "displayIcon"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "thumbnailUrl"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const TeamNodeByIdDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "TeamNodeById"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Team"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "colorKey"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayIcon"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "thumbnailUrl"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const TeamsForTooltipDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "TeamsForTooltip"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "ids"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "ID"
              }
            }
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "nodes"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "ids"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "ids"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Team"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "colorKey"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayIcon"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "thumbnailUrl"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "members"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "pagination"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "limit"
                          },
                          "value": {
                            "kind": "IntValue",
                            "value": "0"
                          }
                        }]
                      }
                    }]
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryWorkspaceWorkflowSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspaceWorkflowSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspaceSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Workspace2WorkflowSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspaceSlug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "options"
            },
            "name": {
              "kind": "Name",
              "value": "workflowSelectOptions"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "groupKey"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const WorkspaceLogoQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "WorkspaceLogoQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const MenuFeaturesQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "MenuFeaturesQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "features"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "MenuFeatures"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "MenuFeatures"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Features"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "projects"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "enabled"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iterations"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "enabled"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "docs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "enabled"
            }
          }]
        }
      }]
    }
  }]
};
export const QueryWorkspaceCurrentPermissionDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspaceCurrentPermission"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentUserPermission"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "email"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayIcon"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "thumbnailUrl"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "url"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QuickSearchRecentlyViewedDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QuickSearchRecentlyViewed"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "RecentlyViewedQueryInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentUserRecentlyViewed"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "StorySearchCardStory"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicSearchCardEpic"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveSearchCardObjective"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "IterationSearchCardIteration"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "DocSearchCardDoc"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "lastViewedAt"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UserCohortAdapterPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StorySearchCardStory"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "UserCohortAdapterPermission"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicSearchCardEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "UserCohortAdapterPermission"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveSearchCardObjective"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "objectiveName"
        },
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "objectiveState"
        },
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "IterationSearchCardIteration"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Iteration"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "iterationName"
        },
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "iterationState"
        },
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "startEpochDay"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "endEpochDay"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DocSearchCardDoc"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "accessControlScope"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "creator"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "UserCohortAdapterPermission"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const SearchDocsQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "SearchDocsQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspaceSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "DocsSearchInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspaceSlug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "docsSearch"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "DocSearchCardDoc"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "hasNextPage"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "endCursor"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "count"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UserCohortAdapterPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DocSearchCardDoc"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "accessControlScope"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "creator"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "UserCohortAdapterPermission"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const NavigationLinkStoryQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "NavigationLinkStoryQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "workflowState"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }]
};
export const UpdateHealthStatusDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateHealthStatus"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "HealthUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "healthUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "health"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "status"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "text"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "updatedAt"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const CreateHealthReactionDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CreateHealthReaction"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "HealthReactionInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "healthReactionCreate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "workspace2Slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "reaction"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const DeleteHealthReactionDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "DeleteHealthReaction"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "healthReactionDelete"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "success"
            }
          }]
        }
      }]
    }
  }]
};
export const QueryDockedItemsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryDockedItems"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "ids"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "ID"
              }
            }
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "nodes"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "ids"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "ids"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DockedStory"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DockedEpic"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DockedIteration"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DockedObjective"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DockedKeyResult"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DockedDoc"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DockedStory"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DockedEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "epicName"
        },
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DockedIteration"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Iteration"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "iterationName"
        },
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DockedObjective"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "objectiveName"
        },
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DockedKeyResult"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "keyResultName"
        },
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objective"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DockedDoc"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }]
    }
  }]
};
export const QueryDockedItemDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryDockedItem"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DockedStory"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DockedEpic"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DockedIteration"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DockedObjective"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DockedKeyResult"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DockedDoc"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DockedStory"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DockedEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "epicName"
        },
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DockedIteration"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Iteration"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "iterationName"
        },
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DockedObjective"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "objectiveName"
        },
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DockedKeyResult"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "keyResultName"
        },
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objective"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DockedDoc"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }]
    }
  }]
};
export const StoryBreadcrumbsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "StoryBreadcrumbs"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "breadcrumbs"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "segments"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "InlineFragment",
                        "typeCondition": {
                          "kind": "NamedType",
                          "name": {
                            "kind": "Name",
                            "value": "ObjectiveConnection"
                          }
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "edges"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "node"
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "type"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "name"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "publicId"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "keyResults"
                                    },
                                    "selectionSet": {
                                      "kind": "SelectionSet",
                                      "selections": [{
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "id"
                                        }
                                      }, {
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "name"
                                        }
                                      }]
                                    }
                                  }]
                                }
                              }]
                            }
                          }]
                        }
                      }, {
                        "kind": "InlineFragment",
                        "typeCondition": {
                          "kind": "NamedType",
                          "name": {
                            "kind": "Name",
                            "value": "KeyResultConnection"
                          }
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "edges"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "node"
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "name"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "objective"
                                    },
                                    "selectionSet": {
                                      "kind": "SelectionSet",
                                      "selections": [{
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "id"
                                        }
                                      }]
                                    }
                                  }]
                                }
                              }]
                            }
                          }]
                        }
                      }, {
                        "kind": "InlineFragment",
                        "typeCondition": {
                          "kind": "NamedType",
                          "name": {
                            "kind": "Name",
                            "value": "EpicConnection"
                          }
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "edges"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "node"
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "name"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "publicId"
                                    }
                                  }]
                                }
                              }]
                            }
                          }]
                        }
                      }, {
                        "kind": "InlineFragment",
                        "typeCondition": {
                          "kind": "NamedType",
                          "name": {
                            "kind": "Name",
                            "value": "StoryConnection"
                          }
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "edges"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "node"
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "name"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "publicId"
                                    }
                                  }]
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const TeamNavigationQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "TeamNavigationQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "ids"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "ID"
              }
            }
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "nodes"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "ids"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "ids"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Team"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "TeamHeaderFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "archived"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamHeaderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }]
    }
  }]
};
export const GetTeamNamesDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetTeamNames"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "ids"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "ID"
              }
            }
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "nodes"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "ids"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "ids"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Team"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const TeamHeaderDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "TeamHeader"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Team"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "archived"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "colorKey"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayIcon"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "thumbnailUrl"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const TeamScopedPageTitleQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "TeamScopedPageTitleQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Team"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const TeamScopeProviderTeamExistsQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "TeamScopeProviderTeamExistsQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Team"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UseInitializeTeamNavigationStateQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "UseInitializeTeamNavigationStateQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspaceSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Workspace2TeamSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspaceSlug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "teamSelectOptions"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "groupKey"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UseSetTeamScopeForDetailPageEpicAssociatedTeamsQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "UseSetTeamScopeForDetailPageEpicAssociatedTeamsQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "includeTeams"
        }
      },
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "entity"
            },
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "publicId"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "team"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "teamsOrAssociatedTeams"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "includeTeams"
                      },
                      "value": {
                        "kind": "Variable",
                        "name": {
                          "kind": "Name",
                          "value": "includeTeams"
                        }
                      }
                    }, {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "pagination"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "limit"
                          },
                          "value": {
                            "kind": "IntValue",
                            "value": "2"
                          }
                        }]
                      }
                    }]
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "isEpicOwner"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UseSetTeamScopeForDetailPageIterationAssociatedTeamsQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "UseSetTeamScopeForDetailPageIterationAssociatedTeamsQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "includeTeams"
        }
      },
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "entity"
            },
            "name": {
              "kind": "Name",
              "value": "iteration"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "publicId"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "team"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "teamsOrAssociatedTeams"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "includeTeams"
                      },
                      "value": {
                        "kind": "Variable",
                        "name": {
                          "kind": "Name",
                          "value": "includeTeams"
                        }
                      }
                    }, {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "pagination"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "limit"
                          },
                          "value": {
                            "kind": "IntValue",
                            "value": "2"
                          }
                        }]
                      }
                    }]
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "isIterationOwner"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UseSetTeamScopeForStoryPageOnFirstRenderQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "UseSetTeamScopeForStoryPageOnFirstRenderQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "publicId"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "team"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const ExampleColocatedFragmentsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "ExampleColocatedFragments"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "WorkspaceCardWorkspace2"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkspaceIconWorkspace2"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workspace2"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkspaceHeaderWorkspace2"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workspace2"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "WorkspaceIconWorkspace2"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkspaceCardWorkspace2"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workspace2"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "WorkspaceHeaderWorkspace2"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const ExampleComponentWorkspaceNameQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "ExampleComponentWorkspaceNameQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }]
    }
  }]
};
export const UseQueryContextTestQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "UseQueryContextTestQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const QueryApplicationStateDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryApplicationState"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentUserPermissionSpaceData"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "parsedCurrentUserPermissionSpaceData"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentUserPermission"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const MutateApplicationStateDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateApplicationState"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "PermissionSpaceDataInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "permissionSpaceDataReset"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "success"
            }
          }]
        }
      }]
    }
  }]
};
export const CreateEpicDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CreateEpic"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspace2Slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicCreateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicCreate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "workspace2Slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspace2Slug"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UseAbortableQueryTestQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "UseAbortableQueryTestQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const QueryCurrentPermissionJoyEnabledDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryCurrentPermissionJoyEnabled"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentUserPermission"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "joyEnabled"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UseMutationTestQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "UseMutationTestQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Epic"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UseMutationTestMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UseMutationTestMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UseOptimisticFragmentUpdateTestQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "UseOptimisticFragmentUpdateTestQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicQueryInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "UseOptimisticFragmentUpdateTestFragment"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UseOptimisticFragmentUpdateTestFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workspace2"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const QueryTranslateUrlTestDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryTranslateUrlTest"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "EpicPageFilterState"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "filters"
            },
            "name": {
              "kind": "Name",
              "value": "canonicalizeEpicPageFilterState"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "teamIds"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryWorkspace2IdDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspace2Id"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }]
    }
  }]
};
export const QueryWorkspaceCurrentPermissionIdDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspaceCurrentPermissionId"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentUserPermission"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const AutomationTeamItemQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "AutomationTeamItemQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Team"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "IterationAutomationTeamFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "IterationAutomationTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }]
    }
  }]
};
export const AutomationTeamCohortQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "AutomationTeamCohortQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "ids"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "ID"
              }
            }
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "nodes"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "ids"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "ids"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Team"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "IterationAutomationTeamFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "IterationAutomationTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }]
    }
  }]
};
export const QueryWorkspaceFutureIterationsAutomationsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspaceFutureIterationsAutomations"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "AutomationEnsureNFutureIterationsQueryInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "relatedAutomations"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "ensureNFutureIterations"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "connection"
                    },
                    "arguments": [{
                      "kind": "Argument",
                      "name": {
                        "kind": "Name",
                        "value": "input"
                      },
                      "value": {
                        "kind": "Variable",
                        "name": {
                          "kind": "Name",
                          "value": "input"
                        }
                      }
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "edges"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "node"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "enabled"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "iterationCount"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "iterationLengthWeeks"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "iterationStartDayOfWeek"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "team"
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    }
                                  }]
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryFutureIterationsTeamSelectOptionsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryFutureIterationsTeamSelectOptions"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "NewAutomationEligibleTeamInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "relatedAutomations"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "ensureNFutureIterations"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "eligibleTeamForNewAutomationSelectOptions"
                    },
                    "arguments": [{
                      "kind": "Argument",
                      "name": {
                        "kind": "Name",
                        "value": "input"
                      },
                      "value": {
                        "kind": "Variable",
                        "name": {
                          "kind": "Name",
                          "value": "input"
                        }
                      }
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "edges"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "node"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "publicId"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "name"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "colorKey"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "displayIcon"
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "thumbnailUrl"
                                    }
                                  }]
                                }
                              }]
                            }
                          }]
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "pageInfo"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "totalSize"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryWorkspaceMoveStoriesIterationAutomationsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspaceMoveStoriesIterationAutomations"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "AutomationMoveStoriesToNextIterationQueryInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "relatedAutomations"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "moveStoriesToNextIteration"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "connection"
                    },
                    "arguments": [{
                      "kind": "Argument",
                      "name": {
                        "kind": "Name",
                        "value": "input"
                      },
                      "value": {
                        "kind": "Variable",
                        "name": {
                          "kind": "Name",
                          "value": "input"
                        }
                      }
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "edges"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "node"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "enabled"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "team"
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    }
                                  }]
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryMoveIterationStoriesTeamSelectOptionsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryMoveIterationStoriesTeamSelectOptions"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "NewAutomationEligibleTeamInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "relatedAutomations"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "moveStoriesToNextIteration"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "eligibleTeamForNewAutomationSelectOptions"
                    },
                    "arguments": [{
                      "kind": "Argument",
                      "name": {
                        "kind": "Name",
                        "value": "input"
                      },
                      "value": {
                        "kind": "Variable",
                        "name": {
                          "kind": "Name",
                          "value": "input"
                        }
                      }
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "edges"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "node"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "publicId"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "name"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "colorKey"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "displayIcon"
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "thumbnailUrl"
                                    }
                                  }]
                                }
                              }]
                            }
                          }]
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "pageInfo"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "totalSize"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const AutomationsUpdateDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "AutomationsUpdate"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "UpdateAutomationsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "automationsUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "workspace2Slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "startEpic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "completeEpic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryEpicsAutomationStatesSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryEpicsAutomationStatesSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "relatedAutomations"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "completeEpic"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "epicStateSelectOptions"
                    },
                    "arguments": [{
                      "kind": "Argument",
                      "name": {
                        "kind": "Name",
                        "value": "input"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": []
                      }
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "edges"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "node"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "name"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "type"
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "startEpic"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "epicStateSelectOptions"
                    },
                    "arguments": [{
                      "kind": "Argument",
                      "name": {
                        "kind": "Name",
                        "value": "input"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": []
                      }
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "edges"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "node"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "name"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "type"
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryWorkspaceEpicsAutomationsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspaceEpicsAutomations"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "relatedAutomations"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "completeEpic"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "enabled"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "epicState"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "startEpic"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "enabled"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "epicState"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const WorkspaceHasBacklogStoriesDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "WorkspaceHasBacklogStories"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "hasBacklogStories"
            }
          }]
        }
      }]
    }
  }]
};
export const QueryStoriesTableRowsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryStoriesTableRows"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryQueryInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "defaultsInput"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "BacklogStoryDefaultsInput"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": false
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EnabledCustomFields"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "features"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "iterations"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "enabled"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "backlogStoryDefaults"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "defaultsInput"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "team"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "publicId"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "workflowState"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "publicId"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "workflow"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stories"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "connection"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "key"
                },
                "value": {
                  "kind": "StringValue",
                  "value": "backlogStoryList",
                  "block": false
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "BacklogStories"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryReorderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "BulkEditFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryPublicIdFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryNameFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryTypeFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryOwnersFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryRequesterField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "requester"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mentionName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "IterationFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryDeadlineFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "BacklogWorkflowStatesField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflow"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epicState"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ProductAreaFieldItem"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TechnicalAreaFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SkillSetFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mentionName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "TeamDisplayIconFragment"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SeverityFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesEstimateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "estimate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryPriorityFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLabelsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLastUpdatedField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCreatedOnField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyStoryLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveStoryData"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveStoryData"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryMoreActionsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "GoToStoryAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinStoryAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyStoryLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveStoryAction"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByOwnerFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByWorkflowStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByWorkflowFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflow"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByTypeFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByIterationFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupBySkillSetFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByTechnicalAreaFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByPriorityFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupBySeverityFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryGroupByProductAreaFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "BacklogStory"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryReorderFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "BulkEditFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryPublicIdFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryNameFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryTypeFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryOwnersFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryRequesterField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryOwnersFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "IterationFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryDeadlineFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "BacklogWorkflowStatesField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "WorkflowFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesTableEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ProductAreaFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TechnicalAreaFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "SkillSetFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesTableTeamFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "SeverityFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesEstimateFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryPriorityFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryLabelsField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryLastUpdatedField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCreatedOnField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryMoreActionsField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByOwnerFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByTeamFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByWorkflowStateFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByWorkflowFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByTypeFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByIterationFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupBySkillSetFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByTechnicalAreaFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByPriorityFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupBySeverityFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryGroupByProductAreaFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EnabledCustomFields"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workspace2"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "enabledCustomFields"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "canonicalName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "publicName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "BacklogStories"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoryConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "node"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "BacklogStory"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "offset"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "limit"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }]
};
export const MutateStoryPositionDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateStoryPosition"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryReorderFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryReorderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const HealthDisplayDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "HealthDisplay"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Epic"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "EpicHealthUpdateSelect"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicHealthUpdateSelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numHealthComments"
            }
          }]
        }
      }]
    }
  }]
};
export const EpicBreadcrumbsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "EpicBreadcrumbs"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "publicId"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "breadcrumbs"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "segments"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "InlineFragment",
                        "typeCondition": {
                          "kind": "NamedType",
                          "name": {
                            "kind": "Name",
                            "value": "ObjectiveConnection"
                          }
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "edges"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "node"
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "type"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "name"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "publicId"
                                    }
                                  }]
                                }
                              }]
                            }
                          }]
                        }
                      }, {
                        "kind": "InlineFragment",
                        "typeCondition": {
                          "kind": "NamedType",
                          "name": {
                            "kind": "Name",
                            "value": "KeyResultConnection"
                          }
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "edges"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "node"
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "name"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "objective"
                                    },
                                    "selectionSet": {
                                      "kind": "SelectionSet",
                                      "selections": [{
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "id"
                                        }
                                      }]
                                    }
                                  }]
                                }
                              }]
                            }
                          }]
                        }
                      }, {
                        "kind": "InlineFragment",
                        "typeCondition": {
                          "kind": "NamedType",
                          "name": {
                            "kind": "Name",
                            "value": "EpicConnection"
                          }
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "edges"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "node"
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "name"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "publicId"
                                    }
                                  }]
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const EpicEntityPageDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "EpicEntityPage"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "publicId"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "EpicPinButton"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "EpicShareButton"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicPinButton"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicShareButton"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const EpicSidebarMoreActionsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "EpicSidebarMoreActions"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "epicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Epic"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "DeleteEpicActionFragment"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "DuplicateEpicAction"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ArchiveEpicActionFragment"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteEpicFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateEpicAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const QueryWorkspaceEpicStatesDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspaceEpicStates"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicStateQueryInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epicStates"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "type"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "hasNextPage"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const NoEpicsInWorkspaceEmptyStateQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "NoEpicsInWorkspaceEmptyStateQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Team"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryWorkspace2CanonicalizeEpicsPageFilterStateDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspace2CanonicalizeEpicsPageFilterState"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "EpicPageFilterState"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "filters"
            },
            "name": {
              "kind": "Name",
              "value": "canonicalizeEpicPageFilterState"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "teamIds"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "labelIds"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "objectiveIds"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "ownerIds"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "projectIds"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "epicStateIds"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryMiniKanbanStoriesDataDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryMiniKanbanStoriesData"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "epicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Epic"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "stories"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "pagination"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "offset"
                          },
                          "value": {
                            "kind": "IntValue",
                            "value": "0"
                          }
                        }, {
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "limit"
                          },
                          "value": {
                            "kind": "IntValue",
                            "value": "100"
                          }
                        }]
                      }
                    }, {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "where"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "archived"
                          },
                          "value": {
                            "kind": "BooleanValue",
                            "value": false
                          }
                        }]
                      }
                    }, {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "orderBy"
                      },
                      "value": {
                        "kind": "ListValue",
                        "values": [{
                          "kind": "ObjectValue",
                          "fields": [{
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "value"
                            },
                            "value": {
                              "kind": "EnumValue",
                              "value": "workflowState_type"
                            }
                          }, {
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "direction"
                            },
                            "value": {
                              "kind": "EnumValue",
                              "value": "ascending"
                            }
                          }]
                        }, {
                          "kind": "ObjectValue",
                          "fields": [{
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "value"
                            },
                            "value": {
                              "kind": "EnumValue",
                              "value": "workflowState_workflow_name"
                            }
                          }, {
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "direction"
                            },
                            "value": {
                              "kind": "EnumValue",
                              "value": "ascending"
                            }
                          }]
                        }, {
                          "kind": "ObjectValue",
                          "fields": [{
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "value"
                            },
                            "value": {
                              "kind": "EnumValue",
                              "value": "workflowState_position"
                            }
                          }, {
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "direction"
                            },
                            "value": {
                              "kind": "EnumValue",
                              "value": "ascending"
                            }
                          }]
                        }, {
                          "kind": "ObjectValue",
                          "fields": [{
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "value"
                            },
                            "value": {
                              "kind": "EnumValue",
                              "value": "updatedAt"
                            }
                          }, {
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "direction"
                            },
                            "value": {
                              "kind": "EnumValue",
                              "value": "descending"
                            }
                          }]
                        }]
                      }
                    }]
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "publicId"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "type"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "team"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "publicId"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "colorKey"
                                }
                              }]
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "workflowState"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "name"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "workflow"
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "name"
                                    }
                                  }]
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "pageSize"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const MutateEpicPositionAndEpicStateDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateEpicPositionAndEpicState"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "EpicColumnReorderFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicColumnReorderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "position"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }]
};
export const QueryWorkspaceEpicStatesByStateDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspaceEpicStatesByState"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicStateQueryInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epicStates"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicStateNode"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "hasNextPage"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStateNode"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }]
};
export const QueryEpicsColumnDataDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryEpicsColumnData"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicQueryInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Boolean"
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": false
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "currentUserId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "canPointStories"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "progressUnit"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "features"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "docs"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "enabled"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epics"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ColumnEpicDataFragment"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "EpicColumnReorderFragment"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "offset"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicTitleDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStatsProgressFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicStats"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsBacklog"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesBacklog"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicProgressDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentStarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStatsProgressFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesUnestimated"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteEpicFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateEpicAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UpdateEpicState"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicMutateEpicStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "UpdateEpicState"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicMoreActionsDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateEpicAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicMutateEpicStateFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicDetailsDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastStoryUpdate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesTotal"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsTotal"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": []
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTeamFieldTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicTeamsOrAssociatedTeamsAndOwnersDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "3"
                  }
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsTeamFieldTeamFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicMiniKanbanDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesTotal"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicFooterDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesInProgress"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesCompleted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesTotal"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastStoryUpdate"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnEpicDataFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsTotal"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesTotal"
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicTitleDataFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicProgressDataFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicMoreActionsDataFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicDetailsDataFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicTeamsOrAssociatedTeamsAndOwnersDataFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicMiniKanbanDataFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicFooterDataFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicColumnReorderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "position"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }]
};
export const MutateEpicPositionDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateEpicPosition"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "EpicReorderFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicReorderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const QueryWorkspaceProjectsFeatureDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspaceProjectsFeature"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "features"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "projects"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "enabled"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryJoinTeamItemsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryJoinTeamItems"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspaceSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "teamInput"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Workspace2TeamSelectOptionsInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "membersInput"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "PermissionQueryInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspaceSlug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "teamSelectOptions"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "teamInput"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "groupKey"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "displayIcon"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "thumbnailUrl"
                            }
                          }]
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "colorKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "members"
                        },
                        "arguments": [{
                          "kind": "Argument",
                          "name": {
                            "kind": "Name",
                            "value": "input"
                          },
                          "value": {
                            "kind": "Variable",
                            "name": {
                              "kind": "Name",
                              "value": "membersInput"
                            }
                          }
                        }],
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "edges"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "node"
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "publicId"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "displayIcon"
                                    },
                                    "selectionSet": {
                                      "kind": "SelectionSet",
                                      "selections": [{
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "id"
                                        }
                                      }, {
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "thumbnailUrl"
                                        }
                                      }]
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "email"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "displayName"
                                    }
                                  }]
                                }
                              }]
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "pageInfo"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "totalSize"
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageSize"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryIterationInsightsReportConfigDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryIterationInsightsReportConfig"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "iterationId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "iterationPage"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "id"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "iterationId"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "reportConfig"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "workflowSelect"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "valueDisplayable"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "InsightsChartWorkflowChipDisplayable"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "InsightsChartWorkflowChipDisplayable"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }]
};
export const QueryDailyBurndownDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryDailyBurndown"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Burndown_EntityReportInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "dailyBurndownReport"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "BurndownChartBurndown_EntityReport"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "BurndownTooltipBurndown_EntityReport"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Burndown_EntityReport"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "burndown"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "completed"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "added"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "removed"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "started"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "unstarted"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "backlog"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reportNowValueEpoch"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "client"
          }
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "BurndownChartBurndown_EntityReport"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Burndown_EntityReport"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "burndown"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "active"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "idealBurndown"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "ideal"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "nonWorkingDays"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "nonWorkingDay"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "xAxis"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reportNowValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reportNowValueEpoch"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "client"
          }
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "BurndownTooltipBurndown_EntityReport"
        }
      }]
    }
  }]
};
export const QueryDetailedBurndownDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryDetailedBurndown"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Burndown_EntityReportInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "detailedBurndownReport"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "BurndownChartBurndown_EntityReport"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "BurndownTooltipBurndown_EntityReport"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Burndown_EntityReport"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "burndown"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "completed"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "added"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "removed"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "started"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "unstarted"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "backlog"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reportNowValueEpoch"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "client"
          }
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "BurndownChartBurndown_EntityReport"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Burndown_EntityReport"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "burndown"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "active"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "idealBurndown"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "ideal"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "nonWorkingDays"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "nonWorkingDay"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "xAxis"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reportNowValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reportNowValueEpoch"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "client"
          }
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "BurndownTooltipBurndown_EntityReport"
        }
      }]
    }
  }]
};
export const QueryCumulativeFlowDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryCumulativeFlow"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CumulativeFlow_EntityReportInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "cumulativeFlowDistributionReport"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "CumulativeFlowChartCumulativeFlowDistribution_EntityReport"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "workflowStates"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "workflowState"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CumulativeFlowTooltipCumulativeFlowDistribution_DataPoint_EntityReport"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CumulativeFlowDistribution_DataPoint_EntityReport"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "distribution"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "percentage"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "storyCount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "estimateSum"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "total"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "storyCount"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "estimateSum"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CumulativeFlowChartCumulativeFlowDistribution_EntityReport"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CumulativeFlowDistribution_EntityReport"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "cumulativeFlow"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "distribution"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "aggregated"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "storyCount"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "estimateSum"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "workflowState"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "type"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "workflow"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CumulativeFlowTooltipCumulativeFlowDistribution_DataPoint_EntityReport"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "nonWorkingDays"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "nonWorkingDay"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "xAxis"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reportNowValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reportNowValueEpoch"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "client"
          }
        }]
      }]
    }
  }]
};
export const QueryIterationPageReportConfigDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryIterationPageReportConfig"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "iterationId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "iterationPage"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "id"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "iterationId"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "reportConfig"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "workflowSelect"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "value"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "WorkflowSelectIteration_ReportConfig_WorkflowSelect"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ReportSelectFilterSelectItemEdgeUnion"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemEdgeUnion"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelectItemEdgeInterface"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayable"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "Node"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayableName"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelectItemEdgeOption"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isSelected"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isDisabled"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowSelectIteration_ReportConfig_WorkflowSelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Iteration_ReportConfig_WorkflowSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Node"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ReportSelectFilterSelectItemEdgeUnion"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const QueryCycleTimeDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryCycleTime"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CycleTimeLeadTime_EntityReportInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "cycleLeadTimeReport"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "CycleTimeChartCycleTimeLeadTime_EntityReport"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "viewConfig"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "storyTypes"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "CycleTimeChartSelectItemsConnection"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "workflowSelector"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "WorkflowSelectReport_Selector"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "chartTypeSelector"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ChartTypeSelectReport_Selector"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "timeUnitSelector"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "TimeUnitSelectReport_Selector"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "workflowStateSelector"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "WorkflowStateRangeSelectReport_Selector"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CycleTimeTooltipStoriesByInstant_EntityReport"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesByInstant_EntityReport"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "timeSpent"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "valueLabel"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "sampledAtEpoch"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "client"
          }
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "story"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "estimate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CycleTimeTooltipTrailingAverageByInstant_EntityReport"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "TrailingAverageByInstant_EntityReport"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "sampledAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "sampledAtEpoch"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "client"
          }
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "trailingAverageTimeSpent"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "valueLabel"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryTypeLegendItem_SelectItemEdgeOption"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemEdgeOption"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSelected"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CycleTimeLegend_SelectItemsConnection"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeOption"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoryTypeLegendItem_SelectItemEdgeOption"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ReportSelectFilterSelectItemEdgeUnion"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemEdgeUnion"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelectItemEdgeInterface"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayable"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "Node"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayableName"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SelectItemEdgeOption"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isSelected"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isDisabled"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ReportSelectFilterReport_Selector"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Report_Selector"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Node"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ReportSelectFilterSelectItemEdgeUnion"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CycleTimeChartCycleTimeLeadTime_EntityReport"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CycleTimeLeadTime_EntityReport"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "standardDeviation"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "standardDeviation"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stories"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "timeSpent"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CycleTimeTooltipStoriesByInstant_EntityReport"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "trailingAverage"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "trailingAverageTimeSpent"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CycleTimeTooltipTrailingAverageByInstant_EntityReport"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iterationAverage"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "iterationAverageTimeSpent"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "valueLabel"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "nonWorkingDays"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "nonWorkingDay"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "xAxis"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sampledAtEpoch"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reportNowValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reportNowValueEpoch"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "client"
          }
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CycleTimeChartSelectItemsConnection"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CycleTimeLegend_SelectItemsConnection"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowSelectReport_Selector"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Report_Selector"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ReportSelectFilterReport_Selector"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ChartTypeSelectReport_Selector"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Report_Selector"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ReportSelectFilterReport_Selector"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TimeUnitSelectReport_Selector"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Report_Selector"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ReportSelectFilterReport_Selector"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateRangeSelectReport_Selector"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Report_Selector"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ReportSelectFilterSelectItemEdgeUnion"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const ArchiveLabelDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "ArchiveLabel"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "labelId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labelUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "labelId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "archived"
              },
              "value": {
                "kind": "BooleanValue",
                "value": true
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "label"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "archived"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UnarchiveLabelDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UnarchiveLabel"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "labelId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labelUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "labelId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "archived"
              },
              "value": {
                "kind": "BooleanValue",
                "value": false
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "label"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "archived"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const DeleteLabelDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "DeleteLabel"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "labelId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labelDelete"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "labelId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "success"
            }
          }]
        }
      }]
    }
  }]
};
export const GetLabelsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetLabels"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "LabelQueryInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "hasLabels"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "labels"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "LabelsTableFragment"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "limit"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "offset"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageCount"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "NameFieldComponentLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesProgressFieldComponentLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesUnstarted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesInProgress"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesCompleted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsUnstarted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsInProgress"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsCompleted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesBacklog"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsBacklog"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsProgressFieldComponentLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsUnstarted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsInProgress"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsCompleted"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteLabelDialogLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LabelActionsMenuLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteLabelDialogLabelFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "MoreActionsFieldComponentLabelFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "LabelActionsMenuLabelFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LabelsTableFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Label"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "NameFieldComponentLabelFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesProgressFieldComponentLabelFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicsProgressFieldComponentLabelFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "MoreActionsFieldComponentLabelFragment"
        }
      }]
    }
  }]
};
export const ObjectivePageDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "ObjectivePage"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "objectivePublicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "epicInput"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicQueryInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "canCreateKeyResults"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "objective"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "publicId"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "objectivePublicId"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "archived"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ObjectiveActionsSection"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ObjectiveName"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ObjectiveDescription"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ObjectiveProgress"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ObjectiveKeyResultsAndEpics"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ObjectiveInsights"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ObjectiveSidebar"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCurrentHealth"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numHealthComments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveEpicProgress"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastStoryUpdate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsTotal"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTargetDateProgress"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "endDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "startDate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultProgress"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResultProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastKeyResultUpdate"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KeyResultReorder"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultEpics"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinObjectiveKeyResult"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ViewKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyKeyResultLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "progress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "canAddEpics"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epics"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveKeyResultEpics"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentObservedValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentTargetValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinObjectiveKeyResult"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ViewKeyResultAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyKeyResultLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateKeyResultAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteKeyResultAction"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KeyResultsInObjective"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResults"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "KeyResultReorder"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ObjectiveKeyResultItem"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicIdsInObjective"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "directEpics"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicInput"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "directEpicIDs"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicInput"
            }
          }
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveDatePickerField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "startDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "endDate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveStateField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTeamFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTeamsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveTeamFieldData"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveOwnerFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveOwnersField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveOwnerFieldData"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCategoryFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Category"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCategoriesField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "categories"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveCategoryFieldData"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveDetails"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveDatePickerField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveStateField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveTeamsField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveOwnersField"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveCategoriesField"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveDocs"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveActionsSection"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResults"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateObjectiveAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveObjectiveAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteObjectiveAction"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveDescription"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "description"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveProgress"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResultProgress"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveCurrentHealth"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveEpicProgress"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveTargetDateProgress"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveKeyResultProgress"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultsAndEpics"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "canAddKeyResults"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "KeyResultsInObjective"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicIdsInObjective"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveInsights"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsTotal"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveSidebar"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveDetails"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveDocs"
        }
      }]
    }
  }]
};
export const UnarchiveObjectiveDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UnarchiveObjective"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectiveUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "archived"
              },
              "value": {
                "kind": "BooleanValue",
                "value": false
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "objective"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "archived"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const DeleteObjectiveDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "DeleteObjective"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectiveDelete"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "success"
            }
          }]
        }
      }]
    }
  }]
};
export const KeyResultDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "KeyResult"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "keyResultId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "keyResultId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResult"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "KeyResultDialog"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KeyResultDialog"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "canAddEpics"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objective"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epics"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "initialObservedValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentObservedValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentTargetValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateKeyResultDialogDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateKeyResultDialog"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "KeyResultUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResultUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "keyResult"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "KeyResultDialog"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KeyResultDialog"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "canAddEpics"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objective"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epics"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "initialObservedValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentObservedValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentTargetValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const DeleteKeyResultDialogDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "DeleteKeyResultDialog"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResultDelete"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "success"
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateObjectiveDescriptionDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateObjectiveDescription"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "objectiveId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ObjectiveUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectiveUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "objectiveId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "objective"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ObjectiveDescription"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveDescription"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "description"
        }
      }]
    }
  }]
};
export const CreateKeyResultDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CreateKeyResult"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "KeyResultCreateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResultCreate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "workspace2Slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "keyResult"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ObjectiveKeyResultItem"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ColumnObjectiveKeyResultItem"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "objective"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "publicId"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultEpics"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinObjectiveKeyResult"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ViewKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyKeyResultLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "progress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "canAddEpics"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epics"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveKeyResultEpics"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentObservedValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentTargetValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinObjectiveKeyResult"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ViewKeyResultAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyKeyResultLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateKeyResultAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteKeyResultAction"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnObjectiveKeyResultItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "progress"
        }
      }]
    }
  }]
};
export const FetchEpicsForCreatingKeyResultsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "FetchEpicsForCreatingKeyResults"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Workspace2EpicSelectOptionsInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epicSelectOptions"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "groupKey"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const DeleteKeyResultMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "DeleteKeyResultMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResultDelete"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "success"
            }
          }]
        }
      }]
    }
  }]
};
export const GetObjectiveEpicDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetObjectiveEpic"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Epic"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ObjectiveEpic"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyEpicLinkActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RemoveEpicFromKeyResult"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RemoveEpicFromObjective"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateEpicAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicHealthUpdateSelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numHealthComments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "position"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentStarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastStoryUpdate"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "publicId"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "colorKey"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "GoToEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyEpicLinkActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RemoveEpicFromKeyResult"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RemoveEpicFromObjective"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateEpicAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicHealthUpdateSelect"
        }
      }]
    }
  }]
};
export const UpdateKeyResultDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateKeyResult"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "KeyResultUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResultUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "keyResult"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ObjectiveKeyResultItem"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultEpics"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinObjectiveKeyResult"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ViewKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyKeyResultLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteKeyResultAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "progress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "canAddEpics"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epics"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveKeyResultEpics"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentObservedValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentTargetValue"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultNumericValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "numericValueString"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "KeyResultBooleanValue"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "booleanValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creator"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinObjectiveKeyResult"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ViewKeyResultAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyKeyResultLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateKeyResultAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteKeyResultAction"
        }
      }]
    }
  }]
};
export const RemoveEpicFromKeyResultDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "RemoveEpicFromKeyResult"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "KeyResultUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResultUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "keyResult"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "epics"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "pagination"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "limit"
                          },
                          "value": {
                            "kind": "IntValue",
                            "value": "40"
                          }
                        }]
                      }
                    }, {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "orderBy"
                      },
                      "value": {
                        "kind": "ListValue",
                        "values": [{
                          "kind": "ObjectValue",
                          "fields": [{
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "direction"
                            },
                            "value": {
                              "kind": "EnumValue",
                              "value": "ascending"
                            }
                          }, {
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "value"
                            },
                            "value": {
                              "kind": "EnumValue",
                              "value": "position"
                            }
                          }]
                        }]
                      }
                    }]
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const RemoveEpicFromObjectiveDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "RemoveEpicFromObjective"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateObjectiveNameDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateObjectiveName"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "objectiveId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ObjectiveUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectiveUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "objectiveId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "objective"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ObjectiveName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const ObjectiveDocsAddRelationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "ObjectiveDocsAddRelation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "DocUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "docUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "doc"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveSearchDocsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "ObjectiveSearchDocs"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ObjectiveDocSelectOptionsInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Objective"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "docSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "title"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "archived"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const ObjectiveDocsListDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "ObjectiveDocsList"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "DocQueryInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "infiniteScroll"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Boolean"
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": true
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Objective"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "docsSharedToWorkspace2"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "DocItem"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ViewDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyDocLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RemoveDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "docRelationships"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "object"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "InlineFragment",
                        "typeCondition": {
                          "kind": "NamedType",
                          "name": {
                            "kind": "Name",
                            "value": "Node"
                          }
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "creator"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DocItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "creator"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ViewDocAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinDocAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyDocLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RemoveDocAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveDocAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteDocAction"
        }
      }]
    }
  }]
};
export const FetchEpicsForObjectiveDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "FetchEpicsForObjective"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ObjectiveEpicSelectOptionsInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Objective"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "epicSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "groupKey"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "node"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pageInfo"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalSize"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "pageSize"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const AddEpicToObjectiveDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "AddEpicToObjective"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "EpicReorderFragment"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ObjectiveEpic"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ColumnObjectiveEpicItem"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyEpicLinkActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RemoveEpicFromKeyResult"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RemoveEpicFromObjective"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateEpicAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicHealthUpdateSelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numHealthComments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicReorderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "position"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentStarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastStoryUpdate"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "publicId"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "colorKey"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "GoToEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyEpicLinkActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RemoveEpicFromKeyResult"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RemoveEpicFromObjective"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateEpicAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicHealthUpdateSelect"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnObjectiveEpicItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentStarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }]
    }
  }]
};
export const AddNewEpicToObjectiveDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "AddNewEpicToObjective"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspace2Slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicCreateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicCreate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "workspace2Slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspace2Slug"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "EpicReorderFragment"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ObjectiveEpic"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ColumnObjectiveEpicItem"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyEpicLinkActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RemoveEpicFromKeyResult"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RemoveEpicFromObjective"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateEpicAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicHealthUpdateSelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numHealthComments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicReorderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "position"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentStarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastStoryUpdate"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "publicId"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "colorKey"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "GoToEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyEpicLinkActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RemoveEpicFromKeyResult"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RemoveEpicFromObjective"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateEpicAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicHealthUpdateSelect"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnObjectiveEpicItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentStarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }]
    }
  }]
};
export const MutateEpicPositionOnObjectiveDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateEpicPositionOnObjective"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epic"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "EpicReorderFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicReorderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const UpdateKeyResultPositionOnObjectiveDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateKeyResultPositionOnObjective"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "KeyResultUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResultUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "keyResult"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "KeyResultReorder"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KeyResultReorder"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const MoveEpicToKeyResultDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MoveEpicToKeyResult"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "KeyResultUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResultUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "keyResult"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetColumnObjectiveEpicItemDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetColumnObjectiveEpicItem"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Epic"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ColumnObjectiveEpicItem"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnObjectiveEpicItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentStarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }]
    }
  }]
};
export const CanCreateKeyResultQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "CanCreateKeyResultQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "canCreateKeyResults"
            }
          }]
        }
      }]
    }
  }]
};
export const WorkspaceHasObjectivesDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "WorkspaceHasObjectives"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "hasObjectives"
            }
          }]
        }
      }]
    }
  }]
};
export const MutateObjectivePositionDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateObjectivePosition"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "objectiveUpdateId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "objectiveUpdateInput"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ObjectiveUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectiveUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "objectiveUpdateId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "objectiveUpdateInput"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "objective"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ObjectiveReorderFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveReorderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const ListObjectivesForColumnDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "ListObjectivesForColumn"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ObjectiveQueryInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "epicInput"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicQueryInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "infiniteScroll"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Boolean"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "objectives"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "connection"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "key"
                },
                "value": {
                  "kind": "StringValue",
                  "value": "objectivesColumnView",
                  "block": false
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ColumnObjective"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "offset"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnObjectiveKeyResultItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "KeyResult"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "progress"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyObjectiveLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnObjective"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentStarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResultProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "startDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "endDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numKeyResults"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsTotal"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "categories"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "publicId"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "publicId"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "publicId"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "colorKey"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResults"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ColumnObjectiveKeyResultItem"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epics"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "orderBy"
                  },
                  "value": {
                    "kind": "ListValue",
                    "values": [{
                      "kind": "ObjectValue",
                      "fields": [{
                        "kind": "ObjectField",
                        "name": {
                          "kind": "Name",
                          "value": "value"
                        },
                        "value": {
                          "kind": "EnumValue",
                          "value": "position"
                        }
                      }, {
                        "kind": "ObjectField",
                        "name": {
                          "kind": "Name",
                          "value": "direction"
                        },
                        "value": {
                          "kind": "EnumValue",
                          "value": "ascending"
                        }
                      }]
                    }]
                  }
                }]
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "directEpics"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicInput"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "directEpicIDs"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicInput"
            }
          }
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "GoToObjectiveAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyObjectiveLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinObjectiveAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveObjectiveAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateObjectiveAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteObjectiveAction"
        }
      }]
    }
  }]
};
export const ListObjectivesForTableDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "ListObjectivesForTable"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "ObjectiveQueryInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "canPointStories"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "canCreateKeyResults"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "objectives"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "connection"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "key"
                },
                "value": {
                  "kind": "StringValue",
                  "value": "objectivesTableView",
                  "block": false
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveReorderFragment"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveNameField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveTypeField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectivePublicIdField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveStateField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveStartDateField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveTargetDateField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveStoryPointsField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveStoryCountField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveEpicCountField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveRelatedDocumentsField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveKeyResultCountField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveKeyResultProgressField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveCategoriesField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveProgressField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveTeamsField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveOwnersField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveHealthField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveCurrentHealthCommentField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveLastUpdatedDateField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveCreatedDateField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveMoreActionsField"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveGroupByType"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveGroupByState"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "ObjectiveGroupByHealth"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "offset"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCategoryFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Category"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTeamFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveOwnerFieldData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveHealthUpdateSelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numHealthComments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CurrentHealthCommentDisplay"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Health"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "text"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "author"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyObjectiveLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DuplicateObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteObjectiveAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveReorderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveNameField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTypeField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectivePublicIdField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveStateField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveStartDateField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "startDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "endDate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTargetDateField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "startDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "endDate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveStoryPointsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPointsTotal"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveStoryCountField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numStoriesTotal"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveEpicCountField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsTotal"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveRelatedDocumentsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultCountField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numKeyResults"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveKeyResultProgressField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "keyResultProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numKeyResults"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCategoriesField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "categories"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveCategoryFieldData"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveProgressField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentStarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "percentDone"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsTotal"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveTeamsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveTeamFieldData"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveOwnersField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "ObjectiveOwnerFieldData"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveHealthField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ObjectiveHealthUpdateSelect"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCurrentHealthCommentField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealth"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CurrentHealthCommentDisplay"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveLastUpdatedDateField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveCreatedDateField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveMoreActionsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "GoToObjectiveAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinObjectiveAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyObjectiveLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveObjectiveAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DuplicateObjectiveAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteObjectiveAction"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveGroupByType"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveGroupByState"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ObjectiveGroupByHealth"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }]
    }
  }]
};
export const QueryWorkspace2CanonicalizeRoadmapTimelineFilterStateDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspace2CanonicalizeRoadmapTimelineFilterState"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "RoadmapTimelineFilterState"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "filters"
            },
            "name": {
              "kind": "Name",
              "value": "canonicalizeRoadmapTimelineFilterState"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "objectives"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "teams"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "epics"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "epicStates"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "epicOwners"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "epicLabels"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "categories"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetRoadmapPageAccessDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetRoadmapPageAccess"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "roadmapViewDefaults"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "hasAccessRoadmap"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const EpicHealthEntryMoveBeforeDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "EpicHealthEntryMoveBefore"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicRoadmapEntryMoveInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicRoadmapEntryMoveBefore"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epicRoadmapEntry"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const EpicHealthEntryMoveAfterDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "EpicHealthEntryMoveAfter"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicRoadmapEntryMoveInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicRoadmapEntryMoveAfter"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epicRoadmapEntry"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const EpicRelatedDocsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "EpicRelatedDocs"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "epicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "epicId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicDocsList"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ViewDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyDocLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RemoveDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteDocAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "docRelationships"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "object"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "InlineFragment",
                        "typeCondition": {
                          "kind": "NamedType",
                          "name": {
                            "kind": "Name",
                            "value": "Node"
                          }
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "creator"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DocItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Doc"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "creator"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ViewDocAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinDocAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyDocLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RemoveDocAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveDocAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteDocAction"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicDocsList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "docsSharedToWorkspace2"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DocItem"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const CreateDocInEpicMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CreateDocInEpicMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspace2Slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "DocCreateInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "docCreate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "workspace2Slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspace2Slug"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "doc"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "urlId"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const EpicRoadmapEntryMoveToBeginningMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "EpicRoadmapEntryMoveToBeginningMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicRoadmapEntryMoveToBeginning"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epicRoadmapEntry"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const EpicRoadmapEntryMoveToEndMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "EpicRoadmapEntryMoveToEndMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicRoadmapEntryMoveToEnd"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epicRoadmapEntry"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const EpicEntryContextMenuDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "EpicEntryContextMenu"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Epic"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ArchiveEpicActionFragment"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "DeleteEpicActionFragment"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "RoadmapMoveEpicEntryToBeginningEpic"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "RoadmapMoveEpicEntryToEndEpic"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "RoadmapRemoveEpicEntryEpic"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteEpicFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToBeginningEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToEndEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapRemoveEpicEntryEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const AvailableEpicsOnTeamContainerQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "AvailableEpicsOnTeamContainerQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "containerId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "RoadmapContainerEpicSelectionOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "containerId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "TeamRoadmapContainer"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "epicSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicSelectOptionsFragment"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicSelectOptionsFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "RoadmapContainerEpicSelectionOptionConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageSize"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "groupKey"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "node"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const CreateEpicAndEntryMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CreateEpicAndEntryMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicAndEpicRoadmapEntryCreateInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": false
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "includeTeams"
        }
      },
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicAndEpicRoadmapEntryCreate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "workspace2Slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epicRoadmapEntry"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "RoadmapEpicEntryFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicPublicIdFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicNameFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStatesFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicGroupByEpicStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableEpicStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStatesFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicGroupByEpicStateFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStatsProgressFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicStats"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsBacklog"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesBacklog"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicProgressFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStatsProgressFragment"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicOwnersFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicHealthUpdateSelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numHealthComments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicHealthField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicHealthUpdateSelect"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CurrentHealthCommentDisplay"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Health"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "text"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "author"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicCurrentHealthCommentField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealth"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CurrentHealthCommentDisplay"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTeamFieldTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsGroupByTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsTeamFieldTeamFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsGroupByTeamFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicObjectiveFieldObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicGroupByObjectiveObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectives"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicObjectiveFieldObjectiveFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicGroupByObjectiveObjectiveFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicTableRowObjectives"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectives"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "type"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicDocsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicCommentsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numComments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStartDateFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "plannedStartDate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicDeadlineFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "plannedStartDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicLabelsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicKeyResultCountField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numKeyResults"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyEpicLinkActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteEpicFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToBeginningEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToBeginningActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapMoveEpicEntryToBeginningEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToEndEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToEndActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapMoveEpicEntryToEndEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapRemoveEpicEntryEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapRemoveEpicEntryActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapRemoveEpicEntryEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicEntryMoreActionsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "PinEpicActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "GoToEpicActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CopyEpicLinkActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ArchiveEpicActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DeleteEpicActionFragment"
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapMoveEpicEntryToBeginningActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapMoveEpicEntryToEndActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapRemoveEpicEntryActionFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicEntryFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicPublicIdFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicNameFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicsTableEpicStateFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicProgressFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicOwnersFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicHealthField"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicCurrentHealthCommentField"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicsTableTeamFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicsTableObjectiveFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicTableRowObjectives"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapEpicDocsFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapEpicCommentsFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStartDateFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicDeadlineFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicLabelsFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicKeyResultCountField"
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapEpicEntryMoreActionsFieldFragment"
        }
      }]
    }
  }]
};
export const AddEpicToContainerMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "AddEpicToContainerMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "EpicRoadmapEntryCreateInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": false
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "includeTeams"
        }
      },
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicRoadmapEntryCreate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "workspace2Slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epicRoadmapEntry"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "RoadmapEpicEntryFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicPublicIdFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicNameFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStatesFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicGroupByEpicStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableEpicStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStatesFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicGroupByEpicStateFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStatsProgressFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicStats"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsBacklog"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesBacklog"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicProgressFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStatsProgressFragment"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicOwnersFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicHealthUpdateSelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numHealthComments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicHealthField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicHealthUpdateSelect"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CurrentHealthCommentDisplay"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Health"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "text"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "author"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicCurrentHealthCommentField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealth"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CurrentHealthCommentDisplay"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTeamFieldTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsGroupByTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsTeamFieldTeamFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsGroupByTeamFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicObjectiveFieldObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicGroupByObjectiveObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectives"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicObjectiveFieldObjectiveFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicGroupByObjectiveObjectiveFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicTableRowObjectives"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectives"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "type"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicDocsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicCommentsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numComments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStartDateFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "plannedStartDate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicDeadlineFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "plannedStartDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicLabelsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicKeyResultCountField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numKeyResults"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyEpicLinkActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteEpicFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToBeginningEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToBeginningActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapMoveEpicEntryToBeginningEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToEndEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToEndActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapMoveEpicEntryToEndEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapRemoveEpicEntryEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapRemoveEpicEntryActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapRemoveEpicEntryEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicEntryMoreActionsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "PinEpicActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "GoToEpicActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CopyEpicLinkActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ArchiveEpicActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DeleteEpicActionFragment"
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapMoveEpicEntryToBeginningActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapMoveEpicEntryToEndActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapRemoveEpicEntryActionFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicEntryFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicPublicIdFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicNameFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicsTableEpicStateFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicProgressFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicOwnersFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicHealthField"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicCurrentHealthCommentField"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicsTableTeamFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicsTableObjectiveFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicTableRowObjectives"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapEpicDocsFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapEpicCommentsFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStartDateFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicDeadlineFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicLabelsFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicKeyResultCountField"
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapEpicEntryMoreActionsFieldFragment"
        }
      }]
    }
  }]
};
export const RemoveEpicEntryMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "RemoveEpicEntryMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicRoadmapEntryDelete"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "success"
            }
          }]
        }
      }]
    }
  }]
};
export const QueryRoadmapContainersDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryRoadmapContainers"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "teamInput"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "TeamRoadmapContainersInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": false
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "includeTeams"
        }
      },
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "progressUnit"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "canCreateKeyResults"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapTeamContainerFragment"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapTeamHeaderFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "TeamRoadmapContainer"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsDone"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numEpicsStarted"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamRoadmapContainerFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "TeamRoadmapContainer"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicPublicIdFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicNameFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStatesFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicGroupByEpicStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableEpicStateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStatesFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicGroupByEpicStateFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStatsProgressFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicStats"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numPointsBacklog"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesCompleted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesInProgress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesUnstarted"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "numStoriesBacklog"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicProgressFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStatsProgressFragment"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicOwnersFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicHealthUpdateSelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealthStatus"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numHealthComments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicHealthField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "EpicHealthUpdateSelect"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CurrentHealthCommentDisplay"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Health"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "text"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "author"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicCurrentHealthCommentField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "currentHealth"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CurrentHealthCommentDisplay"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTeamFieldTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsGroupByTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TeamDisplayIconFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teams"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsTeamFieldTeamFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicsGroupByTeamFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicObjectiveFieldObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicGroupByObjectiveObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Objective"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicsTableObjectiveFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectives"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicObjectiveFieldObjectiveFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "EpicGroupByObjectiveObjectiveFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicTableRowObjectives"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "objectives"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "archived"
                  },
                  "value": {
                    "kind": "BooleanValue",
                    "value": false
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "type"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicDocsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicCommentsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numComments"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicStartDateFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "plannedStartDate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicDeadlineFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "plannedStartDate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epicState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicLabelsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "name"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EpicKeyResultCountField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numKeyResults"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyEpicLinkActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteEpicFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToBeginningEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToBeginningActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapMoveEpicEntryToBeginningEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToEndEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapMoveEpicEntryToEndActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapMoveEpicEntryToEndEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapRemoveEpicEntryEpic"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapRemoveEpicEntryActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapRemoveEpicEntryEpic"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicEntryMoreActionsFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "PinEpicActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "GoToEpicActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CopyEpicLinkActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ArchiveEpicActionFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DeleteEpicActionFragment"
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapMoveEpicEntryToBeginningActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapMoveEpicEntryToEndActionFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapRemoveEpicEntryActionFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapEpicEntryFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "EpicRoadmapEntry"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicPublicIdFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicNameFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicsTableEpicStateFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicProgressFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicOwnersFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicHealthField"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicCurrentHealthCommentField"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicsTableTeamFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicsTableObjectiveFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicTableRowObjectives"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapEpicDocsFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "RoadmapEpicCommentsFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicStartDateFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicDeadlineFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicLabelsFieldFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "EpicKeyResultCountField"
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "RoadmapEpicEntryMoreActionsFieldFragment"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapPaginationFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "PageInfo"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "offset"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "totalSize"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "RoadmapTeamContainerFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workspace2"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "teamRoadmapContainers"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "teamInput"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "canAddEpicRoadmapEntries"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "RoadmapTeamHeaderFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "TeamRoadmapContainerFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "entries"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "edges"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "node"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "FragmentSpread",
                                "name": {
                                  "kind": "Name",
                                  "value": "RoadmapEpicEntryFragment"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "RoadmapPaginationFragment"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const QueryWipLimitsEnabledDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWipLimitsEnabled"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "features"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "wipLimits"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "enabled"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const ValidateSpaceForLegacyStoriesPageDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "ValidateSpaceForLegacyStoriesPage"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "space"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "publicId"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "savedConfigLegacyPosture"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "StoriesPageQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Int"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "teamId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "ID"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "kanbanResultsInput"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SpaceResultsInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyColorInput"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryColorInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "isTable"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "isKanban"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": false
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "isWipLimitsEnabled"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "features"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "iterations"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "enabled"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "storiesPage"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "teamId"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "teamId"
                    }
                  }
                }]
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "space"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "spacePublicId"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "publicId"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "publicId"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "isSaved"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "isSilentlyDeletable"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "SpaceContext"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "SpaceHeader"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesPageSpaceBody"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "tabList"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesPageTabList"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "team"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "UseSpaceDelete"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoriesPageTabManager"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceCreator"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "maxValueCount"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "multiValueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "values"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabelIcons"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillBoolean"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillBoolean"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageFilterPillOperatorOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOperatorOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "BooleanValue",
              "value": true
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillString"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillString"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageFilterPillOperatorOptions"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LegacyTranslationImprecisions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "legacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsViewType"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewType"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TableGroupBySelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsSingleSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplaySelectConfigViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfig"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "query"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAllFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAnyFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflowSelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "valueDisplayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "InlineFragment",
                    "typeCondition": {
                      "kind": "NamedType",
                      "name": {
                        "kind": "Name",
                        "value": "Workflow"
                      }
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayableName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "LegacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewSettings"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfigViewSettingsViewType"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tableGroupBySelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "TableGroupBySelect"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplaySelectConfigViewSettings"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderTooltipSpaceResultsEdgeAggregation"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdgeAggregation"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderTooltipSpaceResultsEdgeHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdgeHeader"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderSpaceResultsEdge"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headerAggregations"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ColumnHeaderTooltipSpaceResultsEdgeAggregation"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headers"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isWipLimitsEnabled"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "identifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ColumnHeaderTooltipSpaceResultsEdgeHeader"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumnHeaderAggregations"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ColumnHeaderSpaceResultsEdge"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumn"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "WorkflowState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCard"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "isArchived"
        },
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "storyColorInput"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyColorInput"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardBlockedChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "blockedByCount"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hasBlockedLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardBlockerChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "blockerOfCount"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hasBlockerLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardAdvancedCustomFieldChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "advancedCustomFields"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "field"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "stringValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayableIcon"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "iconIdentifier"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "Color"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDeadlineChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "completedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDocCountChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDoneChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "completedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardEpicName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardEstimateChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "estimate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardExternalLinkChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "externalLinks"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardGroup"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardIterationChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPreviousIterations"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardJiraSyncChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "syncedItem"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardLabelChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UserCohortAdapterPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GroupAndOwnersOwnersPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UserCohortAdapterPermission"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardOwners"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "GroupAndOwnersOwnersPermission"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CanonicalCustomFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "selectedValueId"
        },
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "iconIdentifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "Color"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardPriorityChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardProductAreaChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardProjectChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "project"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "abbreviation"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardSkillSetChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardSeverityChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTaskChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numTasksCompleted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numTasksTotal"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTechnicalAreaChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTitle"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "isArchived"
        },
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTypeChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageStoryCardFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCard"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardBlockedChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardBlockerChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardAdvancedCustomFieldChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDeadlineChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDocCountChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDoneChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardEpicName"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardEstimateChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardExternalLinkChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardGroup"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardIterationChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardJiraSyncChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardLabelChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardOwners"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardPriorityChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardProductAreaChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardProjectChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardSkillSetChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardSeverityChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTaskChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTechnicalAreaChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTitle"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTypeChip"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageKanbanFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "queryKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "groupId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isStale"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "client"
              }
            }]
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "WorkflowStateColumnHeaderAggregations"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayable"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "DisplayableName"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "WorkflowStateColumn"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isStale"
                },
                "directives": [{
                  "kind": "Directive",
                  "name": {
                    "kind": "Name",
                    "value": "client"
                  }
                }]
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesPageStoryCardFragment"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "offset"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "limit"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "offset"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "limit"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumnViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KanbanViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "WorkflowStateColumnViewSettings"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UrlIdFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "UrlId"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchivableFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Archivable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageGroupByHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Node"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "WorkflowState"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableIcon"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "UrlIdFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ArchivableFragment"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headerAggregations"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableIcon"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageGroupedTable"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageGroupByHeader"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryNameFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryTypeFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mentionName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "TeamDisplayIconFragment"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryOwnersFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryRequesterField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "requester"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mentionName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesEstimateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "estimate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryDeadlineFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflow"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStatesFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epicState"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "IterationFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLabelsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLastUpdatedField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCreatedOnField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyStoryLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveStoryData"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveStoryData"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryMoreActionsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "GoToStoryAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinStoryAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyStoryLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveStoryAction"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryPriorityFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ProductAreaFieldItem"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SeverityFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SkillSetFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TechnicalAreaFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCanonicalCustomFieldValues"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryPriorityFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ProductAreaFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "SeverityFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "SkillSetFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TechnicalAreaFieldFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryAdvanvedCustomFieldValue"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DisplayableIcon"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DisplayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryAdvancedCustomFields"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "advancedCustomFields"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "field"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryAdvanvedCustomFieldValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableBulkEditFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTableViewLoadMore"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "groupId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTable"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "queryKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "groupId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayable"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "Node"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageGroupedTable"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryNameFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryTypeFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesTableTeamFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryOwnersFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryRequesterField"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesEstimateFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryDeadlineFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "WorkflowFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "WorkflowStatesFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesTableEpicFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "IterationFieldFragment"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryLabelsField"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryLastUpdatedField"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryCreatedOnField"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryMoreActionsField"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryCanonicalCustomFieldValues"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoryAdvancedCustomFields"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "StoriesTableBulkEditFieldFragment"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "pageInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "offset"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "limit"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalSize"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTableViewLoadMore"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "offset"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "limit"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageSpaceBodySpaceConfig"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "kanbanResults2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "kanbanResultsInput"
            }
          }
        }],
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isKanban"
              }
            }
          }]
        }, {
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "connection"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "key"
            },
            "value": {
              "kind": "StringValue",
              "value": "kanban",
              "block": false
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageKanbanFragment"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewSettings"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isWipLimitsEnabled"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "KanbanViewSettings"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "tableResults"
        },
        "name": {
          "kind": "Name",
          "value": "kanbanResults2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "kanbanResultsInput"
            }
          }
        }],
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isTable"
              }
            }
          }]
        }, {
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "connection"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "key"
            },
            "value": {
              "kind": "StringValue",
              "value": "table",
              "block": false
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTable"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTab"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSilentlyDeletable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "visibility"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "savedConfig"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTab"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageAddTabSpace"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabManagerTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageAddTabTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageAddTabSpace"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceContext"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "creator"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceCreator"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "visibility"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSilentlyDeletable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaveable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "savedConfig"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfig"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "config"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfig"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "visibility"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaveable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSilentlyDeletable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "creator"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceCreator"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "config"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfig"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "savedConfig"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfig"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageSpaceBody"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "config"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageSpaceBodySpaceConfig"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UseSpaceDelete"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPage"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabList"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTabList"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabManager"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPage"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "space"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "spacePublicId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageAddTabSpace"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabList"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTabManagerTabList"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageAddTabTabList"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const EditableSpaceFieldsQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "EditableSpaceFieldsQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "spaceId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "spaceId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Space"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "EditableSpaceFields"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "EditableSpaceFields"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "visibility"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }]
};
export const StoryQuickCreateDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "StoryQuickCreate"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspace2Slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryQuickCreateInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyColorInput"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryColorInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyQuickCreate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "workspace2Slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspace2Slug"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoriesPageStoryCardFragment"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryCreatedToast"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "storyVisibleInSpace"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "QuickCreateStoryFormStoryQuickCreatePayload"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCard"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "isArchived"
        },
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "storyColorInput"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyColorInput"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardBlockedChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "blockedByCount"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hasBlockedLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardBlockerChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "blockerOfCount"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hasBlockerLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardAdvancedCustomFieldChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "advancedCustomFields"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "field"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "stringValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayableIcon"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "iconIdentifier"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "Color"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDeadlineChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "completedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDocCountChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDoneChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "completedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardEpicName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardEstimateChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "estimate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardExternalLinkChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "externalLinks"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardGroup"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardIterationChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPreviousIterations"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardJiraSyncChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "syncedItem"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardLabelChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UserCohortAdapterPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GroupAndOwnersOwnersPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UserCohortAdapterPermission"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardOwners"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "GroupAndOwnersOwnersPermission"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CanonicalCustomFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "selectedValueId"
        },
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "iconIdentifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "Color"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardPriorityChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardProductAreaChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardProjectChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "project"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "abbreviation"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardSkillSetChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardSeverityChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTaskChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numTasksCompleted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numTasksTotal"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTechnicalAreaChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTitle"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "isArchived"
        },
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTypeChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageStoryCardFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCard"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardBlockedChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardBlockerChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardAdvancedCustomFieldChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDeadlineChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDocCountChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDoneChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardEpicName"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardEstimateChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardExternalLinkChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardGroup"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardIterationChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardJiraSyncChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardLabelChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardOwners"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardPriorityChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardProductAreaChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardProjectChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardSkillSetChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardSeverityChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTaskChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTechnicalAreaChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTitle"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTypeChip"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCreatedToast"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "QuickCreateStoryFormStoryQuickCreatePayload"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoryQuickCreatePayload"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyVisibleInSpace"
        }
      }]
    }
  }]
};
export const StoriesPageReorderMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "StoriesPageReorderMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "ids"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "ID"
              }
            }
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyBulkUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "ids"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "ids"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stories"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageFilterOperatorSelectQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "StoriesPageFilterOperatorSelectQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "FilterPillOperatorOptions"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "operatorOptions"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "SelectItemsConnectionFragment"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SelectItemsConnectionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isFuzzyNameSupported"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeInterface"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableName"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableIcon"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeHeader"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "headerValue"
                },
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "showSelectAll"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeOption"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isSelected"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageFilterBooleanValueOptionsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "StoriesPageFilterBooleanValueOptions"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "ValueOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "FilterPillBoolean"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "valueOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "SelectItemsConnectionFragment"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SelectItemsConnectionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isFuzzyNameSupported"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeInterface"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableName"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableIcon"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeHeader"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "headerValue"
                },
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "showSelectAll"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeOption"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isSelected"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageFilterValueSelectQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "StoriesPageFilterValueSelectQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "ValueOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "FilterPillOptions"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "valueOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "SelectItemsConnectionFragment"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SelectItemsConnectionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isFuzzyNameSupported"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeInterface"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableName"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableIcon"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeHeader"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "headerValue"
                },
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "showSelectAll"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeOption"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isSelected"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const FilterTypeSelectOptionsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "FilterTypeSelectOptions"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "spaceId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "ValueOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "spaceId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Space"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "config"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "filterTypeSelectOptions"
                    },
                    "arguments": [{
                      "kind": "Argument",
                      "name": {
                        "kind": "Name",
                        "value": "input"
                      },
                      "value": {
                        "kind": "Variable",
                        "name": {
                          "kind": "Name",
                          "value": "input"
                        }
                      }
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "SelectItemsConnectionFragment"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SelectItemsConnectionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isFuzzyNameSupported"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeInterface"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableName"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableIcon"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeHeader"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "headerValue"
                },
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "showSelectAll"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeOption"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isSelected"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageTableViewGroupByValueOptionsQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTableViewGroupByValueOptionsQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ValueOptionsInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Space"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "config"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "viewSettings"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "tableGroupBySelect"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "TableGroupBySelect"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "valueOptions"
                            },
                            "arguments": [{
                              "kind": "Argument",
                              "name": {
                                "kind": "Name",
                                "value": "input"
                              },
                              "value": {
                                "kind": "Variable",
                                "name": {
                                  "kind": "Name",
                                  "value": "input"
                                }
                              }
                            }],
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "FragmentSpread",
                                "name": {
                                  "kind": "Name",
                                  "value": "SelectItemsConnectionFragment"
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TableGroupBySelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsSingleSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SelectItemsConnectionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isFuzzyNameSupported"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeInterface"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableName"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableIcon"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeHeader"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "headerValue"
                },
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "showSelectAll"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeOption"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isSelected"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageWorkflowSelectQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "StoriesPageWorkflowSelectQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "spaceId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "ValueOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "spaceId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Space"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "config"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "query"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "workflowSelect"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "valueOptions"
                            },
                            "arguments": [{
                              "kind": "Argument",
                              "name": {
                                "kind": "Name",
                                "value": "input"
                              },
                              "value": {
                                "kind": "Variable",
                                "name": {
                                  "kind": "Name",
                                  "value": "input"
                                }
                              }
                            }],
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "FragmentSpread",
                                "name": {
                                  "kind": "Name",
                                  "value": "SelectItemsConnectionFragment"
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SelectItemsConnectionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isFuzzyNameSupported"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeInterface"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableName"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableIcon"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeHeader"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "headerValue"
                },
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "showSelectAll"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeOption"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isSelected"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesAddFilterPillMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "StoriesAddFilterPillMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SpaceAddFilterInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "spaceAddFilter"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "filterPill"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "space"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isSaved"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "config"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "SpaceConfig"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "maxValueCount"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "multiValueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "values"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabelIcons"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillBoolean"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillBoolean"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageFilterPillOperatorOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOperatorOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "BooleanValue",
              "value": true
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillString"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillString"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageFilterPillOperatorOptions"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LegacyTranslationImprecisions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "legacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsViewType"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewType"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TableGroupBySelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsSingleSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplaySelectConfigViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfig"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "query"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAllFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAnyFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflowSelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "valueDisplayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "InlineFragment",
                    "typeCondition": {
                      "kind": "NamedType",
                      "name": {
                        "kind": "Name",
                        "value": "Workflow"
                      }
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayableName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "LegacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewSettings"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfigViewSettingsViewType"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tableGroupBySelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "TableGroupBySelect"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplaySelectConfigViewSettings"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesDeleteFilterPillMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "StoriesDeleteFilterPillMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SpaceDeleteFilterInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "spaceDeleteFilter"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "space"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "config"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "SpaceConfig"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "maxValueCount"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "multiValueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "values"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabelIcons"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillBoolean"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillBoolean"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageFilterPillOperatorOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOperatorOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "BooleanValue",
              "value": true
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillString"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillString"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageFilterPillOperatorOptions"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LegacyTranslationImprecisions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "legacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsViewType"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewType"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TableGroupBySelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsSingleSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplaySelectConfigViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfig"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "query"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAllFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAnyFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflowSelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "valueDisplayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "InlineFragment",
                    "typeCondition": {
                      "kind": "NamedType",
                      "name": {
                        "kind": "Name",
                        "value": "Workflow"
                      }
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayableName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "LegacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewSettings"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfigViewSettingsViewType"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tableGroupBySelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "TableGroupBySelect"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplaySelectConfigViewSettings"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const SpaceDeleteMatchGroupMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "SpaceDeleteMatchGroupMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SpaceDeleteMatchGroupInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "spaceDeleteMatchGroup"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "space"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "config"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "SpaceConfig"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "maxValueCount"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "multiValueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "values"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabelIcons"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillBoolean"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillBoolean"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageFilterPillOperatorOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOperatorOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "BooleanValue",
              "value": true
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillString"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillString"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageFilterPillOperatorOptions"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LegacyTranslationImprecisions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "legacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsViewType"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewType"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TableGroupBySelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsSingleSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplaySelectConfigViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfig"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "query"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAllFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAnyFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflowSelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "valueDisplayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "InlineFragment",
                    "typeCondition": {
                      "kind": "NamedType",
                      "name": {
                        "kind": "Name",
                        "value": "Workflow"
                      }
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayableName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "LegacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewSettings"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfigViewSettingsViewType"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tableGroupBySelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "TableGroupBySelect"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplaySelectConfigViewSettings"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const SpaceSaveUnsavedConfigDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "SpaceSaveUnsavedConfig"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SpaceSaveUnsavedConfigInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "spaceSaveUnsavedConfig"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "space"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isSaved"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const SpaceUpdateFilterDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "SpaceUpdateFilter"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SpaceUpdateFilterInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "spaceUpdateFilter"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "space"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isSaved"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "config"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "SpaceConfig"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "maxValueCount"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "multiValueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "values"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabelIcons"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillBoolean"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillBoolean"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageFilterPillOperatorOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOperatorOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "BooleanValue",
              "value": true
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillString"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillString"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageFilterPillOperatorOptions"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LegacyTranslationImprecisions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "legacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsViewType"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewType"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TableGroupBySelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsSingleSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplaySelectConfigViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfig"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "query"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAllFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAnyFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflowSelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "valueDisplayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "InlineFragment",
                    "typeCondition": {
                      "kind": "NamedType",
                      "name": {
                        "kind": "Name",
                        "value": "Workflow"
                      }
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayableName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "LegacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewSettings"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfigViewSettingsViewType"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tableGroupBySelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "TableGroupBySelect"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplaySelectConfigViewSettings"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const SpaceUpdateFilterBooleanValueDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "SpaceUpdateFilterBooleanValue"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SpaceUpdateFilterInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "spaceUpdateFilter"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "space"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isSaved"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "config"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "SpaceConfig"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "maxValueCount"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "multiValueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "values"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabelIcons"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillBoolean"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillBoolean"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageFilterPillOperatorOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOperatorOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "BooleanValue",
              "value": true
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillString"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillString"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageFilterPillOperatorOptions"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LegacyTranslationImprecisions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "legacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsViewType"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewType"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TableGroupBySelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsSingleSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplaySelectConfigViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfig"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "query"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAllFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAnyFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflowSelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "valueDisplayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "InlineFragment",
                    "typeCondition": {
                      "kind": "NamedType",
                      "name": {
                        "kind": "Name",
                        "value": "Workflow"
                      }
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayableName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "LegacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewSettings"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfigViewSettingsViewType"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tableGroupBySelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "TableGroupBySelect"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplaySelectConfigViewSettings"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const SpaceUpdateFilterStringValueDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "SpaceUpdateFilterStringValue"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SpaceUpdateFilterInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "spaceUpdateFilter"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "space"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isSaved"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "config"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "SpaceConfig"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "maxValueCount"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "multiValueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "values"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabelIcons"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillBoolean"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillBoolean"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageFilterPillOperatorOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOperatorOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "BooleanValue",
              "value": true
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillString"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillString"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageFilterPillOperatorOptions"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LegacyTranslationImprecisions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "legacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsViewType"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewType"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TableGroupBySelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsSingleSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplaySelectConfigViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfig"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "query"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAllFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAnyFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflowSelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "valueDisplayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "InlineFragment",
                    "typeCondition": {
                      "kind": "NamedType",
                      "name": {
                        "kind": "Name",
                        "value": "Workflow"
                      }
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayableName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "LegacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewSettings"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfigViewSettingsViewType"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tableGroupBySelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "TableGroupBySelect"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplaySelectConfigViewSettings"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const SpaceUpdateDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "SpaceUpdate"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "saveInput"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SpaceSaveUnsavedConfigInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "spaceUpdateId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SpaceUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "spaceSaveUnsavedConfig"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "saveInput"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "space"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isSaved"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "spaceUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "spaceUpdateId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "space"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "visibility"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const SpaceUpdateWorkflowDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "SpaceUpdateWorkflow"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SpaceUpdateWorkflow"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "spaceUpdateWorkflow"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "space"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isSaved"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "config"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "SpaceConfig"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "maxValueCount"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "multiValueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "values"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabelIcons"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillBoolean"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillBoolean"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageFilterPillOperatorOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOperatorOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "BooleanValue",
              "value": true
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillString"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillString"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageFilterPillOperatorOptions"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LegacyTranslationImprecisions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "legacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsViewType"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewType"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TableGroupBySelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsSingleSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplaySelectConfigViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfig"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "query"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAllFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAnyFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflowSelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "valueDisplayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "InlineFragment",
                    "typeCondition": {
                      "kind": "NamedType",
                      "name": {
                        "kind": "Name",
                        "value": "Workflow"
                      }
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayableName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "LegacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewSettings"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfigViewSettingsViewType"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tableGroupBySelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "TableGroupBySelect"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplaySelectConfigViewSettings"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageSelectQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "StoriesPageSelectQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storiesPageInput"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoriesPageInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SpaceSelectOptionsInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "storiesPage"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "storiesPageInput"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "spaceSelectOptions"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "input"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "input"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "edges"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "InlineFragment",
                        "typeCondition": {
                          "kind": "NamedType",
                          "name": {
                            "kind": "Name",
                            "value": "SelectItemEdgeInterface"
                          }
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "displayable"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "InlineFragment",
                                "typeCondition": {
                                  "kind": "NamedType",
                                  "name": {
                                    "kind": "Name",
                                    "value": "Space"
                                  }
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "publicId"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "creator"
                                    },
                                    "selectionSet": {
                                      "kind": "SelectionSet",
                                      "selections": [{
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "displayableName"
                                        }
                                      }, {
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "id"
                                        }
                                      }]
                                    }
                                  }]
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "SelectItemsConnectionFragment"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SelectItemsConnectionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isFuzzyNameSupported"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeInterface"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableName"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "DisplayableIcon"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeHeader"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "headerValue"
                },
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "showSelectAll"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "SelectItemEdgeOption"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isSelected"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageCloneSpaceAndAddToTabsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "StoriesPageCloneSpaceAndAddToTabs"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SpaceCloneAndStoriesPageTabListAppendSpaceInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "spaceCloneAndStoriesPageTabListAppendSpace"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "space"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tabList"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoriesPageTabList"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTab"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSilentlyDeletable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "visibility"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "savedConfig"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTab"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageCreateSpaceAndAddToTabsMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "StoriesPageCreateSpaceAndAddToTabsMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SpaceCreateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "spaceCreateAndStoriesPageTabListAppendSpace"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "workspace2Slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "space"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tabList"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoriesPageTabList"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTab"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSilentlyDeletable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "visibility"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "savedConfig"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTab"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const CreateSpaceAndPrependDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CreateSpaceAndPrepend"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SpaceCreateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "spaceCreateAndStoriesPageTabListPrependSpace"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "workspace2Slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "space"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tabList"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoriesPageTabList"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTab"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSilentlyDeletable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "visibility"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "savedConfig"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTab"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const ExportSpaceDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "ExportSpace"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ExportInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "export"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "success"
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageOnReorderTabsMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "StoriesPageOnReorderTabsMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoriesPageTabListReorderSpaceInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storiesPageTabListReorderSpace"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tabList"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoriesPageTabList"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTab"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSilentlyDeletable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "visibility"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "savedConfig"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTab"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageRemoveSpaceFromTabsMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "StoriesPageRemoveSpaceFromTabsMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoriesPageRemoveTabInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storiesPageTabListRemoveSpace"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tabList"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoriesPageTabList"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTab"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSilentlyDeletable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "visibility"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "savedConfig"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTab"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const SpaceCancelUnsavedConfigDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "SpaceCancelUnsavedConfig"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SpaceCancelUnsavedConfigInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "spaceCancelUnsavedConfig"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "space"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isSaved"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "config"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "SpaceConfig"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "maxValueCount"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "multiValueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "values"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabelIcons"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillBoolean"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillBoolean"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageFilterPillOperatorOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOperatorOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "BooleanValue",
              "value": true
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillString"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillString"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageFilterPillOperatorOptions"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LegacyTranslationImprecisions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "legacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsViewType"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewType"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TableGroupBySelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsSingleSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplaySelectConfigViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfig"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "query"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAllFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAnyFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflowSelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "valueDisplayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "InlineFragment",
                    "typeCondition": {
                      "kind": "NamedType",
                      "name": {
                        "kind": "Name",
                        "value": "Workflow"
                      }
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayableName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "LegacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewSettings"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfigViewSettingsViewType"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tableGroupBySelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "TableGroupBySelect"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplaySelectConfigViewSettings"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const SpaceDeleteDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "SpaceDelete"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "spaceDelete"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "success"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tabList"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoriesPageTabList"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTab"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSilentlyDeletable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "visibility"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "savedConfig"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTab"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageAddTabMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "StoriesPageAddTabMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoriesPageAppendTabInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "setLastViewedInput"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoriesPageTabListSetLastVisitedInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storiesPageTabListAppendSpace"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tabList"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoriesPageAddTabTabList"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storiesPageTabListSetLastVisited"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "setLastViewedInput"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "__typename"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageAddTabSpace"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageAddTabTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageAddTabSpace"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPagePrependTabDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "StoriesPagePrependTab"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoriesPagePrependTabInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "setLastViewedInput"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoriesPageTabListSetLastVisitedInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storiesPageTabListPrependSpace"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tabList"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoriesPageAddTabTabList"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storiesPageTabListSetLastVisited"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "setLastViewedInput"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "__typename"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageAddTabSpace"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageAddTabTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageAddTabSpace"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageTabListSetLastVisitedMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabListSetLastVisitedMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoriesPageTabListSetLastVisitedInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storiesPageTabListSetLastVisited"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "__typename"
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageTabManagerQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabManagerQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "teamId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "ID"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Int"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "storiesPage"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "teamId"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "teamId"
                    }
                  }
                }]
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoriesPageTabManager"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageAddTabSpace"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabManagerTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageAddTabTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageAddTabSpace"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabManager"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPage"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "space"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "spacePublicId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageAddTabSpace"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabList"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTabManagerTabList"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageAddTabTabList"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageViewTypeDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "StoriesPageViewType"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Int"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "teamId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "ID"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "storiesPage"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "teamId"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "teamId"
                    }
                  }
                }]
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "space"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "spacePublicId"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "publicId"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "config"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "viewSettings"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "viewType"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetStoryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetStory"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyColorInput"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryColorInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoriesPageStoryCardFragment"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCard"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "isArchived"
        },
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "storyColorInput"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyColorInput"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardBlockedChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "blockedByCount"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hasBlockedLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardBlockerChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "blockerOfCount"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hasBlockerLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardAdvancedCustomFieldChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "advancedCustomFields"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "field"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "stringValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "displayableIcon"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "iconIdentifier"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "Color"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDeadlineChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "completedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDocCountChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numRelatedDocuments"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardDoneChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "completedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardEpicName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardEstimateChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "estimate"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardExternalLinkChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "externalLinks"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardGroup"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardIterationChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numPreviousIterations"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardJiraSyncChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "syncedItem"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardLabelChips"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UserCohortAdapterPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GroupAndOwnersOwnersPermission"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UserCohortAdapterPermission"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardOwners"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "GroupAndOwnersOwnersPermission"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CanonicalCustomFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "selectedValueId"
        },
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "iconIdentifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "Color"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardPriorityChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardProductAreaChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardProjectChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "project"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "abbreviation"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardSkillSetChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardSeverityChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTaskChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stats"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numTasksCompleted"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "numTasksTotal"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTechnicalAreaChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "CanonicalCustomFieldFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTitle"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "isArchived"
        },
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCardTypeChip"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageStoryCardFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCard"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardBlockedChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardBlockerChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardAdvancedCustomFieldChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDeadlineChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDocCountChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardDoneChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardEpicName"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardEstimateChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardExternalLinkChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardGroup"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardIterationChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardJiraSyncChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardLabelChips"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardOwners"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardPriorityChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardProductAreaChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardProjectChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardSkillSetChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardSeverityChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTaskChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTechnicalAreaChip"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTitle"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryCardTypeChip"
        }
      }]
    }
  }]
};
export const StoriesPageV3MoveStoryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "StoriesPageV3MoveStory"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "ids"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "ID"
              }
            }
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryUpdateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyBulkUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "ids"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "ids"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stories"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetGroupStoryListDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetGroupStoryList"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "spaceId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoriesInGroupInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "space"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "publicId"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "spaceId"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "config"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "storiesInGroup"
                    },
                    "arguments": [{
                      "kind": "Argument",
                      "name": {
                        "kind": "Name",
                        "value": "input"
                      },
                      "value": {
                        "kind": "Variable",
                        "name": {
                          "kind": "Name",
                          "value": "input"
                        }
                      }
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "StoryInGroupIds"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryInGroupIds"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoryInGroup"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }]
    }
  }]
};
export const GetTabsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetTabs"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "teamId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "ID"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "kanbanInput"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SpaceResultsInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "disableMerge"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Boolean"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "storiesPage"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "teamId"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "teamId"
                    }
                  }
                }]
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "tabList"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "tabs"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "isSaved"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "isSilentlyDeletable"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "visibility"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "savedConfig"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "displayableIcon"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "DisplayIconFragment"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "team"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "UseSpaceDelete"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoriesPageTabManager"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "space"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "spacePublicId"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "publicId"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "SpaceContext"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTab"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSilentlyDeletable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "visibility"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "savedConfig"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTab"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageAddTabSpace"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabManagerTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageAddTabTabList"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPageTabList"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabs"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageAddTabSpace"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceCreator"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "maxValueCount"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "multiValueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "values"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabelIcons"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillBoolean"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillBoolean"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageFilterPillOperatorOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOperatorOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "BooleanValue",
              "value": true
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillString"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillString"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageFilterPillOperatorOptions"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LegacyTranslationImprecisions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "legacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsViewType"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewType"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TableGroupBySelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsSingleSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplaySelectConfigViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfig"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "query"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAllFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAnyFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflowSelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "valueDisplayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "InlineFragment",
                    "typeCondition": {
                      "kind": "NamedType",
                      "name": {
                        "kind": "Name",
                        "value": "Workflow"
                      }
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayableName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "LegacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewSettings"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfigViewSettingsViewType"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tableGroupBySelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "TableGroupBySelect"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplaySelectConfigViewSettings"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UseSpaceDelete"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPage"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabList"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTabList"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageTabManager"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "StoriesPage"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "space"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "spacePublicId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageAddTabSpace"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tabList"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageTabManagerTabList"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesPageAddTabTabList"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceContext"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "creator"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceCreator"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "visibility"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSilentlyDeletable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaveable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "savedConfig"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfig"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "config"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfig"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const GetSpaceInfoDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetSpaceInfo"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "teamId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "ID"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "kanbanInput"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SpaceResultsInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "disableMerge"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Boolean"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "isTable"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "isColumn"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "isWipLimitsEnabled"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "features"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "iterations"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "enabled"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "storiesPage"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "teamId"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "teamId"
                    }
                  }
                }]
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "team"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "space"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "spacePublicId"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "publicId"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "publicId"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "isSaved"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "isSilentlyDeletable"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "SpaceHeader"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "config"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "kanbanResults2"
                        },
                        "arguments": [{
                          "kind": "Argument",
                          "name": {
                            "kind": "Name",
                            "value": "input"
                          },
                          "value": {
                            "kind": "Variable",
                            "name": {
                              "kind": "Name",
                              "value": "kanbanInput"
                            }
                          }
                        }],
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "FragmentSpread",
                            "name": {
                              "kind": "Name",
                              "value": "KanbanResultEdges"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceCreator"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Permission"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "mentionName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "state"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueOptions"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "maxValueCount"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "multiValueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "values"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabelIcons"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillBoolean"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillBoolean"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageFilterPillOperatorOptions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillOperatorOptions"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "operatorValue"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "BooleanValue",
              "value": true
            }
          }]
        }]
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "FilterPillString"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "FilterPillString"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filterTypeLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueLabel"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "disabled"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageFilterPillOperatorOptions"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "LegacyTranslationImprecisions"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "legacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsViewType"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewType"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TableGroupBySelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsSingleSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplaySelectConfigViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfig"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfig"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "query"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAllFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "matchAnyFilterPills"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "FilterPill"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillOptions"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillBoolean"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "FilterPillString"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflowSelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "valueDisplayable"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "InlineFragment",
                    "typeCondition": {
                      "kind": "NamedType",
                      "name": {
                        "kind": "Name",
                        "value": "Workflow"
                      }
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "publicId"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayableName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "LegacyTranslationImprecisions"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewSettings"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfigViewSettingsViewType"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tableGroupBySelect"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "TableGroupBySelect"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplaySelectConfigViewSettings"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderTooltipSpaceResultsEdgeAggregation"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdgeAggregation"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderTooltipSpaceResultsEdgeHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdgeHeader"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ColumnHeaderSpaceResultsEdge"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headerAggregations"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ColumnHeaderTooltipSpaceResultsEdgeAggregation"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headers"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isWipLimitsEnabled"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "identifier"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ColumnHeaderTooltipSpaceResultsEdgeHeader"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumnHeaderAggregations"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ColumnHeaderSpaceResultsEdge"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateColumn"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "WorkflowState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesV3Column"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "groupId"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "WorkflowStateColumnHeaderAggregations"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "WorkflowStateColumn"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UrlIdFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "UrlId"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "urlId"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchivableFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Archivable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesPageGroupByHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Node"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "WorkflowState"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableIcon"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "UrlIdFragment"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ArchivableFragment"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "headerAggregations"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableIcon"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesV3Table"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsEdge"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "groupId"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoriesPageGroupByHeader"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "pageInfo"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalSize"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceHeader"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "visibility"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaveable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSilentlyDeletable"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "creator"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceCreator"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "config"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfig"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "savedConfig"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "SpaceConfig"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "KanbanResultEdges"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceResultsConnection"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "queryKey"
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "columnEdges"
        },
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isColumn"
              }
            }
          }]
        }, {
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "connection"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "key"
            },
            "value": {
              "kind": "StringValue",
              "value": "kanban",
              "block": false
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesV3Column"
            }
          }]
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "tableEdges"
        },
        "name": {
          "kind": "Name",
          "value": "edges"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "include"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "isTable"
              }
            }
          }]
        }, {
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "connection"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "key"
            },
            "value": {
              "kind": "StringValue",
              "value": "table",
              "block": false
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "StoriesV3Table"
            }
          }]
        }
      }]
    }
  }]
};
export const SpaceConfigViewSettingsMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "SpaceConfigUpdateViewSettingsInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "spaceConfigUpdateViewSettings"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "space"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "SpaceConfigViewSettingsSpace"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsViewType"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewType"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TableGroupBySelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsSingleSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplaySelectConfigViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsSpace"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "config"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "viewSettings"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "SpaceConfigViewSettingsViewType"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "tableGroupBySelect"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "TableGroupBySelect"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "DisplaySelectConfigViewSettings"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoriesPageViewTypeV3Document = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "StoriesPageViewTypeV3"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Int"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "teamId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "ID"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "storiesPage"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "teamId"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "teamId"
                    }
                  }
                }]
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "space"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "spacePublicId"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "publicId"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "SpaceConfigViewSettingsSpace"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsViewType"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "viewType"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TableGroupBySelect"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SelectItemsSingleSelect"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "valueDisplayable"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayableName"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "value"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplaySelectConfigViewSettings"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "SpaceConfigViewSettings"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SpaceConfigViewSettingsSpace"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Space"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "isSaved"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "config"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "viewSettings"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "SpaceConfigViewSettingsViewType"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "tableGroupBySelect"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "TableGroupBySelect"
                    }
                  }]
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "DisplaySelectConfigViewSettings"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetStoryRowDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetStoryRow"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": false
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryNameFieldFragment"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryTypeFieldFragment"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoriesTableTeamFragment"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryOwnersFieldFragment"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryRequesterField"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoriesEstimateFragment"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryDeadlineFieldFragment"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "WorkflowFieldFragment"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "WorkflowStatesFieldFragment"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoriesTableEpicFragment"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "IterationFieldFragment"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryLabelsField"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryLastUpdatedField"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryCreatedOnField"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryMoreActionsField"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryCanonicalCustomFieldValues"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryAdvancedCustomFields"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoriesTableBulkEditFieldFragment"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamDisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "if"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "skipAllExceptId"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "GoToStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "PinStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "CopyStoryLinkAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryData"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveStoryAction"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveStoryData"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveStoryData"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryPriorityFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "stringValue"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ProductAreaFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "productArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "ProductAreaFieldItem"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SeverityFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "severity"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "position"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "SkillSetFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "skillSet"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TechnicalAreaFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "technicalArea"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stringValue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableIcon"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayableName"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Displayable"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "InlineFragment",
        "typeCondition": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Node"
          }
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryAdvanvedCustomFieldValue"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "CustomFieldEnumValue"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DisplayableIcon"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DisplayableName"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryNameFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryTypeFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableTeamFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publicId"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mentionName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "colorKey"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "TeamDisplayIconFragment"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryOwnersFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryRequesterField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "requester"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "mentionName"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }]
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "displayIcon"
            },
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "if"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "skipAllExceptId"
                  }
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnailUrl"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesEstimateFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "estimate"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryDeadlineFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deadline"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflow"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStatesFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowState"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "epic"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "epicState"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "IterationFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLabelsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "labels"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "color"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryLastUpdatedField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updatedAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCreatedOnField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createdAt"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryMoreActionsField"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "GoToStoryAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "PinStoryAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "CopyStoryLinkAction"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveStoryAction"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryCanonicalCustomFieldValues"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "StoryPriorityFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ProductAreaFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "SeverityFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "SkillSetFieldFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "TechnicalAreaFieldFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryAdvancedCustomFields"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "advancedCustomFields"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "field"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryAdvanvedCustomFieldValue"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoriesTableBulkEditFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }]
    }
  }]
};
export const QueryWorkspaceWorkflowsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "QueryWorkspaceWorkflows"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "teamId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "workflows"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "teamIds"
                  },
                  "value": {
                    "kind": "ListValue",
                    "values": [{
                      "kind": "Variable",
                      "name": {
                        "kind": "Name",
                        "value": "teamId"
                      }
                    }]
                  }
                }]
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "TeamWorkflowsWorkflow"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "alias": {
          "kind": "Name",
          "value": "team"
        },
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "teamId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "TeamWorkflowsTeam"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Color"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Color"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "colorKey"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hexadecimalColor"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "semanticColor"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DisplayIconFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "DisplayIcon"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "color"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "Color"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iconIdentifier"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "icon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowStateItemWorkflowState"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "WorkflowState"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "DisplayIconFragment"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "wipLimits"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "teamIds"
          },
          "value": {
            "kind": "ListValue",
            "values": [{
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "teamId"
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "value"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WorkflowItemWorkflow"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workflow"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowStates"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }, {
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "WorkflowStateItemWorkflowState"
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "WipLimitFormWorkflow"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workflow"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayableName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "hasWIPLimits"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "teamIds"
          },
          "value": {
            "kind": "ListValue",
            "values": [{
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "teamId"
              }
            }]
          }
        }]
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workflowStates"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "WorkflowItemWorkflow"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamWorkflowsWorkflow"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Workflow"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "WipLimitFormWorkflow"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamWorkflowsTeam"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }]
    }
  }]
};
export const UpsertWipLimitsMutationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpsertWipLimitsMutation"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspace2Slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "WIPLimitUpsertInput"
              }
            }
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "upsertWIPLimits"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "workspace2Slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspace2Slug"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "wipLimits"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const WorkspaceTeamSelectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "WorkspaceTeamSelect"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "workspaceSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Workspace2TeamSelectOptionsInput"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "workspace2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "workspaceSlug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "options"
            },
            "name": {
              "kind": "Name",
              "value": "teamSelectOptions"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "input"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "input"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "edges"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "groupKey"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "node"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "TeamSelectDropdownTeam"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pageInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalSize"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TeamSelectDropdownTeam"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Team"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "displayIcon"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnailUrl"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }]
        }
      }]
    }
  }]
};
export const EpicContextMenuDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "EpicContextMenu"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Epic"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ArchiveEpicActionFragment"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "DeleteEpicActionFragment"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "UnarchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "archived"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ArchiveEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "UnarchiveEpicFragment"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "ArchiveEpicFragment"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "DeleteEpicActionFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Epic"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "FragmentSpread",
        "name": {
          "kind": "Name",
          "value": "DeleteEpicFragment"
        }
      }]
    }
  }]
};
export const UpdateStoryIterationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateStoryIteration"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryUpdateInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Boolean"
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": true
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "iteration"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const MutateStoryOwnersDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "MutateStoryOwners"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "StoryUpdateInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skipAllExceptId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Boolean"
        }
      },
      "defaultValue": {
        "kind": "BooleanValue",
        "value": true
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "storyUpdate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "story"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "StoryOwnersFieldFragment"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "StoryOwnersFieldFragment"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "where"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": []
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "displayName"
                    }
                  }, {
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "direction"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ascending"
                    }
                  }]
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "state"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "mentionName"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }]
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "displayIcon"
                    },
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "skip"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "if"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "skipAllExceptId"
                          }
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "thumbnailUrl"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const StoryContextMenuQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "StoryContextMenuQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "storyId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "node"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "storyId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "InlineFragment",
            "typeCondition": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Story"
              }
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "formattedVcsBranchName"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "ToggleOwnersContextMenuItem"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "MoveToIterationContextMenuItem"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "ToggleOwnersContextMenuItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "publicId"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "name"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "type"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "owners"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "value": {
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "offset"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "0"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  },
                  "value": {
                    "kind": "IntValue",
                    "value": "50"
                  }
                }]
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              },
              "value": {
                "kind": "ListValue",
                "values": []
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "edges"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "node"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "MoveToIterationContextMenuItem"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "Story"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "iteration"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "team"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentIteration"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "nextIteration"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publicId"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};